import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e352acc8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main"
};
const _hoisted_2 = {
  class: "form-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MainTitle = _resolveComponent("MainTitle");
  const _component_Banner = _resolveComponent("Banner");
  const _component_w_text_input = _resolveComponent("w-text-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_w_icon = _resolveComponent("w-icon");
  const _component_w_standard_button = _resolveComponent("w-standard-button");
  const _component_el_form = _resolveComponent("el-form");
  const _component_CenterTop = _resolveComponent("CenterTop");
  return _openBlock(), _createBlock(_component_CenterTop, {
    logo: require('@/assets/theme/quizz.png')
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [_createVNode(_component_MainTitle, null, {
      title: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('password_creation.title')), 1)]),
      _: 1
    })]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_form, {
      model: _ctx.passwordCreationForm,
      class: "password-creation-form"
    }, {
      default: _withCtx(() => [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
        key: 0,
        type: "error"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)]),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_el_form_item, {
        prop: "password",
        class: "password-field-block",
        rules: [{
          required: true,
          trigger: 'blur',
          message: _ctx.$t('password_creation.form.password_required_msg')
        }]
      }, {
        default: _withCtx(() => [_createVNode(_component_w_text_input, {
          width: "500px",
          size: "large",
          type: "password",
          "custom-class": "password-input",
          "show-password": true,
          "field-label": _ctx.$t('password_creation.form.password_field_label').toString(),
          placeholder: _ctx.$t('password_creation.form.password_field_placeholder').toString(),
          value: _ctx.passwordCreationForm.password,
          onInputChanged: _cache[0] || (_cache[0] = value => _ctx.passwordCreationForm.password = value)
        }, null, 8, ["field-label", "placeholder", "value"])]),
        _: 1
      }, 8, ["rules"]), _createVNode(_component_el_form_item, {
        prop: "repassword",
        rules: [{
          required: true,
          trigger: 'blur',
          message: _ctx.$t('password_creation.form.repassword_required_msg')
        }]
      }, {
        default: _withCtx(() => [_createVNode(_component_w_text_input, {
          width: "500px",
          size: "large",
          type: "password",
          "custom-class": "password-input",
          "show-password": true,
          "field-label": _ctx.$t('password_creation.form.repassword_field_label').toString(),
          placeholder: _ctx.$t('password_creation.form.repassword_field_placeholder').toString(),
          value: _ctx.passwordCreationForm.repassword,
          onEnterPressed: _cache[1] || (_cache[1] = $event => _ctx.createPassword()),
          onInputChanged: _cache[2] || (_cache[2] = value => _ctx.passwordCreationForm.repassword = value)
        }, null, 8, ["field-label", "placeholder", "value"])]),
        _: 1
      }, 8, ["rules"]), _createVNode(_component_el_form_item, {
        class: "password-creation-form__submit-item"
      }, {
        default: _withCtx(() => [_createVNode(_component_w_standard_button, {
          type: "primary",
          minWidth: "117px",
          disabled: _ctx.requiredFieldIsMissing(),
          "is-loading": _ctx.loading,
          onClick: _cache[3] || (_cache[3] = $event => _ctx.createPassword())
        }, {
          default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$t('password_creation.form.submit_btn_label')), 1), _createVNode(_component_w_icon, {
            icon: "security"
          })]),
          _: 1
        }, 8, ["disabled", "is-loading"])]),
        _: 1
      })]),
      _: 1
    }, 8, ["model"])])])]),
    _: 1
  }, 8, ["logo"]);
}