import {User} from '@/store/models/user';
import {AuthLink} from '@/store/models/authLink';
import {
    AuthItemAttributeMappingType,
    AuthItemAttributeRuleMapping,
} from '@/store/enums/authItemEnum';

export class AuthItem {
    public type: string;
    public name: string;
    public authItemID: string;
    public authItemConfigurations: AuthItemConfiguration[];
    public authItemAttributes: AuthItemAttribute[];
}

export class AuthItemConfiguration {
    public type: string;
    public value: string;
}

export class AuthItemAttribute {
    public name: string;
    public testValue: string;
    public mapping: AuthItemAttributeMapping[];
}

export class AuthItemAttributeMapping {
    public type: AuthItemAttributeMappingType;
    public valueMap: string;
    public ruleType: AuthItemAttributeRuleMapping;
}




