import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_icon = _resolveComponent("w-icon");
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createBlock(_component_el_button, {
    icon: _ctx.icon,
    class: _normalizeClass(_ctx.btnClass),
    loading: _ctx.loading,
    disabled: _ctx.disabled
  }, _createSlots({
    _: 2
  }, [!_ctx.icon ? {
    name: "icon",
    fn: _withCtx(() => [_createVNode(_component_w_icon, {
      icon: _ctx.iconClass
    }, null, 8, ["icon"])]),
    key: "0"
  } : undefined]), 1032, ["icon", "class", "loading", "disabled"]);
}