import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-50fad010"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "w-dialog"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_dialog, {
    modelValue: _ctx.isVisible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.isVisible = $event),
    width: _ctx.width,
    title: _ctx.title,
    "custom-class": _ctx.customClass,
    center: _ctx.center,
    "close-on-click-modal": _ctx.closeOnOutsideClick,
    "show-close": _ctx.showClose,
    onClose: _cache[1] || (_cache[1] = $event => _ctx.emitCloseEvent())
  }, _createSlots({
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 2
  }, [_ctx.$slots.footer ? {
    name: "footer",
    fn: _withCtx(() => [_renderSlot(_ctx.$slots, "footer", {}, undefined, true)]),
    key: "0"
  } : undefined]), 1032, ["modelValue", "width", "title", "custom-class", "center", "close-on-click-modal", "show-close"])]);
}