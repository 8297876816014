import store from '@/store'
import { AUTH_LOGOUT } from '@/store/actions/auth'
import {router} from "@/router"

export function isAuth () {
  return store.getters.isAuthenticated
}
export function logoutUser () {
  store.dispatch(AUTH_LOGOUT).then(() => {
    router.push({ name: 'Authentication' })
  })
}
