import axios from 'axios';
import {
  APPEND_AUTHORS_LIST,
  APPEND_SPEACH_LIST,
  AUTHORS_LIST,
  CATEGORIES_LIST,
  CHECK_ALL_SPEACH_FROM_BACK,
  CHECK_ALL_SPEACH_NOT_CHECKED,
  CHECK_SPEACH,
  COPY_SPEACH,
  COPY_SPEACH_SEARCH_FILTERS,
  DESTINATION_PLATFORM,
  EMPTY_SPEACH_LIST,
  ERROR_COPY_SPEACH,
  GET_SPEACH_FROM_SPEACH_ID,
  REFRESH_CHECKED_SPEACH_TO_SHOW,
  REFRESH_COUR_IN_CHECKED_LIST,
  REFRESH_COUR_IN_SPEACH_TO_COPY, RESET_AUTHORS_LIST,
  RESET_CONFIGURE_SPEACH,
  RESET_STORE_COPY_SPEACH,
  SET_AUTHOR,
  SET_CATEGORY,
  SOURCE_PLATFORM,
  SPEACH_IDS_LIST,
  SPEACH_LIST,
  SUCCESS_COPY_SPEACH,
  UNCHECK_ALL_SPEACH_FROM_BACK,
  UNCHECK_ALL_SPEACH_IN_SEARCH_LIST,
  UNCHECK_SPEACH
} from '@/store/actions/speach';
import {Platform} from '@/store/models/platform';
import {BACK_ENDPOINT} from '@/store/actions/api';
import {Categorie, Cour, CourToCopy, UserSpeach} from '@/store/models/cour';
import {PaginationWrapper} from '@/store/models/paginationWrapper';
import {CopySpeachStatus} from '@/store/enums/speachEnum';
import {CopySpeachSearchFilters} from "@/store/models/copySpeachSearchFilters";

const platformAPI = BACK_ENDPOINT + '/platform';
const categoriesAPI = '/categories';
const authorsAPI = '/authors';
const speachAPI = '/speach';
const speachIdsAPI = '/speachids';
const copySpeachAPI = '/speach/copy';

export default {
  state: {
    categories: {},
    authors: {},
    listSpeach: {},
    listSpeachIdsChecked: {},
    totalNumberSpeach:0,
    currentPageSpeach:1,
    listCheckedSpeach: {},
    listCheckedSpeachSearchList: {},
    listSpeachToCopy: {},
    sourcePlatform:Platform,
    destinationPlatform:Platform,
    copySpeachSearchFilters: CopySpeachSearchFilters,
  },
  mutations: {
    [RESET_STORE_COPY_SPEACH]: (state: any) => {
      state.authors = {};
      state.listSpeach = {};
      state.listSpeachIdsChecked = {};
      state.totalNumberSpeach = 0;
      state.currentPageSpeach = 1;
      state.listCheckedSpeach = {};
      state.listCheckedSpeachSearchList = {};
      state.listSpeachToCopy = {};
      state.destinationPlatform = null;
      state.sourcePlatform = null;
      state.copySpeachSearchFilters = {};
    },
    [CATEGORIES_LIST]: (state: any, {platformID, categories}:{platformID:string, categories: Categorie[]}) => {
      state.categories[platformID] = categories;
    },
    [AUTHORS_LIST]: (state: any, {platformID, authors}:{platformID:string, authors: PaginationWrapper<UserSpeach>}) => {
      state.authors[platformID] = {result:authors.result, total:authors.total};
    },
    [APPEND_AUTHORS_LIST]: (state: any, {platformID, authors}:{platformID:string, authors: PaginationWrapper<UserSpeach>}) => {
      let existingAuthors =state.authors[platformID]?.result ?? [];
      state.authors[platformID] = {result:existingAuthors.concat(authors.result), total:authors.total};
    },
    [RESET_AUTHORS_LIST]: (state: any, platformID: string) => {
      state.authors[platformID] = {result: [], total: 0};
    },
    [SPEACH_LIST]: (state: any, listSpeach: PaginationWrapper<Cour>) => {
      state.totalNumberSpeach=listSpeach.total;
      state.listSpeach={};
      state.listSpeachIdsChecked={};
      for(let s of listSpeach.result){
        state.listSpeach[s.speachID] = s;
      }
      state.currentPageSpeach=listSpeach.page;
    },
    [APPEND_SPEACH_LIST]: (state: any, listSpeach: PaginationWrapper<Cour>) => {
      state.totalNumberSpeach=listSpeach.total;
      for(let s of listSpeach.result){
        state.listSpeach[s.speachID] = s;
      }
      state.currentPageSpeach=listSpeach.page;
    },
    [SPEACH_IDS_LIST]: (state: any, listSpeachIds: string[]) => {
      state.listSpeachIdsChecked={};
      for(let s of listSpeachIds){
        state.listSpeachIdsChecked[s] = new Cour(s);
      }
    },
    [CHECK_SPEACH]: (state: any, cour: Cour) => {
      state.listCheckedSpeach[cour.speachID] = cour;
    },
    [UNCHECK_SPEACH]: (state: any, cour: Cour) => {
      delete state.listCheckedSpeach[cour.speachID];
    },
    [REFRESH_CHECKED_SPEACH_TO_SHOW]: (state: any) => {
      state.listCheckedSpeachSearchList={};
      for(let c of Object.values<Cour>(state.listSpeach) ){
        if(state.listCheckedSpeach[c.speachID]){
          state.listCheckedSpeachSearchList[c.speachID] = c;
        }
      }
    },
    [RESET_CONFIGURE_SPEACH]: (state: any) => {
      state.listSpeachToCopy={};
      for(let c of Object.values<Cour>(state.listCheckedSpeach)){
        state.listSpeachToCopy[c.speachID] = new CourToCopy(c);
      }
    },
    [SET_AUTHOR]: (state: any, {cour, userCopy} : { cour: CourToCopy, userCopy: UserSpeach } ) => {
      const courStore:CourToCopy = state.listSpeachToCopy[cour.cour.speachID];
      courStore.userCopy=userCopy;
    },
    [SET_CATEGORY]: (state: any, {cour, category} : { cour: CourToCopy, category: Categorie } ) => {
      const courStore:CourToCopy = state.listSpeachToCopy[cour.cour.speachID];
      courStore.categoryCopy=category;
    },
    [SOURCE_PLATFORM]: (state: any, platform : Platform ) => {
      state.sourcePlatform = platform;
    },
    [DESTINATION_PLATFORM]: (state: any, platform : Platform ) => {
      state.destinationPlatform = platform;
    },
    [SUCCESS_COPY_SPEACH]: (state: any, speachID: string) => {
      const cour = state.listSpeachToCopy[speachID];
      if(cour) {
        cour.copySpeachStatus = CopySpeachStatus.SUCCESS;
      }
    },
    [ERROR_COPY_SPEACH]: (state: any, speachID: string) => {
      const cour = state.listSpeachToCopy[speachID];
      if(cour) {
        cour.copySpeachStatus = CopySpeachStatus.ERROR;
      }
    },
    [CHECK_ALL_SPEACH_NOT_CHECKED]: (state: any, coursChecked: Cour[]) => {
      for(let cour of coursChecked) {
        const courExisting = state.listCheckedSpeach[cour.speachID];
        if (!courExisting) {
          state.listCheckedSpeach[cour.speachID] = cour;
        }
      }
      state.listSpeachIdsChecked={}
    },
    [UNCHECK_ALL_SPEACH_IN_SEARCH_LIST]: (state: any) => {
      state.listCheckedSpeachSearchList = {};
      for(let cour of Object.values<Cour>(state.listSpeach)){
        if(state.listCheckedSpeach[cour.speachID]){
          delete state.listCheckedSpeach[cour.speachID];
        }
      }
      state.listSpeachIdsChecked={}
    },
    [CHECK_ALL_SPEACH_FROM_BACK]: (state: any) => {
      for(let cour of Object.values<Cour>(state.listSpeachIdsChecked)) {
        const courExisting = state.listCheckedSpeach[cour.speachID];
        if (!courExisting) {
          state.listCheckedSpeach[cour.speachID] = cour;
        }
      }
    },
    [UNCHECK_ALL_SPEACH_FROM_BACK]: (state: any) => {
      state.listCheckedSpeachSearchList = {};
      for(let cour of Object.values<Cour>(state.listSpeachIdsChecked)) {
        if (state.listCheckedSpeach[cour.speachID]) {
          delete state.listCheckedSpeach[cour.speachID];
        }
      }
      state.listSpeachIdsChecked={}
    },
    [REFRESH_COUR_IN_CHECKED_LIST]: (state: any, cour:Cour) => {
      if (state.listCheckedSpeach[cour.speachID]) {
        state.listCheckedSpeach[cour.speachID] = cour;
      }
    },
    [REFRESH_COUR_IN_SPEACH_TO_COPY]: (state: any, cour:Cour) => {
      const courStore:CourToCopy = state.listSpeachToCopy[cour.speachID];
      if(courStore) {
        courStore.cour = cour;
      }
    },
    [COPY_SPEACH_SEARCH_FILTERS]: (state: any, filters: CopySpeachSearchFilters) => {
      state.copySpeachSearchFilters = filters;
    },
  },
  getters: {
    categories: (state: any) => (platformID:string) :Categorie[] => {
      return state.categories[platformID];
    },
    authors: (state: any) => (platformID:string) :UserSpeach[] => {
      return state.authors[platformID]?.result ?? [];
    },
    totalNumberAuthors: (state: any) => (platformID:string) => {
      return state.authors[platformID]?.total ?? 0;
    },
    listSpeach: (state: any) => {
      return Object.values(state.listSpeach);
    },
    totalNumberSpeach: (state: any) => {
      return state.totalNumberSpeach;
    },
    currentPageSpeach: (state: any) => {
      return state.currentPageSpeach;
    },
    listCheckedSpeach: (state: any) : Cour[] => {
      return Object.values(state.listCheckedSpeach);
    },
    listCheckedSpeachSearchList: (state: any) : Cour[] => {
      return Object.values(state.listCheckedSpeachSearchList);
    },
    listSpeachToCopy: (state: any) : CourToCopy[] => {
      return Object.values(state.listSpeachToCopy);
    },
    sourcePlatform: (state: any) => {
      return state.sourcePlatform;
    },
    destinationPlatform: (state: any) => {
      return state.destinationPlatform;
    },
    copySpeachSearchFilters: (state: any) => {
      return state.copySpeachSearchFilters;
    }
  },
  actions: {



    async [CATEGORIES_LIST]({commit, dispatch}: { commit: any, dispatch: any },  {
      platformID
    }: { platformID: string}) {
      await axios({
        url: platformAPI + '/' + platformID + categoriesAPI,
        method: 'GET',
      }).then((resp) => {
        commit(CATEGORIES_LIST, {platformID, categories:resp.data});
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [AUTHORS_LIST]({commit, dispatch}: { commit: any, dispatch: any },  {
      platformID,
      author = null,
      page=1,
      append=false
    }: { platformID: string, author: string, page:number, append:boolean }) {
      await axios({
        url: platformAPI + '/' + platformID + authorsAPI,
        method: 'GET',
        params:{author, page},
      }).then((resp) => {
        if(!append) {
          commit(AUTHORS_LIST, {platformID, authors:resp.data});
        }else {
          commit(APPEND_AUTHORS_LIST, {platformID, authors:resp.data});
        }
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [RESET_AUTHORS_LIST]({commit, dispatch}: { commit: any, dispatch: any }, platformId: string) {
      commit(RESET_AUTHORS_LIST, platformId);
    },
    async [SPEACH_LIST]({commit, dispatch}: { commit: any, dispatch: any },
                        {
                          platformID,
                          title = null,
                          author=null,
                          categoryId=null,
                          page=1,
                          append=false
                        }: { platformID: string, title: string, author: string, categoryId: number, page:number, append:boolean }) {
      await axios({
        url: platformAPI + '/' + platformID + speachAPI,
        method: 'GET',
        params:{title, author, categoryId, page}
      }).then((resp) => {
        if(!append) {
          commit(SPEACH_LIST, resp.data);
        }else {
          commit(APPEND_SPEACH_LIST, resp.data);
        }
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [SPEACH_IDS_LIST]({commit, dispatch}: { commit: any, dispatch: any },
                        {
                          platformID,
                          title = null,
                          author=null,
                          categoryId=null
                        }: { platformID: string, title: string, author: string, categoryId: number}) {
      await axios({
        url: platformAPI + '/' + platformID + speachIdsAPI,
        method: 'GET',
        params:{title, author, categoryId}
      }).then((resp) => {
          commit(SPEACH_IDS_LIST, resp.data);
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [EMPTY_SPEACH_LIST]({commit, dispatch}: { commit: any, dispatch: any }) {
      commit(SPEACH_LIST, new PaginationWrapper<Cour>(0, 0, []));
    },
    async [CHECK_SPEACH]({commit, dispatch}: { commit: any, dispatch: any }, cour: Cour) {
      commit(CHECK_SPEACH, cour);
    },
    async [UNCHECK_SPEACH]({commit, dispatch}: { commit: any, dispatch: any }, cour: Cour) {
      commit(UNCHECK_SPEACH, cour);
    },
    async [REFRESH_CHECKED_SPEACH_TO_SHOW]({commit, dispatch}: { commit: any, dispatch: any }) {
      commit(REFRESH_CHECKED_SPEACH_TO_SHOW);
    },
    async [RESET_STORE_COPY_SPEACH]({commit, dispatch}: { commit: any, dispatch: any }) {
      commit(RESET_STORE_COPY_SPEACH);
    },
    async [RESET_CONFIGURE_SPEACH]({commit, dispatch}: { commit: any, dispatch: any }) {
      commit(RESET_CONFIGURE_SPEACH);
    },
    async [COPY_SPEACH]({commit, dispatch}: { commit: any, dispatch: any },
                        {
                          platformSourceID,
                          platformDestinationID,
                          courToCopy
                        }: { platformSourceID: string, platformDestinationID: string, courToCopy:CourToCopy }) {
      await axios({
        url: platformAPI + '/' + platformDestinationID + copySpeachAPI,
        method: 'POST',
        params:{platformSourceID},
        data:courToCopy
      }).then(() => {
        commit(SUCCESS_COPY_SPEACH, courToCopy.cour.speachID);
      }).catch(() => {
        commit(ERROR_COPY_SPEACH, courToCopy.cour.speachID);
      });
    },
    async [SET_AUTHOR]({commit, dispatch}: { commit: any, dispatch: any }, {
      cour, userCopy
    }: { cour: CourToCopy, userCopy: UserSpeach }) {
      commit(SET_AUTHOR, {cour, userCopy});
    },
    async [SET_CATEGORY]({commit, dispatch}: { commit: any, dispatch: any }, {
      cour, category
    }: { cour: CourToCopy, category: Categorie }) {
      commit(SET_CATEGORY, {cour, category});
    },
    async [SOURCE_PLATFORM]({commit, dispatch}: { commit: any, dispatch: any }, platform: Platform) {
      commit(SOURCE_PLATFORM, platform);
    },
    async [DESTINATION_PLATFORM]({commit, dispatch}: { commit: any, dispatch: any }, platform: Platform) {
      commit(DESTINATION_PLATFORM, platform);
    },
    async [CHECK_ALL_SPEACH_NOT_CHECKED]({commit, dispatch}: { commit: any, dispatch: any }, coursChecked: Cour[]) {
      commit(CHECK_ALL_SPEACH_NOT_CHECKED, coursChecked);
    },
    async [UNCHECK_ALL_SPEACH_IN_SEARCH_LIST]({commit, dispatch}: { commit: any, dispatch: any }) {
      commit(UNCHECK_ALL_SPEACH_IN_SEARCH_LIST);
    },
    async [CHECK_ALL_SPEACH_FROM_BACK]({commit, dispatch}: { commit: any, dispatch: any }) {
      commit(CHECK_ALL_SPEACH_FROM_BACK);
    },
    async [UNCHECK_ALL_SPEACH_FROM_BACK]({commit, dispatch}: { commit: any, dispatch: any }) {
      commit(UNCHECK_ALL_SPEACH_FROM_BACK);
    },
    async [REFRESH_COUR_IN_CHECKED_LIST]({commit, dispatch}: { commit: any, dispatch: any }, cour: Cour) {
      commit(REFRESH_COUR_IN_CHECKED_LIST, cour);
    },
    async [REFRESH_COUR_IN_SPEACH_TO_COPY]({commit, dispatch}: { commit: any, dispatch: any }, cour: Cour) {
      commit(REFRESH_COUR_IN_SPEACH_TO_COPY, cour);
    },
    async [COPY_SPEACH_SEARCH_FILTERS]({commit, dispatch}: { commit: any, dispatch: any }, {
          title,
          author,
          category
    }: { title: string, author: string, category: Categorie}) {
      commit(COPY_SPEACH_SEARCH_FILTERS, new CopySpeachSearchFilters(title, author, category));
    },
    async [GET_SPEACH_FROM_SPEACH_ID]({commit, dispatch}: { commit: any, dispatch: any },  {
      platformID, speachID
    }: { platformID: string, speachID:string}):Promise<Cour> {
      return await axios({
        url: platformAPI + '/' + platformID + speachAPI+'/'+speachID,
        method: 'GET',
      }).then((resp) => {
        return resp.data;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
  },

};
