import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
export default class ColorPicker extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "width", void 0);
    _defineProperty(this, "height", void 0);
    _defineProperty(this, "panelChoose", void 0);
    // value => {return (/^#[0-9a-f]{3,6}$/i).test(value);
    _defineProperty(this, "defaultColor", void 0);
    _defineProperty(this, "color", void 0);
    _defineProperty(this, "canvas", void 0);
    _defineProperty(this, "selectedColor", void 0);
  }
  mounted() {
    this.color = this.defaultColor;
    this.selectedColor = this.color;
    this.canvas = document.getElementById('canvas');
  }
  onClick() {
    this.selectAColor(this.color);
  }
  selectAColor(color) {
    this.color = color;
    this.selectedColor = color;
    this.$emit('onSelection', this.color);
  }
  onMouseMove(evt) {
    const hexColor = this.getHexColor(evt);
    this.color = hexColor;
  }
  onMouseOut(evt) {
    this.color = this.selectedColor;
  }
  getHexColor(evt) {
    const canvasX = evt.pageX - this.canvas.offsetLeft;
    const canvasY = evt.pageY - this.canvas.offsetTop;
    const yPosition = (this.canvas.height - canvasY) / this.canvas.height * 100;
    const percent = Math.round((this.canvas.width - canvasX) / this.canvas.width * 100);
    const h = 3.6 * percent;
    const s = Math.round(yPosition / 3 + 50);
    const l = Math.round(yPosition / 3 + 40);
    const hex = this.hslToHex(h, s, l);
    return hex;
  }
  hslToHex(h, s, l) {
    h /= 360;
    s /= 100;
    l /= 100;
    let r;
    let g;
    let b;
    if (s === 0) {
      r = g = b = l;
    } else {
      const hueToRgb = (p, q, t) => {
        if (t < 0) {
          t += 1;
        }
        if (t > 1) {
          t -= 1;
        }
        if (t < 1 / 6) {
          return p + (q - p) * 6 * t;
        }
        if (t < 1 / 2) {
          return q;
        }
        if (t < 2 / 3) {
          return p + (q - p) * (2 / 3 - t) * 6;
        }
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hueToRgb(p, q, h + 1 / 3);
      g = hueToRgb(p, q, h);
      b = hueToRgb(p, q, h - 1 / 3);
    }
    const toHex = x => {
      const hex = Math.round(x * 255).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`.toUpperCase();
  }
}
__decorate([Prop({
  default: 800
})], ColorPicker.prototype, "width", void 0);
__decorate([Prop({
  default: 100
})], ColorPicker.prototype, "height", void 0);
__decorate([Prop({
  default: true
})], ColorPicker.prototype, "panelChoose", void 0);
__decorate([Prop({
  default: '#F8550F'
})], ColorPicker.prototype, "defaultColor", void 0);
__decorate([Prop({
  default: '#F8550F'
})], ColorPicker.prototype, "color", void 0);