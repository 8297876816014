import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7de6988e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "error-dialog__msg-block"
};
const _hoisted_2 = {
  class: "error-dialog__footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_icon = _resolveComponent("w-icon");
  const _component_w_standard_button = _resolveComponent("w-standard-button");
  const _component_w_dialog = _resolveComponent("w-dialog");
  return _openBlock(), _createBlock(_component_w_dialog, {
    visible: _ctx.isVisible,
    title: _ctx.title || _ctx.$t('generic_dialog.error.default_title').toString(),
    width: _ctx.width,
    "close-on-click-modal": _ctx.closeOnOutsideClick,
    "show-close": _ctx.showClose,
    onDialogClose: _cache[0] || (_cache[0] = $event => _ctx.handleDialogClose())
  }, {
    footer: _withCtx(() => [_createElementVNode("span", _hoisted_2, [_createVNode(_component_w_standard_button, {
      onClick: _ctx.handleDialogClose
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('generic_dialog.error.btn_ok').toString()), 1)]),
      _: 1
    }, 8, ["onClick"])])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_w_icon, {
      icon: "alert-circle-outline",
      size: "48px",
      class: "error-icon"
    }), _createElementVNode("div", null, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])])]),
    _: 3
  }, 8, ["visible", "title", "width", "close-on-click-modal", "show-close"]);
}