import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
let UserCell = class UserCell extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "image", void 0);
    _defineProperty(this, "firstName", void 0);
    _defineProperty(this, "lastName", void 0);
    _defineProperty(this, "email", void 0);
  }
};
__decorate([Prop({
  default: ""
})], UserCell.prototype, "image", void 0);
__decorate([Prop({
  default: ""
})], UserCell.prototype, "firstName", void 0);
__decorate([Prop({
  default: ""
})], UserCell.prototype, "lastName", void 0);
__decorate([Prop({
  default: ""
})], UserCell.prototype, "email", void 0);
UserCell = __decorate([Options({
  components: {}
})], UserCell);
export default UserCell;