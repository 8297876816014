import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
let NativeColorPicker = class NativeColorPicker extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "defaultColor", null);
    _defineProperty(this, "width", void 0);
    _defineProperty(this, "selectedColor", '');
  }
  mounted() {
    this.selectedColor = this.defaultColor;
  }
  selectAColor(color) {
    this.selectedColor = color;
    this.$emit('onSelection', this.selectedColor);
  }
  onColorInputChanges(color) {
    this.selectedColor = color.target.value;
  }
  updateSelectedColor(color) {
    this.selectedColor = color;
  }
  onBlur() {
    this.selectAColor(this.selectedColor);
  }
};
__decorate([Prop()], NativeColorPicker.prototype, "defaultColor", void 0);
__decorate([Prop({
  default: '160px'
})], NativeColorPicker.prototype, "width", void 0);
NativeColorPicker = __decorate([Options({
  watch: {
    defaultColor() {
      this.selectedColor = this.defaultColor;
    }
  }
})], NativeColorPicker);
export default NativeColorPicker;