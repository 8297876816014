import { ElMessage } from "element-plus";

export default class MessagesUtil {

    static showSuccessMessage(message: string, duration?: number): void {
        ElMessage.success({message: message, duration: duration});
    }

    static showErrorMessage(message: string, duration?: number): void {
        ElMessage.error({message: message, duration: duration});
    }

    static showInfoMessage(message: string, duration?: number): void {
        ElMessage.info({message: message, duration: duration});
    }
}

