import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import MainTitle from '@/components/title/MainTitle.vue';
import store from '@/store';
import { Options, Vue } from 'vue-class-component';
import { USER_CREATE_PASSWORD } from '@/store/actions/user';
import MessagesUtil from "@/utils/messagesUtil";
import { router } from "@/router";
import CenterTop from "@/components/layout/CenterTop.vue";
let CreatePasswordView = class CreatePasswordView extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "loading", false);
    _defineProperty(this, "errorMsg", '');
    _defineProperty(this, "passwordCreationForm", {
      password: '',
      repassword: ''
    });
  }
  createPassword() {
    if (this.requiredFieldIsMissing()) {
      return;
    }
    this.errorMsg = '';
    this.loading = true;
    const user = store.getters.userConnected;
    store.dispatch(USER_CREATE_PASSWORD, {
      password: this.passwordCreationForm.password,
      repassword: this.passwordCreationForm.repassword,
      user
    }).then(() => {
      this.loading = false;
      this.errorMsg = '';
      this.success();
      router.push('/');
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
  requiredFieldIsMissing() {
    return !this.passwordCreationForm.password || !this.passwordCreationForm.repassword;
  }
  success() {
    MessagesUtil.showSuccessMessage(this.$t('password_creation.password_created_toast.message').toString(), 5000);
  }
};
CreatePasswordView = __decorate([Options({
  components: {
    MainTitle,
    CenterTop
  }
})], CreatePasswordView);
export default CreatePasswordView;