export class Platform {
    public name: string;
    public url: string;
    public logo: string;
    public platformID: string;
    public color: string;
    constructor(name: string, url: string, logo: string, platformID: string, color: string) {
        this.name = name;
        this.url = url;
        this.logo = logo;
        this.platformID = platformID;
        this.color = color;
    }
}

export class PlatformCreateParams {
public name: string;
public url: string;
public logo: string;
public serverID: string;
public color: string;
public title: string;
    constructor(name: string, url: string, logo: string, clusterID: string, color: string, title: string) {
        this.name = name;
        this.url = url;
        this.logo = logo;
        this.serverID = clusterID;
        this.color = color;
        this.title = title;
    }

}
