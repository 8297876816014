import {BACK_ENDPOINT} from '@/store/actions/api';

const STATISTICS_API = `${BACK_ENDPOINT}/statistics`;

export default {
    state: {},
    mutations: {},
    getters: {
        STATISTICS_API_URL: () => {
            return STATISTICS_API;
        }
    },
    actions: {}
}
