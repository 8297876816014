import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import CardInteractive from '@/components/layout/CardInteractive.vue';
import NumberAnimation from '@/components/numberAnimation/NumberAnimation.vue';
import { redirectToIDP } from '@/utils/util';
let AuthItemPreview = class AuthItemPreview extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "authItem", void 0);
    _defineProperty(this, "errorMsg", '');
  }
  createAuthRequest() {
    this.errorMsg = redirectToIDP(this.authItem);
  }
};
__decorate([Prop()], AuthItemPreview.prototype, "authItem", void 0);
AuthItemPreview = __decorate([Options({
  components: {
    NumberAnimation,
    CardInteractive
  }
})], AuthItemPreview);
export default AuthItemPreview;