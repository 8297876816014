export enum SettingSPEKey {
    PLATFORM_CONNEXION_ENABLED = 'PLATFORM_CONNEXION_ENABLED',
    PLATFORM_CREATION_ENABLED = 'PLATFORM_CREATION_ENABLED',
    ADD_USER_ENABLED = 'ADD_USER_ENABLED',
    AUTH_ITEM_ENABLED = 'AUTH_ITEM_ENABLED',
    DEBUG_SSO_ACTIVATED = 'DEBUG_SSO_ACTIVATED',
    COPY_SPEACH_ENABLED = 'COPY_SPEACH_ENABLED',
    IP_WHITELISTING_ENABLED = 'IP_WHITELISTING_ENABLED',
    UNIFIED_STATISTICS_ENABLED = 'UNIFIED_STATISTICS_ENABLED',
}
