import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Card from '@/components/layout/Card.vue';
let AuthItemAttributeTable = class AuthItemAttributeTable extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "authItem", void 0);
    _defineProperty(this, "errorMsg", '');
  }
  get authItemAttribueMapped() {
    const listOfAttriibute = new Array();
    for (const attribute of this.authItem.authItemAttributes) {
      if (attribute.mapping.length > 0) {
        listOfAttriibute.push(attribute);
      }
    }
    return listOfAttriibute;
  }
};
__decorate([Prop()], AuthItemAttributeTable.prototype, "authItem", void 0);
AuthItemAttributeTable = __decorate([Options({
  components: {
    Card
  }
})], AuthItemAttributeTable);
export default AuthItemAttributeTable;