import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import ChoosePlatform from '@/components/platform/copySpeach/ChoosePlatform.vue';
import ChooseSpeach from '@/components/platform/copySpeach/ChooseSpeach.vue';
import { CATEGORIES_LIST, DESTINATION_PLATFORM, RESET_AUTHORS_LIST, RESET_CONFIGURE_SPEACH, RESET_STORE_COPY_SPEACH, SOURCE_PLATFORM } from '@/store/actions/speach';
import ConfigureSpeach from '@/components/platform/copySpeach/ConfigureSpeach.vue';
import ResultCopySpeach from '@/components/platform/copySpeach/ResultCopySpeach.vue';
import { router } from "@/router";
import CenterTop from "@/components/layout/CenterTop.vue";
let CopySpeach = class CopySpeach extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "activeStep", 0);
    _defineProperty(this, "lastStepButtonsEnabled", false);
  }
  mounted() {
    if (!this.isCopySpeachEnabled) {
      this.goToHome();
    }
    this.activeStep = 0;
    store.dispatch(RESET_STORE_COPY_SPEACH);
  }
  // -- Computed variables
  get listCheckedSpeach() {
    return store.getters.listCheckedSpeach;
  }
  get listSpeachToCopy() {
    return store.getters.listSpeachToCopy;
  }
  get nextEnabled() {
    switch (this.activeStep) {
      case 0:
        return this.sourcePlatform !== null;
      case 1:
        return this.listCheckedSpeach.length !== 0;
      case 2:
        return this.destinationPlatform !== null;
      case 3:
        return this.listSpeachToCopy.length !== 0 && this.listSpeachToCopy.find(c => c.userCopy == null || c.categoryCopy == null) == null;
      case 4:
        return false;
      default:
        return true;
    }
  }
  get iconRightNext() {
    return this.activeStep < 3 ? 'arrow-right' : '';
  }
  get iconLeftNext() {
    return this.activeStep === 3 ? 'check' : '';
  }
  get labelNext() {
    return this.activeStep === 3 ? this.$t('copy_speaches.steps_navigate_buttons.finish').toString() : this.$t('copy_speaches.steps_navigate_buttons.next').toString();
  }
  // -- CUSTOM FUNCTIONS
  selectSourcePlaform(platform) {
    store.dispatch(RESET_STORE_COPY_SPEACH);
    store.dispatch(SOURCE_PLATFORM, platform);
    if (platform) {
      store.dispatch(CATEGORIES_LIST, {
        platformID: platform.platformID
      });
    }
  }
  selectDestinationPlatform(platform) {
    store.dispatch(RESET_CONFIGURE_SPEACH);
    store.dispatch(DESTINATION_PLATFORM, platform);
    if (platform) {
      store.dispatch(CATEGORIES_LIST, {
        platformID: platform.platformID
      });
      store.dispatch(RESET_AUTHORS_LIST, platform.platformID);
    }
  }
  reset() {
    store.dispatch(RESET_STORE_COPY_SPEACH);
    this.activeStep = 0;
    this.lastStepButtonsEnabled = false;
  }
  enableLastStepButtons() {
    this.lastStepButtonsEnabled = true;
  }
  goToHome() {
    this.reset();
    router.push({
      name: 'HomePage'
    });
  }
  updateActiveStep(newActiveStepNumber) {
    if (this.activeStep === 1 && newActiveStepNumber === 2) {
      store.dispatch(RESET_CONFIGURE_SPEACH);
      store.dispatch(DESTINATION_PLATFORM, null);
    }
    this.activeStep = newActiveStepNumber;
  }
  get sourcePlatform() {
    return store.getters.sourcePlatform;
  }
  get destinationPlatform() {
    return store.getters.destinationPlatform;
  }
  get isCopySpeachEnabled() {
    return store.getters.isCopySpeachEnabled;
  }
};
CopySpeach = __decorate([Options({
  components: {
    ResultCopySpeach,
    ChooseSpeach,
    ChoosePlatform,
    CenterTop,
    ConfigureSpeach
  }
})], CopySpeach);
export default CopySpeach;