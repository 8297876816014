import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import MainTitle from '@/components/title/MainTitle.vue';
import { Options, Vue } from 'vue-class-component';
import UsersTable from '@/components/users/UsersTable.vue';
import store from '@/store';
import AddUser from "@/views/user/AddUser.vue";
import CenterTop from "@/components/layout/CenterTop.vue";
let ListUserSPE = class ListUserSPE extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "toggleUserCreationDialog", false);
  }
  get isAddUser() {
    return store.getters.isAddUserAvailable;
  }
};
ListUserSPE = __decorate([Options({
  components: {
    UsersTable,
    CenterTop,
    MainTitle,
    AddUser
  }
})], ListUserSPE);
export default ListUserSPE;