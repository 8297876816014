import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Card from '@/components/layout/Card.vue';
import { AuthItemAttribute, AuthItemAttributeMapping } from '@/store/models/authItem';
import { AuthItemAttributeMappingType, AuthItemAttributeRuleMapping } from '@/store/enums/authItemEnum';
import MainTitle from '@/components/title/MainTitle.vue';
import store from '@/store';
import { AUTH_ITEM_CHANGE_ATTRIBUTE } from '@/store/actions/authItem';
let AuthItemsAttributeMappingDialog = class AuthItemsAttributeMappingDialog extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "isMapAttributeModalActive", void 0);
    _defineProperty(this, "visible", false);
    _defineProperty(this, "authItemAttributesOptions", []);
    _defineProperty(this, "additionalRuleOptions", []);
    _defineProperty(this, "authItemListOptions", []);
    _defineProperty(this, "authItem", void 0);
    _defineProperty(this, "errorMsg", '');
    _defineProperty(this, "loading", false);
    _defineProperty(this, "UNMAPPED", 'UNMAPPED');
    _defineProperty(this, "emailAttributeMap", this.UNMAPPED);
    _defineProperty(this, "loginAttributeMap", this.UNMAPPED);
    _defineProperty(this, "firstNameAttributeMap", this.UNMAPPED);
    _defineProperty(this, "lastNameAttributeMap", this.UNMAPPED);
    _defineProperty(this, "trainerAttributeMap", this.UNMAPPED);
    _defineProperty(this, "approverAttributeMap", this.UNMAPPED);
    _defineProperty(this, "adminAttributeMap", this.UNMAPPED);
    _defineProperty(this, "groupAttributeMap", this.UNMAPPED);
    _defineProperty(this, "authorizationAttributeMap", this.UNMAPPED);
    _defineProperty(this, "ruleTrainer", AuthItemAttributeRuleMapping.EXIST);
    _defineProperty(this, "ruleApprover", AuthItemAttributeRuleMapping.EXIST);
    _defineProperty(this, "ruleAdmin", AuthItemAttributeRuleMapping.EXIST);
    _defineProperty(this, "ruleGroup", AuthItemAttributeRuleMapping.LIST);
    _defineProperty(this, "ruleValueTrainer", 'true');
    _defineProperty(this, "ruleValueApprover", 'true');
    _defineProperty(this, "ruleValueAdmin", 'true');
    _defineProperty(this, "ruleValueGroup", ',');
  }
  setAdditionalRuleOptions() {
    this.additionalRuleOptions = [{
      'label': this.$t('authentications_management.auth_item.auth_mappings_modal.profiles_block.additional_rule_options.contain_value').toString(),
      'value': AuthItemAttributeRuleMapping.CONTAIN_VALUE
    }, {
      'label': this.$t('authentications_management.auth_item.auth_mappings_modal.profiles_block.additional_rule_options.exact_value').toString(),
      'value': AuthItemAttributeRuleMapping.EXACT_VALUE
    }, {
      'label': this.$t('authentications_management.auth_item.auth_mappings_modal.profiles_block.additional_rule_options.not_contain').toString(),
      'value': AuthItemAttributeRuleMapping.NOT_CONTAIN
    }, {
      'label': this.$t('authentications_management.auth_item.auth_mappings_modal.profiles_block.additional_rule_options.exist').toString(),
      'value': AuthItemAttributeRuleMapping.EXIST
    }];
  }
  setAuthItemListOptions() {
    this.authItemListOptions = [{
      'label': this.$t('authentications_management.auth_item.auth_mappings_modal.groups_affectation_block.list_options.split').toString(),
      'value': AuthItemAttributeRuleMapping.SPLIT
    }, {
      'label': this.$t('authentications_management.auth_item.auth_mappings_modal.groups_affectation_block.list_options.list').toString(),
      'value': AuthItemAttributeRuleMapping.LIST
    }];
  }
  get authItemAttributeRuleMapping() {
    return AuthItemAttributeRuleMapping;
  }
  get valueExplanationToolTip() {
    return this.$t('authentications_management.auth_item.auth_mappings_modal.mapping_value_tooltip').toString();
  }
  recordMapping() {
    const mapAttribute = {};
    const addMapping = (name, type, valueMap, ruleType) => {
      if (name === this.UNMAPPED) {
        return;
      }
      // //we search the email Map
      // //email Mapping
      let attribute = mapAttribute[name];
      if (attribute === undefined) {
        attribute = new AuthItemAttribute();
        attribute.name = name;
        attribute.mapping = new Array();
        attribute.testValue = '';
        mapAttribute[name] = attribute;
      }
      const mapping = new AuthItemAttributeMapping();
      mapping.ruleType = ruleType;
      mapping.valueMap = valueMap;
      mapping.type = type;
      attribute.mapping.push(mapping);
    };
    // map email Attribute
    addMapping(this.emailAttributeMap, AuthItemAttributeMappingType.EMAIL, '', AuthItemAttributeRuleMapping.NO_RULE);
    addMapping(this.loginAttributeMap, AuthItemAttributeMappingType.LOGIN, '', AuthItemAttributeRuleMapping.NO_RULE);
    addMapping(this.firstNameAttributeMap, AuthItemAttributeMappingType.FIRSTNAME, '', AuthItemAttributeRuleMapping.NO_RULE);
    addMapping(this.lastNameAttributeMap, AuthItemAttributeMappingType.LASTNAME, '', AuthItemAttributeRuleMapping.NO_RULE);
    addMapping(this.trainerAttributeMap, AuthItemAttributeMappingType.TRAINER, this.ruleValueTrainer, this.ruleTrainer);
    addMapping(this.approverAttributeMap, AuthItemAttributeMappingType.APPROVER, this.ruleValueApprover, this.ruleApprover);
    addMapping(this.adminAttributeMap, AuthItemAttributeMappingType.ADMINISTRATOR, this.ruleValueAdmin, this.ruleAdmin);
    addMapping(this.groupAttributeMap, AuthItemAttributeMappingType.GROUPS, this.ruleValueGroup, this.ruleGroup);
    addMapping(this.authorizationAttributeMap, AuthItemAttributeMappingType.AUTHORIZATION, '', AuthItemAttributeRuleMapping.NO_RULE);
    this.loading = true;
    store.dispatch(AUTH_ITEM_CHANGE_ATTRIBUTE, {
      authItemID: this.authItem.authItemID,
      attributes: Object.values(mapAttribute)
    }).then(resp => {
      this.loading = false;
      this.onDialogClose();
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
  mounted() {
    this.setAdditionalRuleOptions();
    this.setAuthItemListOptions();
    this.buildAuthItemsAttributesOptions();
    let attribute = this.attributeFor(AuthItemAttributeMappingType.EMAIL);
    if (attribute) {
      this.emailAttributeMap = attribute.name;
    }
    attribute = this.attributeFor(AuthItemAttributeMappingType.LOGIN);
    if (attribute) {
      this.loginAttributeMap = attribute.name;
    }
    attribute = this.attributeFor(AuthItemAttributeMappingType.FIRSTNAME);
    if (attribute) {
      this.firstNameAttributeMap = attribute.name;
    }
    attribute = this.attributeFor(AuthItemAttributeMappingType.LASTNAME);
    if (attribute) {
      this.lastNameAttributeMap = attribute.name;
    }
    attribute = this.attributeFor(AuthItemAttributeMappingType.TRAINER);
    if (attribute) {
      this.trainerAttributeMap = attribute.name;
      const mapping = this.mappedForAttribute(attribute, AuthItemAttributeMappingType.TRAINER);
      this.ruleTrainer = mapping.ruleType;
      this.ruleValueTrainer = mapping.valueMap;
    }
    attribute = this.attributeFor(AuthItemAttributeMappingType.APPROVER);
    if (attribute) {
      this.approverAttributeMap = attribute.name;
      const mapping = this.mappedForAttribute(attribute, AuthItemAttributeMappingType.APPROVER);
      this.ruleApprover = mapping.ruleType;
      this.ruleValueApprover = mapping.valueMap;
    }
    attribute = this.attributeFor(AuthItemAttributeMappingType.ADMINISTRATOR);
    if (attribute) {
      this.adminAttributeMap = attribute.name;
      const mapping = this.mappedForAttribute(attribute, AuthItemAttributeMappingType.ADMINISTRATOR);
      this.ruleAdmin = mapping.ruleType;
      this.ruleValueAdmin = mapping.valueMap;
    }
    attribute = this.attributeFor(AuthItemAttributeMappingType.GROUPS);
    if (attribute) {
      this.groupAttributeMap = attribute.name;
      const mapping = this.mappedForAttribute(attribute, AuthItemAttributeMappingType.GROUPS);
      this.ruleGroup = mapping.ruleType;
      this.ruleValueGroup = mapping.valueMap;
    }
    attribute = this.attributeFor(AuthItemAttributeMappingType.AUTHORIZATION);
    if (attribute) {
      this.authorizationAttributeMap = attribute.name;
    }
  }
  buildAuthItemsAttributesOptions() {
    this.authItemAttributesOptions.push({
      value: this.UNMAPPED,
      label: this.$t('authentications_management.auth_item.auth_mappings_modal.unmapped_keyword').toString()
    });
    this.authItemAttributesOptions.push(...this.authItem.authItemAttributes?.map(attr => ({
      value: attr.name,
      label: attr.name
    })));
  }
  getLastValueForName(name) {
    const attribute = this.attributeForName(name);
    if (attribute) {
      return attribute.testValue;
    } else {
      return '';
    }
  }
  attributeFor(authItemAttributeMappingType) {
    for (const attribute of this.authItem.authItemAttributes) {
      for (const attributeMapping of attribute.mapping) {
        if (attributeMapping.type === authItemAttributeMappingType) {
          return attribute;
        }
      }
    }
    return undefined;
  }
  mappedForAttribute(attribute, authItemAttributeMappingType) {
    for (const mapping of attribute.mapping) {
      if (mapping.type === authItemAttributeMappingType) {
        return mapping;
      }
    }
    return undefined;
  }
  attributeForName(name) {
    for (const attribute of this.authItem.authItemAttributes) {
      if (attribute.name === name) {
        return attribute;
      }
    }
    return undefined;
  }
  onDialogClose() {
    this.visible = false;
    this.errorMsg = '';
    this.$emit("dialogClose");
  }
};
__decorate([Prop({
  default: false
})], AuthItemsAttributeMappingDialog.prototype, "isMapAttributeModalActive", void 0);
__decorate([Prop()], AuthItemsAttributeMappingDialog.prototype, "authItem", void 0);
AuthItemsAttributeMappingDialog = __decorate([Options({
  components: {
    MainTitle,
    Card
  },
  watch: {
    isMapAttributeModalActive() {
      this.visible = this.isMapAttributeModalActive;
    }
  }
})], AuthItemsAttributeMappingDialog);
export default AuthItemsAttributeMappingDialog;