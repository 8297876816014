import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import store from '@/store';
import Card from '@/components/layout/Card.vue';
import { SettingChangeParams } from '@/store/models/settings';
import { SETTING_CHANGE } from '@/store/actions/platforms';
let SettingItem = class SettingItem extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "keyValue", void 0);
    _defineProperty(this, "platform", void 0);
    _defineProperty(this, "label", void 0);
    _defineProperty(this, "disabled", void 0);
    _defineProperty(this, "icon", void 0);
    _defineProperty(this, "tooltip", void 0);
    _defineProperty(this, "value", false);
    _defineProperty(this, "errorMsg", '');
  }
  get settingValue() {
    if (this.setting === undefined) {
      return false;
    } else {
      const value = this.setting[this.keyValue];
      return value;
    }
  }
  get setting() {
    return store.getters.settings(this.platform.platformID);
  }
  settingChange(event) {
    this.value = this.setting[this.keyValue];
  }
  mounted() {
    this.value = this.settingValue;
  }
  changeValue() {
    const params = new SettingChangeParams(this.platform.platformID, this.keyValue, this.value);
    store.dispatch(SETTING_CHANGE, params).then(() => {
      this.errorMsg = '';
    }).catch(err => {
      this.errorMsg = err;
    });
  }
};
__decorate([Prop()], SettingItem.prototype, "keyValue", void 0);
__decorate([Prop()], SettingItem.prototype, "platform", void 0);
__decorate([Prop({
  default: ''
})], SettingItem.prototype, "label", void 0);
__decorate([Prop({
  default: false
})], SettingItem.prototype, "disabled", void 0);
__decorate([Prop({
  default: ''
})], SettingItem.prototype, "icon", void 0);
__decorate([Prop({
  default: ''
})], SettingItem.prototype, "tooltip", void 0);
__decorate([Watch('setting', {
  immediate: true,
  deep: true
})], SettingItem.prototype, "settingChange", null);
SettingItem = __decorate([Options({
  components: {
    Card
  }
})], SettingItem);
export default SettingItem;