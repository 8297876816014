import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import HeaderTable from '@/components/platform/copySpeach/HeaderTable.vue';
import TitleCell from '@/components/platform/copySpeach/TitleCell.vue';
import { CopySpeachStatus } from '@/store/enums/speachEnum';
import Cell from '@/components/platform/copySpeach/Cell.vue';
import UserCell from '@/components/platform/copySpeach/UserCell.vue';
let SpeachConfigureTable = class SpeachConfigureTable extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "listSpeach", void 0);
  }
  getIconFromStatus(status) {
    switch (status) {
      case CopySpeachStatus.SUCCESS:
        {
          return "check-circle-outline";
        }
      case CopySpeachStatus.ERROR:
        {
          return "alert-circle-outline";
        }
      default:
        {
          return "clock-outline";
        }
    }
  }
  getTypeFromStatus(status) {
    switch (status) {
      case CopySpeachStatus.SUCCESS:
        {
          return "is-success";
        }
      case CopySpeachStatus.ERROR:
        {
          return "is-danger";
        }
      default:
        {
          return "is-black";
        }
    }
  }
};
__decorate([Prop({
  default: []
})], SpeachConfigureTable.prototype, "listSpeach", void 0);
SpeachConfigureTable = __decorate([Options({
  components: {
    HeaderTable,
    TitleCell,
    Cell,
    UserCell
  }
})], SpeachConfigureTable);
export default SpeachConfigureTable;