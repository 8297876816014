import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import store from '@/store';
import { USER_ADD } from '@/store/actions/user';
import { User } from '@/store/models/user';
import { capitalize, generateRandomString, isEmail } from '@/utils/util';
import AccountCreationNotificationDialog from "@/components/dialog/PasswordNotificationDialog.vue";
let AddUser = class AddUser extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "toggleUserCreationDialog", void 0);
    _defineProperty(this, "login", '');
    _defineProperty(this, "firstname", '');
    _defineProperty(this, "lastname", '');
    _defineProperty(this, "contact", '');
    _defineProperty(this, "errorMsg", '');
    _defineProperty(this, "loading", false);
    _defineProperty(this, "dialogIsVisible", false);
    _defineProperty(this, "toggleAccountCreationNotificationDialog", false);
    _defineProperty(this, "createdUser", null);
    _defineProperty(this, "createdAccountUrl", null);
  }
  addUser() {
    this.errorMsg = '';
    this.loading = true;
    const user = new User(this.login, this.firstname, this.lastname, this.contact, '');
    const tmpPassword = generateRandomString(30);
    store.dispatch(USER_ADD, {
      user,
      tmpPassword
    }).then(resp => {
      this.closeUserCreationDialog();
      this.createdUser = user;
      this.informPassword(user, tmpPassword);
      this.loading = false;
      this.errorMsg = '';
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
  fillprofil(login) {
    this.login = login;
    if (isEmail(this.login)) {
      const email = this.login.split('@');
      if (email[0].indexOf('.') > -1 || email[0].indexOf('_') > -1) {
        const fullname = email[0].split('.');
        const firstname = fullname[0];
        const lastname = fullname[1];
        if (this.firstname === '') {
          this.firstname = capitalize(firstname);
        }
        if (this.lastname === '') {
          this.lastname = capitalize(lastname);
        }
      } else {
        if (this.firstname === '') {
          this.firstname = capitalize(email[0]);
        }
        if (this.lastname === '') {
          this.lastname = capitalize(email[0]);
        }
      }
      this.contact = this.login;
    }
  }
  informPassword(user, tmpPassword) {
    this.createdAccountUrl = document.location.origin + '/initialize/' + user.login + '/' + tmpPassword;
    this.toggleAccountCreationNotificationDialog = true;
  }
  handleLogin(login) {
    this.login = login;
  }
  handleFirstName(firstname) {
    this.firstname = firstname;
  }
  handleLastName(lastname) {
    this.lastname = lastname;
  }
  handleContact(contact) {
    this.contact = contact;
  }
  shouldDisableSubmit() {
    return !this.login?.trim() || !this.firstname?.trim() || !this.lastname?.trim();
  }
  closeUserCreationDialog() {
    this.dialogIsVisible = false;
    this.errorMsg = '';
    this.$emit("dialogClose");
  }
};
__decorate([Prop({
  default: false
})], AddUser.prototype, "toggleUserCreationDialog", void 0);
AddUser = __decorate([Options({
  components: {
    AccountCreationNotificationDialog
  },
  watch: {
    toggleUserCreationDialog() {
      this.dialogIsVisible = this.toggleUserCreationDialog;
    }
  }
})], AddUser);
export default AddUser;