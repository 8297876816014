import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
export default class SidePanel extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "visible", void 0);
    _defineProperty(this, "title", void 0);
  }
}
__decorate([Prop({
  default: false
})], SidePanel.prototype, "visible", void 0);
__decorate([Prop({
  default: ''
})], SidePanel.prototype, "title", void 0);