import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
let TitleCell = class TitleCell extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "image", void 0);
    _defineProperty(this, "title", void 0);
  }
};
__decorate([Prop({
  default: ""
})], TitleCell.prototype, "image", void 0);
__decorate([Prop({
  default: ""
})], TitleCell.prototype, "title", void 0);
TitleCell = __decorate([Options({
  components: {}
})], TitleCell);
export default TitleCell;