import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import CenterTop from "@/components/layout/CenterTop.vue";
import Banner from "@/components/banner/Banner.vue";
import axios from "axios";
import { router } from "@/router";
let UnifiedStatistics = class UnifiedStatistics extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "internalMetabaseDashboardEmbedUrl", '');
    _defineProperty(this, "errorMsg", '');
  }
  mounted() {
    if (!this.isUnifiedStatisticsEnabled) {
      router.push({
        name: 'HomePage'
      });
    }
    this.requestMetabaseEmbedUrl();
  }
  get isUnifiedStatisticsEnabled() {
    return store.getters.isUnifiedStatisticsEnabled;
  }
  get metabaseDashboardEmbedUrl() {
    return this.internalMetabaseDashboardEmbedUrl;
  }
  set metabaseDashboardEmbedUrl(newUrl) {
    if (newUrl !== this.internalMetabaseDashboardEmbedUrl) {
      this.internalMetabaseDashboardEmbedUrl = newUrl;
    }
  }
  requestMetabaseEmbedUrl() {
    axios({
      url: `${store.getters.STATISTICS_API_URL}/metabase-embed-url`,
      method: 'POST'
    }).then(response => {
      this.errorMsg = '';
      this.metabaseDashboardEmbedUrl = response.data;
    }).catch(err => {
      this.errorMsg = err?.data || err;
    });
  }
};
UnifiedStatistics = __decorate([Options({
  components: {
    Banner,
    CenterTop
  }
})], UnifiedStatistics);
export default UnifiedStatistics;