import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
let AuthItemDeleteDialog = class AuthItemDeleteDialog extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "toggleDialog", void 0);
    _defineProperty(this, "visible", false);
  }
  onDialogClose() {
    this.visible = false;
    this.$emit("dialogClose");
  }
  onDeleteConfirm() {
    this.visible = false;
    this.$emit("deleteConfirm");
  }
};
__decorate([Prop({
  default: false
})], AuthItemDeleteDialog.prototype, "toggleDialog", void 0);
AuthItemDeleteDialog = __decorate([Options({
  watch: {
    toggleDialog() {
      this.visible = this.toggleDialog;
    }
  }
})], AuthItemDeleteDialog);
export default AuthItemDeleteDialog;