import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-810cdf78"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "menuContainer"
};
const _hoisted_2 = {
  class: "submenu-label"
};
const _hoisted_3 = {
  class: "submenu-label"
};
const _hoisted_4 = {
  class: "submenu-label"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_icon = _resolveComponent("w-icon");
  const _component_el_menu_item = _resolveComponent("el-menu-item");
  const _component_el_sub_menu = _resolveComponent("el-sub-menu");
  const _component_el_menu = _resolveComponent("el-menu");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_menu, {
    class: "es-menu",
    router: true,
    "default-openeds": ['sub-menu'],
    "default-active": _ctx.$route.path
  }, {
    default: _withCtx(() => [_createVNode(_component_el_sub_menu, {
      index: "sub-menu",
      class: "es-submenu"
    }, {
      title: _withCtx(() => [_createVNode(_component_w_icon, {
        icon: "home-group",
        class: "submenu-icon"
      }), _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('menu.platforms').toString()), 1)]),
      default: _withCtx(() => [_createVNode(_component_el_menu_item, {
        index: "/",
        class: "es-submenu__item"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('menu.platforms_management').toString()), 1)]),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "/data/copySpeach",
        disabled: !_ctx.isCopySpeachEnabled,
        class: "es-submenu__item"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('menu.copy_speaches').toString()), 1)]),
        _: 1
      }, 8, ["disabled"]), _createVNode(_component_el_menu_item, {
        index: "/statistics",
        disabled: !_ctx.isUnifiedStatisticsEnabled,
        class: "es-submenu__item"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('menu.statistics').toString()), 1)]),
        _: 1
      }, 8, ["disabled"])]),
      _: 1
    }), _createVNode(_component_el_sub_menu, {
      index: "sub-menu",
      class: "es-submenu"
    }, {
      title: _withCtx(() => [_createVNode(_component_w_icon, {
        icon: "account-group-outline",
        class: "submenu-icon"
      }), _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('menu.users').toString()), 1)]),
      default: _withCtx(() => [_createVNode(_component_el_menu_item, {
        index: "/user/access",
        class: "es-submenu__item"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('menu.users_management').toString()), 1)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_sub_menu, {
      index: "sub-menu",
      class: "es-submenu"
    }, {
      title: _withCtx(() => [_createVNode(_component_w_icon, {
        icon: "security",
        class: "submenu-icon"
      }), _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('menu.authentication').toString()), 1)]),
      default: _withCtx(() => [_createVNode(_component_el_menu_item, {
        index: "/authItems",
        disabled: !_ctx.isAuthItem,
        class: "es-submenu__item"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('menu.authentication_management').toString()), 1)]),
        _: 1
      }, 8, ["disabled"]), _createVNode(_component_el_menu_item, {
        index: "/authLinks",
        disabled: !_ctx.isAuthItem,
        class: "es-submenu__item"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('menu.link_management').toString()), 1)]),
        _: 1
      }, 8, ["disabled"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["default-active"])]);
}