import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-67d890a8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main"
};
const _hoisted_2 = {
  class: "themeLayout"
};
const _hoisted_3 = {
  class: "blockLogo"
};
const _hoisted_4 = {
  key: 0,
  class: "imgPreviewContainer"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  key: 1,
  class: "content has-text-centered"
};
const _hoisted_7 = {
  class: "upload-icon-block"
};
const _hoisted_8 = {
  class: "el-upload__text upload-draggable-description"
};
const _hoisted_9 = {
  class: "colorBlock"
};
const _hoisted_10 = {
  class: "container-button"
};
const _hoisted_11 = {
  class: "create-platform-btn__block"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Banner = _resolveComponent("Banner");
  const _component_w_text_input = _resolveComponent("w-text-input");
  const _component_w_label = _resolveComponent("w-label");
  const _component_w_icon = _resolveComponent("w-icon");
  const _component_el_upload = _resolveComponent("el-upload");
  const _component_native_color_picker = _resolveComponent("native-color-picker");
  const _component_CenterCenter = _resolveComponent("CenterCenter");
  const _component_w_standard_button = _resolveComponent("w-standard-button");
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  const _component_el_dropdown = _resolveComponent("el-dropdown");
  const _component_w_dialog = _resolveComponent("w-dialog");
  return _openBlock(), _createBlock(_component_w_dialog, {
    visible: _ctx.visible,
    title: _ctx.$t('platforms_management.create_platform_dialog.title'),
    "custom-class": "create-platform-dialog",
    onDialogClose: _cache[1] || (_cache[1] = $event => _ctx.onDialogClose())
  }, {
    footer: _withCtx(() => [_createElementVNode("div", _hoisted_10, [_ctx.clusters().length == 1 ? (_openBlock(), _createBlock(_component_w_standard_button, {
      key: 0,
      "is-loading": _ctx.loading,
      type: "primary",
      "min-width": "128px",
      disabled: _ctx.loadData || !_ctx.canBeSubmitted(),
      onClick: _cache[0] || (_cache[0] = $event => _ctx.createPlatform())
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('platforms_management.create_platform_dialog.create_btn')), 1)]),
      _: 1
    }, 8, ["is-loading", "disabled"])) : _createCommentVNode("", true), _ctx.clusters().length > 1 ? (_openBlock(), _createBlock(_component_el_dropdown, {
      key: 1,
      placement: "bottom-end",
      trigger: "click",
      onCommand: _ctx.createPlatform
    }, {
      dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, {
        class: "select-cluster-dropdown"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clusters(), clusterId => {
          return _openBlock(), _createBlock(_component_el_dropdown_item, {
            key: clusterId,
            command: clusterId
          }, {
            default: _withCtx(() => [_createVNode(_component_w_icon, {
              icon: "server",
              class: "cluster-icon"
            }), _createTextVNode(" " + _toDisplayString(_ctx.$t('platforms_management.create_platform_dialog.platform_creation_target_label', {
              targetId: clusterId
            })), 1)]),
            _: 2
          }, 1032, ["command"]);
        }), 128))]),
        _: 1
      })]),
      default: _withCtx(() => [_createVNode(_component_w_standard_button, {
        "is-loading": _ctx.loading,
        type: "primary",
        "min-width": "128px",
        class: "create-platform-btn",
        disabled: _ctx.loadData || !_ctx.canBeSubmitted()
      }, {
        default: _withCtx(() => [_createElementVNode("span", _hoisted_11, [_createTextVNode(_toDisplayString(_ctx.$t('platforms_management.create_platform_dialog.create_btn')) + " ", 1), _createVNode(_component_w_icon, {
          icon: "chevron-down",
          size: "22px"
        })])]),
        _: 1
      }, 8, ["is-loading", "disabled"])]),
      _: 1
    }, 8, ["onCommand"])) : _createCommentVNode("", true)])]),
    default: _withCtx(() => [_createVNode(_component_CenterCenter, null, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
        key: 0,
        type: "error"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)]),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_w_text_input, {
        "field-label": `${_ctx.$t('platforms_management.create_platform_dialog.platform_name_field_label')}`,
        placeholder: "my-business-unit",
        value: _ctx.name,
        size: "large",
        width: "512px",
        "left-pin": "https://",
        "right-pin": `.${_ctx.CLUSTER_DOMAIN}`,
        onInputChanged: _ctx.autoFillUrl,
        onBlur: _ctx.slugifyPlatformName
      }, null, 8, ["field-label", "value", "right-pin", "onInputChanged", "onBlur"]), _createVNode(_component_w_text_input, {
        "field-label": `${_ctx.$t('platforms_management.create_platform_dialog.platform_title_field_label')}`,
        placeholder: "Amazing speach platform",
        size: "large",
        width: "512px",
        onInputChanged: _ctx.updateTitle
      }, null, 8, ["field-label", "onInputChanged"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_w_label, null, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('platforms_management.create_platform_dialog.platform_logo_field_label')), 1)]),
        _: 1
      }), _createVNode(_component_el_upload, {
        action: "#",
        "auto-upload": false,
        "show-file-list": false,
        accept: "image/*",
        drag: "",
        "on-change": _ctx.onFileSelected
      }, {
        default: _withCtx(() => [_ctx.logoDataUrl !== '' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("img", {
          class: "logoPreview",
          src: _ctx.logoDataUrl
        }, null, 8, _hoisted_5)])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("p", _hoisted_7, [_createVNode(_component_w_icon, {
          icon: "upload-outline"
        })]), _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('platforms_management.create_platform_dialog.platform_logo_upload_description')), 1)]))]),
        _: 1
      }, 8, ["on-change"])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_w_label, null, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('platforms_management.create_platform_dialog.platform_color_field_label')), 1)]),
        _: 1
      }), _createVNode(_component_native_color_picker, {
        onOnSelection: _ctx.colorSelection
      }, null, 8, ["onOnSelection"])])])])]),
      _: 1
    })]),
    _: 1
  }, 8, ["visible", "title"]);
}