import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_w_select = _resolveComponent("w-select");
  return _openBlock(), _createBlock(_component_w_select, {
    placeholder: _ctx.placeholder,
    name: _ctx.name,
    value: _ctx.realValue,
    width: "100%",
    class: "select-category",
    onChange: _ctx.onChangeValue
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, category => {
      return _openBlock(), _createBlock(_component_el_option, {
        value: category?.id || '',
        key: category?.id,
        label: category?.name
      }, null, 8, ["value", "label"]);
    }), 128))]),
    _: 1
  }, 8, ["placeholder", "name", "value", "onChange"]);
}