
export enum AuthItemType {
    MANUAL= 'MANUAL',
    SAML2= 'SAML2',
}


export enum AuthItemTypeConfiguration {
    IDP_METADATA= 'IDP_METADATA',
    SP_METADATA_DOWNLOADED= 'SP_METADATA_DOWNLOADED',
    SP_ASC_URL = 'SP_ASC_URL',
    SP_ENTITY_ID_PROPERTYKEY = 'SP_ENTITY_ID_PROPERTYKEY',
    SAML_AUTHENTICATION_VALID = 'SAML_AUTHENTICATION_VALID',
}

export enum AuthItemAttributeMappingType {
    FIRSTNAME = 'FIRSTNAME',
    LASTNAME = 'LASTNAME',
    EMAIL = 'EMAIL',
    LOGIN = 'LOGIN',
    TRAINER = 'TRAINER',
    APPROVER = 'APPROVER',
    ADMINISTRATOR = 'ADMINISTRATOR',
    GROUPS = 'GROUPS',
    AUTHORIZATION = 'AUTHORIZATION',
    NO_MAPPING = 'NO_MAPPING',
}

export enum AuthItemAttributeRuleMapping {
    CONTAIN_VALUE = 'CONTAIN_VALUE',
    EXACT_VALUE= 'EXACT_VALUE',
    NOT_CONTAIN= 'NOT_CONTAIN',
    EXIST= 'EXIST',
    SPLIT= 'SPLIT',
    LIST= 'LIST',
    NO_RULE= 'NO_RULE',
}

