import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
let WSelect = class WSelect extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "name", void 0);
    _defineProperty(this, "options", void 0);
    _defineProperty(this, "size", void 0);
    _defineProperty(this, "width", void 0);
    _defineProperty(this, "fieldLabel", void 0);
    _defineProperty(this, "placeholder", void 0);
    _defineProperty(this, "disabled", void 0);
    _defineProperty(this, "value", void 0);
    _defineProperty(this, "currentValue", null);
  }
  mounted() {
    this.currentValue = this.value;
  }
  onInputChanges() {
    if (!this.disabled) {
      this.$emit("change", this.currentValue);
    }
  }
};
__decorate([Prop({
  required: true
})], WSelect.prototype, "name", void 0);
__decorate([Prop()], WSelect.prototype, "options", void 0);
__decorate([Prop({
  default: 'default'
})], WSelect.prototype, "size", void 0);
__decorate([Prop({
  default: 'auto'
})], WSelect.prototype, "width", void 0);
__decorate([Prop()], WSelect.prototype, "fieldLabel", void 0);
__decorate([Prop()], WSelect.prototype, "placeholder", void 0);
__decorate([Prop({
  default: false
})], WSelect.prototype, "disabled", void 0);
__decorate([Prop()], WSelect.prototype, "value", void 0);
WSelect = __decorate([Options({
  watch: {
    value() {
      this.currentValue = this.value;
    }
  }
})], WSelect);
export default WSelect;