import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import CenterCenter from '@/components/layout/CenterCenter.vue';
import MainTitle from '@/components/title/MainTitle.vue';
import store from '@/store';
import { Options, Vue } from 'vue-class-component';
import { AUTH_REQUEST, AuthParams } from '@/store/actions/auth';
import { router } from "@/router";
let LoginView = class LoginView extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "errorMsg", '');
    _defineProperty(this, "loading", false);
    _defineProperty(this, "loginForm", {
      username: '',
      password: ''
    });
  }
  updateUserName(value) {
    this.loginForm.username = value?.trim();
  }
  updatePassword(value) {
    this.loginForm.password = value;
  }
  login() {
    if (this.requiredFieldIsMissing()) {
      return;
    }
    this.errorMsg = '';
    this.loading = true;
    const params = new AuthParams(this.loginForm.username, this.loginForm.password);
    store.dispatch(AUTH_REQUEST, params).then(() => {
      router.push('/');
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
      // TODO catch error
    });
  }
  requiredFieldIsMissing() {
    return !this.loginForm.username || !this.loginForm.password;
  }
};
LoginView = __decorate([Options({
  components: {
    MainTitle,
    CenterCenter
  }
})], LoginView);
export default LoginView;