import { __decorate } from "tslib";
import MainTitle from '@/components/title/MainTitle.vue';
import { Options, Vue } from 'vue-class-component';
import NumberAnimation from '@/components/numberAnimation/NumberAnimation.vue';
import CenterTop from '@/components/layout/CenterTop.vue';
let NotFoundView = class NotFoundView extends Vue {};
NotFoundView = __decorate([Options({
  components: {
    CenterTop,
    NumberAnimation,
    MainTitle
  }
})], NotFoundView);
export default NotFoundView;