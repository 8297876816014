import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0d9b8d0c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dialog-body"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Banner = _resolveComponent("Banner");
  const _component_el_option = _resolveComponent("el-option");
  const _component_w_select = _resolveComponent("w-select");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_w_text_input = _resolveComponent("w-text-input");
  const _component_el_form = _resolveComponent("el-form");
  const _component_w_standard_button = _resolveComponent("w-standard-button");
  const _component_w_dialog = _resolveComponent("w-dialog");
  return _openBlock(), _createBlock(_component_w_dialog, {
    visible: _ctx.visible,
    title: `${_ctx.isUpdateContext() ? _ctx.$t('authentication_links_management.auth_link.form_modal.update_title').toString() : _ctx.$t('authentication_links_management.auth_link.form_modal.creation_title').toString()}`,
    onDialogClose: _cache[0] || (_cache[0] = $event => _ctx.onDialogClose())
  }, {
    footer: _withCtx(() => [_createVNode(_component_w_standard_button, {
      type: "primary",
      minWidth: "104px",
      disabled: !_ctx.canSubmit(),
      "is-loading": _ctx.loading,
      onClick: _ctx.addLink
    }, {
      default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.isUpdateContext() ? _ctx.$t('authentication_links_management.auth_link.form_modal.update_submit_btn') : _ctx.$t('authentication_links_management.auth_link.form_modal.creation_submit_btn')), 1)]),
      _: 1
    }, 8, ["disabled", "is-loading", "onClick"])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
      key: 0,
      type: "error"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)]),
      _: 1
    })) : _createCommentVNode("", true), _createVNode(_component_el_form, {
      model: _ctx.authLinkForm,
      class: "auth-link-form"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        prop: "platform",
        class: "auth-link-form-field"
      }, {
        default: _withCtx(() => [_createVNode(_component_w_select, {
          "field-label": _ctx.$t('authentication_links_management.auth_link.form_modal.platform_field_label').toString(),
          placeholder: _ctx.$t('authentication_links_management.auth_link.form_modal.platform_field_placeholder').toString(),
          disabled: _ctx.isUpdateContext(),
          size: "large",
          width: "512px",
          name: "platform",
          value: _ctx.authLinkForm.platform,
          onChange: _ctx.updateSelectedAuthLinkPlatform
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.platforms, p => {
            return _openBlock(), _createBlock(_component_el_option, {
              value: p.platformID,
              key: p.platformID,
              label: p.name
            }, null, 8, ["value", "label"]);
          }), 128))]),
          _: 1
        }, 8, ["field-label", "placeholder", "disabled", "value", "onChange"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        prop: "authenticationItem",
        class: "auth-link-form-field"
      }, {
        default: _withCtx(() => [_createVNode(_component_w_select, {
          "field-label": _ctx.$t('authentication_links_management.auth_link.form_modal.auth_item_field_label').toString(),
          placeholder: _ctx.$t('authentication_links_management.auth_link.form_modal.auth_item_field_placeholder').toString(),
          disabled: _ctx.isUpdateContext(),
          size: "large",
          width: "512px",
          name: "authenticationItem",
          value: _ctx.authLinkForm.authenticationItem,
          onChange: _ctx.updateSelectedAuthItem
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.authItems, a => {
            return _openBlock(), _createBlock(_component_el_option, {
              value: a.authItemID,
              key: a.authItemID,
              label: a.name
            }, null, 8, ["value", "label"]);
          }), 128))]),
          _: 1
        }, 8, ["field-label", "placeholder", "disabled", "value", "onChange"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        prop: "authenticationAdditionalRuleType",
        class: "auth-link-form-field"
      }, {
        default: _withCtx(() => [_createVNode(_component_w_select, {
          "field-label": _ctx.$t('authentication_links_management.auth_link.form_modal.auth_rule_type_field_label').toString(),
          size: "large",
          width: "512px",
          name: "authenticationAdditionalRuleType",
          options: _ctx.additionalRuleOptions,
          value: _ctx.authLinkForm.authenticationAdditionalRuleType,
          onChange: _ctx.updateSelectedAuthAdditionalRuleType
        }, null, 8, ["field-label", "options", "value", "onChange"])]),
        _: 1
      }), _ctx.authLinkForm.authenticationAdditionalRuleType !== _ctx.authItemAttributeRuleMapping.NO_RULE && _ctx.authLinkForm.authenticationAdditionalRuleType !== _ctx.authItemAttributeRuleMapping.EXIST ? (_openBlock(), _createBlock(_component_w_text_input, {
        key: 0,
        size: "large",
        width: "512px",
        value: _ctx.authLinkForm.authenticationAdditionalRuleValue,
        onInputChanged: _ctx.updateSelectedAuthAdditionalRuleValue
      }, null, 8, ["value", "onInputChanged"])) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["model"])])]),
    _: 1
  }, 8, ["visible", "title"]);
}