import { __decorate } from "tslib";
import CenterTop from '@/components/layout/CenterTop.vue';
import { Options, Vue } from 'vue-class-component';
import { VERSION_FRONT } from '@/store/actions/api';
import store from '@/store';
import { VERSION_BACK } from '@/store/actions/version';
let HelpVersion = class HelpVersion extends Vue {
  mounted() {
    store.dispatch(VERSION_BACK);
  }
  get versionFront() {
    return `${VERSION_FRONT}`;
  }
  get versionBack() {
    return store.getters.versionBack;
  }
};
HelpVersion = __decorate([Options({
  components: {
    CenterTop
  }
})], HelpVersion);
export default HelpVersion;