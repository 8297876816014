import {createApp} from 'vue';
import store from './store';
import i18n from '@/i18n';
import {ObserveVisibility} from 'vue-observe-visibility';
import { router } from './router';
import App from "@/App.vue";
import "@/scss/global.scss";

const mountApp = () => {
    const app = createApp(App);
    app.directive('click-outside', {
        beforeMount: (el, binding) => {
            //  check that click was outside the el and his children
            el.clickOutsideEvent = (event: { target: any; }) => {
                const sidePanelFilterArray = Array.from(document.querySelectorAll('side-panel'));
                const clickedInsideSidePanel = sidePanelFilterArray.length && (sidePanelFilterArray.some((filteredElement) => filteredElement === event.target || filteredElement.contains(event.target)));

                if (!(el == event.target || el.contains(event.target) || clickedInsideSidePanel)) {
                    binding.value(event, el);
                }
            };
            document.addEventListener('mousedown', el.clickOutsideEvent);
        },
        unmounted: el => {
            document.removeEventListener('mousedown', el.clickOutsideEvent);
        },
    });

    app.directive("observe-visibility", {
        beforeMount: (el, binding, vnode) => {
            (vnode as any).context = binding.instance
            ObserveVisibility.bind(el, binding, vnode)
        },
        unmounted: ObserveVisibility.unbind,
    })

    app.use(i18n)
        .use(router)
        .use(store)
        .mount('#app');
}

mountApp();
