import "core-js/modules/es.array.push.js";
import Header from '@/components/header/Header.vue'; // @ is an alias to /src
import Menu from '@/components/menu/Menu.vue'; // @ is an alias to /src
import axios from 'axios';
import store from '@/store';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import { router } from "@/router";
import { getTenantID } from '@/utils/util';
import { SETTINGS_SPE_LIST } from '@/store/actions/configurations';
export default {
  components: {
    Header,
    Menu
  },
  data() {
    return {
      loading: true,
      transitionBack: false,
      routeActive: true
    };
  },
  created() {
    axios.interceptors.response.use(undefined, err => {
      return new Promise((resolve, reject) => {
        if (!err.response) {
          throw err;
        }
        err = err.response;
        if (err && err.status === 401 && err.config && !err.config.__isRetryRequest) {
          // if you ever get an unauthorized, logout the user
          store.dispatch(AUTH_LOGOUT);
          // you can also redirect to /login if needed !
          router.push('/login');
        }
        throw err;
      });
    });
    axios.interceptors.request.use(config => {
      const token = store.getters.token;
      // @ts-ignore
      config.headers.tenantID = getTenantID();
      // @ts-ignore
      config.headers.tenantURL = document.location.origin;
      // @ts-ignore
      config.headers.clientURL = document.location.href;
      if (token) {
        // @ts-ignore
        config.headers.Authorization = 'Bearer ' + token;
        return config;
      } else {
        return config;
      }
    });
    // load your destiny
    this.loading = true;
    store.dispatch(SETTINGS_SPE_LIST).then(() => {
      this.loading = false;
    }).catch(err => {
      this.loading = false;
      router.push({
        name: 'NotFound'
      });
    });
  },
  computed: {
    isMenuVisible() {
      const LIST_ROUTE_WITHOUT_MENU = ['Authentication', 'IdpChoose', 'NotFound', 'authLinkConnect'];
      return store.getters.isAuthenticated && store.getters.isMenuVisible && !LIST_ROUTE_WITHOUT_MENU.includes(this.routeActive);
    }
  },
  watch: {
    $route: {
      handler(to, from) {
        this.routeActive = to.name;
        if (to && to.path && from && from.path) {
          const toDepth = to.path.split('/').length;
          const fromDepth = from.path.split('/').length;
          this.transitionBack = toDepth < fromDepth;
        }
      },
      deep: true,
      immediate: true
    }
  }
};