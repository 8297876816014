import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import MainTitle from '@/components/title/MainTitle.vue';
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import CenterTop from '@/components/layout/CenterTop.vue';
import Footer from '@/components/layout/Footer.vue';
import store from '@/store';
import PlatformPreview from '@/components/platform/PlatformPreview.vue';
import { PLATFORM_DELETE, PLATFORM_REFRESH, SETTINGS_LIST } from '@/store/actions/platforms';
import GridItem from '@/components/layout/GridItem.vue';
import { router } from "@/router";
import NumberAnimation from '@/components/numberAnimation/NumberAnimation.vue';
import SummaryItem from '@/components/platform/SummaryItem.vue';
import Separator from '@/components/layout/Separator.vue';
import { SummaryKey } from '@/store/enums/sumaryKeyEnum';
import SettingsPlatform from "@/views/platform/SettingsPlatform.vue";
import Banner from "@/components/banner/Banner.vue";
import IpWhitelistSettings from "@/views/platform/IPWhitelistSettings.vue";
let DetailPlatform = class DetailPlatform extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "platformID", void 0);
    _defineProperty(this, "loading", false);
    _defineProperty(this, "errorMsg", '');
    _defineProperty(this, "showApprovers", false);
    _defineProperty(this, "overviewTabName", 'overview-tab');
    _defineProperty(this, "settingsTabName", 'settings-tab');
    _defineProperty(this, "ipWhitelistTabName", 'ip-whitelist-tab');
    _defineProperty(this, "breadcrumbItems", []);
    _defineProperty(this, "activeTabName", this.overviewTabName);
  }
  mounted() {
    this.breadcrumbItems = [{
      label: 'Manage platforms',
      isRouterLink: true,
      url: '/'
    }, {
      label: this.platform.name
    }];
    if (this.settingsPlatform(this.platformID)) {
      this.updateShowApprovers();
    } else {
      store.dispatch(SETTINGS_LIST, this.platform).then(() => this.updateShowApprovers());
    }
  }
  get nbSpeachPublishKey() {
    return SummaryKey.NB_SPEACH_PUBLISH;
  }
  get nbSpeachToPublishKey() {
    return SummaryKey.NB_SPEACH_TO_PUBLISH;
  }
  get nbSpeachToValidateKey() {
    return SummaryKey.NB_SPEACH_TO_VALIDATE;
  }
  get nbSpeachMakerKey() {
    return SummaryKey.NB_SPEACH_MAKER;
  }
  get nbApproverKey() {
    return SummaryKey.NB_APPROVER;
  }
  get nbUserKey() {
    return SummaryKey.NB_USER;
  }
  get nbAdminKey() {
    return SummaryKey.NB_ADMIN;
  }
  get nbConnectedMonthKey() {
    return SummaryKey.NB_CONNECTED_THIS_MONTH;
  }
  get nbNewSpeachMonthKey() {
    return SummaryKey.NB_NEW_SPEACH_MONTH;
  }
  get nbNewSpeachWeekKey() {
    return SummaryKey.NB_NEW_SPEACH_WEEK;
  }
  get nbNewSpeach3MonthKey() {
    return SummaryKey.NB_NEW_SPEACH_3MONTH;
  }
  get nbNewSpeach6MonthKey() {
    return SummaryKey.NB_NEW_SPEACH_6MONTH;
  }
  get nbSpeachUniqueRead1Week() {
    return SummaryKey.NB_SPEACH_UNIQUE_READ_ONE_WEEK;
  }
  get nbSpeachUniqueRead1Month() {
    return SummaryKey.NB_SPEACH_UNIQUE_READ_ONE_MONTH;
  }
  get nbSpeachUniqueRead3Months() {
    return SummaryKey.NB_SPEACH_UNIQUE_READ_THREE_MONTHS;
  }
  get nbSpeachUniqueRead6Months() {
    return SummaryKey.NB_SPEACH_UNIQUE_READ_SIX_MONTHS;
  }
  get nbUniqueReadDataExist() {
    return store.getters.summary(this.platform) && (store.getters.summary(this.platform)[SummaryKey.NB_SPEACH_UNIQUE_READ_ONE_WEEK] || store.getters.summary(this.platform)[SummaryKey.NB_SPEACH_UNIQUE_READ_ONE_MONTH] || store.getters.summary(this.platform)[SummaryKey.NB_SPEACH_UNIQUE_READ_THREE_MONTHS] || store.getters.summary(this.platform)[SummaryKey.NB_SPEACH_UNIQUE_READ_SIX_MONTHS]);
  }
  get isUserCanDisconnect() {
    return store.getters.isRemoveConnexionAvailable;
  }
  get speachAdminDashboardUrl() {
    return this.platform.url + '/administration/dashboard';
  }
  getSpeachAdminDashboardUrlByPeriod(period) {
    return this.platform.url + '/administration/dashboard?period=' + period;
  }
  updateShowApprovers() {
    this.showApprovers = this.settingsPlatform(this.platformID).WORKFLOW_ENABLED;
  }
  settingsPlatform(platformID) {
    return store.getters.settings(platformID);
  }
  goToPlatform() {
    window.open(this.platform.url, this.platform.platformID);
  }
  get isSettingAvailable() {
    return store.getters.isPlatformSettingsAvailable;
  }
  goToPlatformSpeachManagement() {
    window.open(this.speachAdminUrl, this.platform.platformID);
  }
  goToPlatformUsersManagement() {
    window.open(this.userAdminUrl, this.platform.platformID);
  }
  get speachAdminUrl() {
    return `${this.platform.url}/administration/s/all`;
  }
  speachAdminUrlByState(state) {
    return `${this.speachAdminUrl}?etatCoursSearchFilter=${state}`;
  }
  get userAdminUrl() {
    return `${this.platform.url}/administration/listUser`;
  }
  get platformVersion() {
    return this.settingsPlatform(this.platformID)?.PLATFORM_VERSION;
  }
  get isIPWhitelistingAvailable() {
    return store.getters.isIPWhitelistingAvailable;
  }
  userAdminUrlByRole(role) {
    return `${this.userAdminUrl}?role=${role}`;
  }
  get platform() {
    const platform = store.getters.platform(this.platformID);
    if (platform === undefined) {
      router.push('/');
    }
    return platform;
  }
  get loadingStyle() {
    return `color: ${this.platform.color};animation: sand 5s infinite ease-in ;`;
  }
  deleteMe() {
    this.errorMsg = '';
    this.loading = true;
    store.dispatch(PLATFORM_DELETE, this.platform).then(resp => {
      this.loading = false;
      this.errorMsg = '';
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
  handleTabClick(tab) {
    this.activeTabName = tab.paneName;
  }
  refreshInformationPlatform() {
    this.loading = true;
    store.dispatch(PLATFORM_REFRESH, this.platform).then(() => {
      this.loading = false;
    }).catch(() => {
      this.loading = false;
    });
  }
};
__decorate([Prop()], DetailPlatform.prototype, "platformID", void 0);
DetailPlatform = __decorate([Options({
  components: {
    Separator,
    SummaryItem,
    NumberAnimation,
    GridItem,
    PlatformPreview,
    Footer,
    CenterTop,
    MainTitle,
    SettingsPlatform,
    Banner: Banner,
    IpWhitelistSettings: IpWhitelistSettings
  }
})], DetailPlatform);
export default DetailPlatform;