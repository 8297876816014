import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
export default class Card extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "disabled", void 0);
  }
}
__decorate([Prop()], Card.prototype, "disabled", void 0);