import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
export default class WSearchBar extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "placeholder", void 0);
    _defineProperty(this, "value", void 0);
    _defineProperty(this, "width", void 0);
    _defineProperty(this, "height", void 0);
    _defineProperty(this, "disabled", void 0);
    _defineProperty(this, "currentValue", '');
  }
  mounted() {
    this.currentValue = this.value;
  }
  onInputUpdate() {
    if (!this.disabled) {
      this.$emit("onInputUpdate", this.currentValue);
    }
  }
}
__decorate([Prop()], WSearchBar.prototype, "placeholder", void 0);
__decorate([Prop()], WSearchBar.prototype, "value", void 0);
__decorate([Prop({
  default: '240px'
})], WSearchBar.prototype, "width", void 0);
__decorate([Prop({
  default: '32px'
})], WSearchBar.prototype, "height", void 0);
__decorate([Prop({
  default: false
})], WSearchBar.prototype, "disabled", void 0);