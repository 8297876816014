import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  id: "layout"
};
const _hoisted_2 = {
  key: 0,
  class: "menuLayout",
  style: {
    "animation-duration": "0.5s"
  }
};
const _hoisted_3 = {
  class: "appLayout"
};
const _hoisted_4 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Header = _resolveComponent("Header");
  const _component_Menu = _resolveComponent("Menu");
  const _component_router_view = _resolveComponent("router-view");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Header), _createElementVNode("div", _hoisted_1, [$options.isMenuVisible ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_Menu)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [_createVNode(_component_router_view, {
    appear: "",
    style: {
      "animation-duration": "0.5s"
    },
    class: "routerView"
  })])]), $data.loading ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, null, 512)), [[_directive_loading, $data.loading], [_directive_loading, true, void 0, {
    fullscreen: true,
    lock: true
  }]]) : _createCommentVNode("", true)], 64);
}