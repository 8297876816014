import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import UserCell from '@/components/platform/copySpeach/UserCell.vue';
import store from '@/store';
let SelectCategory = class SelectCategory extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "name", void 0);
    _defineProperty(this, "platform", void 0);
    _defineProperty(this, "value", void 0);
    _defineProperty(this, "defaultValue", void 0);
    _defineProperty(this, "addEmptyValue", void 0);
    _defineProperty(this, "placeholder", void 0);
    _defineProperty(this, "realValue", null);
  }
  mounted() {
    this.setRealValue();
  }
  get categories() {
    let categories = store.getters.categories(this.platform);
    if (this.addEmptyValue && categories) {
      categories = Array.from(categories);
      categories.unshift(null);
    }
    return categories;
  }
  onChangeValue(selectedValue) {
    this.realValue = selectedValue;
    this.$emit('input', this.categories.find(cat => cat !== null && cat.id === this.realValue));
  }
  onChangePropValue() {
    this.setRealValue();
  }
  onChangePropDefaultValue() {
    this.setRealValue();
  }
  resetCategory() {
    this.realValue = null;
    this.onChangeValue(this.realValue);
  }
  setRealValue() {
    this.realValue = this.value?.id || this.defaultValue?.id;
  }
};
__decorate([Prop({
  default: 'category'
})], SelectCategory.prototype, "name", void 0);
__decorate([Prop({
  default: ''
})], SelectCategory.prototype, "platform", void 0);
__decorate([Prop({
  default: null
})], SelectCategory.prototype, "value", void 0);
__decorate([Prop({
  default: null
})], SelectCategory.prototype, "defaultValue", void 0);
__decorate([Prop({
  default: false
})], SelectCategory.prototype, "addEmptyValue", void 0);
__decorate([Prop({
  default: ''
})], SelectCategory.prototype, "placeholder", void 0);
__decorate([Watch('value')], SelectCategory.prototype, "onChangePropValue", null);
__decorate([Watch('defaultValue')], SelectCategory.prototype, "onChangePropDefaultValue", null);
__decorate([Watch('platform')], SelectCategory.prototype, "resetCategory", null);
SelectCategory = __decorate([Options({
  components: {
    UserCell
  }
})], SelectCategory);
export default SelectCategory;