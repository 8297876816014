import {createStore} from 'vuex'
import auth from '@/store/modules/auth';
import platforms from '@/store/modules/platforms';
import users from '@/store/modules/users';
import configurations from '@/store/modules/configurations';
import rightManagement from '@/store/modules/rightManagement';
import authItem from '@/store/modules/authItem';
import authLink from '@/store/modules/authLink';
import speach from '@/store/modules/speach';
import statistics from '@/store/modules/statistics';


import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
import version from '@/store/modules/version';
import ipWhitelist from '@/store/modules/ipWhitelist';

const ls = new SecureLS({isCompression: false});

export default createStore({
  strict: true,
  modules: {
    auth,
    platforms,
    users,
    configurations,
    rightManagement,
    authItem,
    authLink,
    speach,
    ipWhitelist,
    statistics,
    version
  },
  state: {},
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  mutations: {},
  getters: {},
  actions: {},
});
