import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-28d18be4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main"
};
const _hoisted_2 = {
  class: "settings-block"
};
const _hoisted_3 = {
  class: "line"
};
const _hoisted_4 = {
  class: "labelLine"
};
const _hoisted_5 = {
  class: "switchLine"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MainTitle = _resolveComponent("MainTitle");
  const _component_el_switch = _resolveComponent("el-switch");
  const _component_CenterTop = _resolveComponent("CenterTop");
  return _openBlock(), _createBlock(_component_CenterTop, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_MainTitle, null, {
      title: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('speSettingsUpdate.title').toString()), 1)]),
      _: 1
    }), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.settings, setting => {
      return _openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(setting.key), 1), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_switch, {
        class: "sso-debug-switch",
        type: "is-success",
        "model-value": setting.value,
        onChange: $event => _ctx.changeValueFor(setting)
      }, null, 8, ["model-value", "onChange"])])]);
    }), 256))])])]),
    _: 1
  });
}