import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue } from 'vue-class-component';
import { Prop, Watch, Emit } from 'vue-property-decorator';
export default class ErrorDialog extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "visible", void 0);
    _defineProperty(this, "title", void 0);
    _defineProperty(this, "width", void 0);
    _defineProperty(this, "closeOnOutsideClick", void 0);
    _defineProperty(this, "showClose", void 0);
    _defineProperty(this, "isVisible", false);
  }
  mounted() {
    this.isVisible = this.visible;
  }
  onVisiblePropChanges() {
    this.isVisible = this.visible;
  }
  handleDialogClose() {
    this.isVisible = false;
    return true;
  }
}
__decorate([Prop({
  default: false
})], ErrorDialog.prototype, "visible", void 0);
__decorate([Prop({
  default: ''
})], ErrorDialog.prototype, "title", void 0);
__decorate([Prop({
  default: '560px'
})], ErrorDialog.prototype, "width", void 0);
__decorate([Prop({
  default: true
})], ErrorDialog.prototype, "closeOnOutsideClick", void 0);
__decorate([Prop({
  default: true
})], ErrorDialog.prototype, "showClose", void 0);
__decorate([Watch('visible')], ErrorDialog.prototype, "onVisiblePropChanges", null);
__decorate([Emit("dialog-close")], ErrorDialog.prototype, "handleDialogClose", null);