import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7391c29c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "result"
};
const _hoisted_2 = {
  class: "message-button"
};
const _hoisted_3 = {
  class: "message-progress"
};
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_i18n_t = _resolveComponent("i18n-t");
  const _component_el_progress = _resolveComponent("el-progress");
  const _component_Banner = _resolveComponent("Banner");
  const _component_SpeachResultTable = _resolveComponent("SpeachResultTable");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_ctx.loading ? (_openBlock(), _createBlock(_component_Banner, {
    key: 0,
    "min-height": "50px",
    "custom-icon": "clock-outline",
    class: "copy-speach-result-alerts"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, [_createVNode(_component_i18n_t, {
      scope: "global",
      keypath: "copy_speaches.speaches_result_process.copying_status_message",
      tag: "div"
    }, {
      sourceNbSpeaches: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.listSpeachToCopy.length), 1)]),
      sourcePlatformName: _withCtx(() => [_createElementVNode("b", null, _toDisplayString(_ctx.sourcePlatform?.name), 1)]),
      destPlatformName: _withCtx(() => [_createElementVNode("b", null, _toDisplayString(_ctx.destinationPlatform?.name), 1)]),
      nbCopiedSpeaches: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.speachCopied), 1)]),
      totalSpeachesToCopy: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.listSpeachToCopy.length), 1)]),
      _: 1
    })]), _createVNode(_component_el_progress, {
      class: "progress-bar",
      "stroke-width": 8,
      percentage: Math.floor(_ctx.speachCopied / _ctx.listSpeachToCopy.length * 100)
    }, null, 8, ["percentage"])])]),
    _: 1
  })) : (_openBlock(), _createElementBlock("div", _hoisted_4, [_ctx.totalSpeachCopiedSuccess > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_Banner, {
    type: "success",
    "min-height": "50px",
    class: "copy-speach-result-alerts"
  }, {
    default: _withCtx(() => [_createVNode(_component_i18n_t, {
      scope: "global",
      keypath: "copy_speaches.speaches_result_process.copy_success_message",
      tag: "div"
    }, {
      nbSuccessfullyCopied: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.totalSpeachCopiedSuccess), 1)]),
      sourcePlatformName: _withCtx(() => [_createElementVNode("b", null, _toDisplayString(_ctx.sourcePlatform?.name), 1)]),
      destPlatformName: _withCtx(() => [_createElementVNode("b", null, _toDisplayString(_ctx.destinationPlatform?.name), 1)]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_Banner, {
    type: "warning",
    "min-height": "50px",
    class: "copy-speach-result-alerts"
  }, {
    default: _withCtx(() => [_createVNode(_component_i18n_t, {
      scope: "global",
      keypath: "copy_speaches.speaches_result_process.copy_warning_message",
      tag: "div"
    }, {
      destPlatformName: _withCtx(() => [_createElementVNode("b", null, _toDisplayString(_ctx.destinationPlatform?.name), 1)]),
      _: 1
    })]),
    _: 1
  })])) : _createCommentVNode("", true), _ctx.totalSpeachCopiedError > 0 ? (_openBlock(), _createBlock(_component_Banner, {
    key: 1,
    type: "error",
    "min-height": "50px",
    class: "copy-speach-result-alerts"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('copy_speaches.speaches_result_process.copy_error_message', {
      nbSpeachesInError: _ctx.totalSpeachCopiedError
    }).toString()), 1)]),
    _: 1
  })) : _createCommentVNode("", true)]))]), _createVNode(_component_SpeachResultTable, {
    "list-speach": _ctx.listSpeachToCopy
  }, null, 8, ["list-speach"])]);
}