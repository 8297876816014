import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import Card from '@/components/layout/Card.vue';
import store from '@/store';
import UserItem from '@/components/users/UserItem.vue';
import UserRightManagementSummary from '@/components/users/UserRightManagementSummary.vue';
import UserRightManagement from '@/components/users/UserRightManagement.vue';
import { AUTH_ITEM_DELETE, AUTH_ITEM_LIST } from '@/store/actions/authItem';
import { AuthItemTypeConfiguration } from '@/store/enums/authItemEnum';
import AuthItemDeleteDialog from '../dialog/AuthItemDeleteDialog.vue';
let AuthItemsTable = class AuthItemsTable extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "errorMsg", '');
    _defineProperty(this, "loading", false);
    _defineProperty(this, "toggleAuthItemDeleteDialog", false);
    _defineProperty(this, "authItemToDelete", null);
  }
  get authItems() {
    return Object.values(store.getters.authItems);
  }
  mounted() {
    this.loadAuthItem();
  }
  get isDeleteAuthItem() {
    return store.getters.isAuthItemDeleteAivailable;
  }
  get isUpdateAuthItem() {
    return store.getters.isAuthItemUpdateAivailable;
  }
  isAuthenticationValid(authItem) {
    const value = this.getConfFor(AuthItemTypeConfiguration.SAML_AUTHENTICATION_VALID, authItem) === 'true';
    return value;
  }
  getConfFor(type, authItem) {
    for (const authConf of authItem.authItemConfigurations) {
      if (authConf.type === type) {
        return authConf.value;
      }
    }
  }
  loadAuthItem() {
    // we load the list of cluster
    this.loading = true;
    store.dispatch(AUTH_ITEM_LIST).then(resp => {
      this.loading = false;
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
  deleteAuthItem(authItem) {
    this.toggleAuthItemDeleteDialog = true;
    this.authItemToDelete = authItem;
  }
  confirmAuthItemDelete() {
    this.toggleAuthItemDeleteDialog = false;
    this.loading = true;
    store.dispatch(AUTH_ITEM_DELETE, this.authItemToDelete.authItemID).then(() => {
      this.loading = false;
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
};
AuthItemsTable = __decorate([Options({
  components: {
    UserRightManagement,
    UserRightManagementSummary,
    UserItem,
    Card,
    AuthItemDeleteDialog
  }
})], AuthItemsTable);
export default AuthItemsTable;