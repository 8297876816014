import {BACK_ENDPOINT} from '@/store/actions/api';
import {RIGHT_MANAGMENT_CHANGE_FOR_GROUPE, RIGHT_MANAGMENT_CHANGE_FOR_KEY} from '@/store/actions/rightManagement';
import axios from 'axios';
import {RightManagementGroupParam, RightManagementKeyParam} from '@/store/models/rightManagement';
import {User} from '@/store/models/user';
import {RightManagementKey} from '@/store/enums/rightManagementEnum';
import {UPDATE_RIGHTMANGEMENT_GROUP_USER_CONNECTE, UPDATE_RIGHTMANGEMENT_USER_CONNECTE} from '@/store/actions/auth';

const rightManagementAPI = BACK_ENDPOINT + '/rightManagement'; // +userID
const rightManagementGroupAPI = rightManagementAPI + '/group'; // +userID
const rightManagementKeyAPI = rightManagementAPI + '/key'; // +userID

export default {
    state: {},
    mutations: {
        [RIGHT_MANAGMENT_CHANGE_FOR_GROUPE]: (state: any, param: any) => {
            const user = param.user;
            for (const rm of param.rightManagement) {
                // we search the good key in the user
                for (const rmUser of user.rightManagement) {
                    if (rm.key === rmUser.key) {
                        rmUser.value = rm.value;
                    }
                }
            }
        },
        [RIGHT_MANAGMENT_CHANGE_FOR_KEY]: (state: any, param: any) => {
            const user: User = param.user;
            for (const rmUser of user.rightManagement) {
                if (param.rightManagement.key === rmUser.key) {
                    rmUser.value = param.rightManagement.value;
                }
            }
        },
    },
    getters: {
        isUserAvailableTo: (state: any, rootGetters: any) => (key: RightManagementKey): boolean => {
            const user: User = rootGetters.userConnected;
            for (const rm of new Set(user.rightManagement)) {

                if (rm.key === key) {
                    return rm.value==="true";
                }
            }
            return false;
        },
    },
    actions: {
        async [RIGHT_MANAGMENT_CHANGE_FOR_GROUPE]({commit, dispatch}: { commit: any, dispatch: any },
                                                  params: RightManagementGroupParam) {
            const response = await axios({
                url: rightManagementGroupAPI + '/' + params.user.userID,
                data: {key: params.key, value: params.value},
                method: 'POST',
            }).then((resp) => {
                    commit(RIGHT_MANAGMENT_CHANGE_FOR_GROUPE, {user: params.user, rightManagement: resp.data});
                commit(UPDATE_RIGHTMANGEMENT_GROUP_USER_CONNECTE, {user: params.user, rightManagement: resp.data});
                },
            ).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });

        },
        async [RIGHT_MANAGMENT_CHANGE_FOR_KEY]({commit}: { commit: any },
                                               params: RightManagementKeyParam) {
            const response = await axios({
                url: rightManagementKeyAPI + '/' + params.user.userID,
                data: {key: params.key, value: params.value},
                method: 'POST',
            }).then((resp) => {
                    commit(RIGHT_MANAGMENT_CHANGE_FOR_KEY, {user: params.user, rightManagement: resp.data});
                    commit(UPDATE_RIGHTMANGEMENT_USER_CONNECTE, {user: params.user, rightManagement: resp.data});
                },
            ).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
    },
};
