import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a4f51140"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dialog-body"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Banner = _resolveComponent("Banner");
  const _component_w_text_input = _resolveComponent("w-text-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_w_select = _resolveComponent("w-select");
  const _component_el_form = _resolveComponent("el-form");
  const _component_w_standard_button = _resolveComponent("w-standard-button");
  const _component_w_dialog = _resolveComponent("w-dialog");
  return _openBlock(), _createBlock(_component_w_dialog, {
    visible: _ctx.visible,
    title: _ctx.$t('authentications_management.auth_item.creation_modal.title').toString(),
    onDialogClose: _cache[1] || (_cache[1] = $event => _ctx.onDialogClose())
  }, {
    footer: _withCtx(() => [_createVNode(_component_w_standard_button, {
      type: "primary",
      minWidth: "104px",
      disabled: !_ctx.authItemCreationForm.name || !_ctx.authItemCreationForm.type,
      "is-loading": _ctx.loading,
      onClick: _cache[0] || (_cache[0] = $event => _ctx.createAuthentication())
    }, {
      default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$t('authentications_management.auth_item.creation_modal.submit_btn_label').toString()), 1)]),
      _: 1
    }, 8, ["disabled", "is-loading"])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
      key: 0,
      type: "error"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)]),
      _: 1
    })) : _createCommentVNode("", true), _createVNode(_component_el_form, {
      model: _ctx.authItemCreationForm,
      class: "auth-item-creation-form"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        class: "auth-item-name-block",
        prop: "name",
        rules: [{
          required: true,
          trigger: 'blur',
          message: _ctx.$t('authentications_management.auth_item.creation_modal.name_required_msg')
        }]
      }, {
        default: _withCtx(() => [_createVNode(_component_w_text_input, {
          "field-label": _ctx.$t('authentications_management.auth_item.creation_modal.name_field_label').toString(),
          placeholder: _ctx.$t('authentications_management.auth_item.creation_modal.name_field_placeholder').toString(),
          size: "large",
          width: "512px",
          value: _ctx.authItemCreationForm.name,
          onInputChanged: _ctx.updateAuthItemName
        }, null, 8, ["field-label", "placeholder", "value", "onInputChanged"])]),
        _: 1
      }, 8, ["rules"]), _createVNode(_component_el_form_item, {
        prop: "type",
        class: "auth-item-creation-form__type-field"
      }, {
        default: _withCtx(() => [_createVNode(_component_w_select, {
          "field-label": _ctx.$t('authentications_management.auth_item.creation_modal.type_field_label').toString(),
          size: "large",
          width: "512px",
          name: "authType",
          options: _ctx.authTypeOptions,
          value: _ctx.authItemCreationForm.type,
          onChange: _ctx.updateAuthItemType
        }, null, 8, ["field-label", "options", "value", "onChange"])]),
        _: 1
      })]),
      _: 1
    }, 8, ["model"])])]),
    _: 1
  }, 8, ["visible", "title"]);
}