import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
export default class WTooltip extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "content", void 0);
    _defineProperty(this, "placement", void 0);
    _defineProperty(this, "effect", void 0);
  }
}
__decorate([Prop({
  default: ""
})], WTooltip.prototype, "content", void 0);
__decorate([Prop({
  default: "bottom"
})], WTooltip.prototype, "placement", void 0);
__decorate([Prop({
  default: "dark"
})], WTooltip.prototype, "effect", void 0);