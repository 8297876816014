import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-77f4b76a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "search-bar-block"
};
const _hoisted_2 = {
  class: "search-bar"
};
const _hoisted_3 = ["placeholder", "disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_icon = _resolveComponent("w-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, [_createVNode(_component_w_icon, {
    icon: "magnify",
    class: "search-bar__icon"
  }), _withDirectives(_createElementVNode("input", {
    class: "search-bar__input",
    type: "search",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.currentValue = $event),
    style: _normalizeStyle(`height:${_ctx.height}; width:${_ctx.width};`),
    placeholder: _ctx.placeholder,
    onInput: _cache[1] || (_cache[1] =
    //@ts-ignore
    (...args) => _ctx.onInputUpdate && _ctx.onInputUpdate(...args)),
    disabled: _ctx.disabled
  }, null, 44, _hoisted_3), [[_vModelText, _ctx.currentValue]])])]);
}