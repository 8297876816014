import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import { __decorate } from "tslib";
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { RightManagementKey, RightManagementKeyGroup } from '@/store/enums/rightManagementEnum';
import store from '@/store';
import { RIGHT_MANAGMENT_CHANGE_FOR_GROUPE } from '@/store/actions/rightManagement';
import { RightManagementGroupParam } from '@/store/models/rightManagement';
export default class UserRightManagementSummary extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "user", void 0);
    _defineProperty(this, "loading", false);
  }
  changeValueForGroup(keyGroup) {
    const value = this.getValueFor(keyGroup);
    let valString = 'false';
    if (value > 0) {
      // throw desactivate ALL
      valString = 'false';
    }
    if (value === 0) {
      // throw activate ALL
      valString = 'true';
    }
    const params = new RightManagementGroupParam();
    params.user = this.user;
    params.key = keyGroup;
    params.value = valString;
    this.loading = true;
    store.dispatch(RIGHT_MANAGMENT_CHANGE_FOR_GROUPE, params).then(resp => {
      this.loading = false;
    }).catch(err => {
      this.loading = false;
    });
  }
  get isMe() {
    const userConnected = store.getters.userConnected;
    if (this.user.userID === userConnected.userID) {
      return true;
    } else {
      return false;
    }
  }
  get typeForUser() {
    return this.getTypeFor(RightManagementKeyGroup.MANAGE_USER);
  }
  get typeForPlatform() {
    return this.getTypeFor(RightManagementKeyGroup.MANAGE_PLATFORM);
  }
  get typeForAuthItem() {
    return this.getTypeFor(RightManagementKeyGroup.MANAGE_AUTHITEM);
  }
  changeValueForUserGroup() {
    this.changeValueForGroup(RightManagementKeyGroup.MANAGE_USER);
  }
  changeValueForPlatformGroup() {
    this.changeValueForGroup(RightManagementKeyGroup.MANAGE_PLATFORM);
  }
  changeValueForAuthItemGroup() {
    this.changeValueForGroup(RightManagementKeyGroup.MANAGE_AUTHITEM);
  }
  // set the good style/type for a value of a group
  getTypeFor(keyGroup) {
    const value = this.getValueFor(keyGroup);
    if (value === 1) {
      return 'is-success';
    }
    if (value === 0.5) {
      return 'is-warning';
    }
    if (value === 0) {
      return '';
    }
  }
  get isUserUpdateAivalable() {
    return store.getters.isUpdateUserAivailable;
  }
  // can be 0 for duisabled
  // 0.5 partiel
  // 1 for full
  getValueFor(keygroup) {
    return this.getGroupValue()[keygroup];
  }
  mounted() {
    //   this.computeGroupValue();
  }
  // Compute the value of a group of right management
  getGroupValue() {
    const valueOfGroup = {};
    for (const key of Object.values(RightManagementKeyGroup)) {
      let numberOfKeyActive = 0;
      let numberOfKeyDisabled = 0;
      for (const rm of new Set(this.user.rightManagement)) {
        if (Object.values(RightManagementKey).includes(RightManagementKey[rm.key])) {
          if (rm.rightManagementGroup.includes(key)) {
            if (rm.value === "true") {
              numberOfKeyActive++;
            } else {
              numberOfKeyDisabled++;
            }
          }
        }
      }
      if (numberOfKeyActive === 0) {
        valueOfGroup[key] = 0;
      } else if (numberOfKeyDisabled === 0) {
        valueOfGroup[key] = 1;
      } else {
        valueOfGroup[key] = 0.5;
      }
    }
    return valueOfGroup;
  }
}
__decorate([Prop()], UserRightManagementSummary.prototype, "user", void 0);