import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import CenterCenter from '@/components/layout/CenterCenter.vue';
import MainTitle from '@/components/title/MainTitle.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import slugify from '@sindresorhus/slugify';
import NativeColorPicker from '@/components/color-picker/NativeColorPicker.vue';
import store from '@/store';
import { CLUSTER_LIST, PLATFORM_CREATE } from '@/store/actions/platforms';
import { PlatformCreateParams } from '@/store/models/platform';
import { CLUSTER_DOMAIN } from '@/store/actions/api';
import WLabel from "@/components/wrapper/w-label.vue";
let CreatePlatform = class CreatePlatform extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "togglePlatformCreationDialog", void 0);
    _defineProperty(this, "visible", false);
    _defineProperty(this, "loading", false);
    _defineProperty(this, "errorMsg", '');
    // form value
    _defineProperty(this, "name", '');
    _defineProperty(this, "url", '');
    _defineProperty(this, "title", '');
    _defineProperty(this, "color", '');
    _defineProperty(this, "logoDataUrl", '');
    _defineProperty(this, "loadData", false);
  }
  mounted() {
    // we load the list of cluster
    this.loading = true;
    store.dispatch(CLUSTER_LIST).then(resp => {
      this.loading = false;
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
  autoFillUrl(value) {
    this.name = value;
    if (this.name !== '') {
      const enhancedName = slugify(this.name);
      this.url = `https://${enhancedName}.${CLUSTER_DOMAIN}`;
    } else {
      this.url = '';
    }
  }
  updateTitle(value) {
    this.title = value;
  }
  slugifyPlatformName() {
    this.name = slugify(this.name);
  }
  colorSelection(value) {
    this.color = value;
  }
  onFileSelected(fileData) {
    const file = fileData.raw;
    this.loadData = true;
    const reader = new FileReader();
    reader.onloadend = this.loadLogo;
    reader.readAsDataURL(file);
  }
  loadLogo(e) {
    this.logoDataUrl = e.target.result;
    this.loadData = false;
  }
  clusters() {
    return store.getters.clusters;
  }
  canBeSubmitted() {
    return this.name?.trim() && this.logoDataUrl?.trim() && this.color?.trim();
  }
  createPlatform(clusterID) {
    this.errorMsg = '';
    this.loading = true;
    if (clusterID === undefined) {
      if (this.clusters().length > 0) {
        clusterID = this.clusters()[0];
      } else {
        this.loading = false;
        this.errorMsg = 'no cluster aivalable';
        return;
      }
    }
    const params = new PlatformCreateParams(this.name, this.url, this.logoDataUrl, clusterID, this.color, this.title);
    store.dispatch(PLATFORM_CREATE, params).then(resp => {
      this.onDialogClose();
      this.loading = false;
      this.errorMsg = '';
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
  onDialogClose() {
    this.visible = false;
    this.$emit("dialogClose");
  }
};
__decorate([Prop({
  default: false
})], CreatePlatform.prototype, "togglePlatformCreationDialog", void 0);
CreatePlatform = __decorate([Options({
  components: {
    WLabel,
    NativeColorPicker,
    CenterCenter,
    MainTitle
  },
  data() {
    return {
      CLUSTER_DOMAIN
    };
  },
  watch: {
    togglePlatformCreationDialog() {
      this.visible = this.togglePlatformCreationDialog;
    }
  }
})], CreatePlatform);
export default CreatePlatform;