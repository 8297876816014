import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Card from '@/components/layout/Card.vue';
import { RightManagementKey, RightManagementKeyGroup } from '@/store/enums/rightManagementEnum';
import { RightManagementKeyParam } from '@/store/models/rightManagement';
import store from '@/store';
import { RIGHT_MANAGMENT_CHANGE_FOR_KEY } from '@/store/actions/rightManagement';
let UserRightManagement = class UserRightManagement extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "user", void 0);
  }
  getValueForKey(key) {
    for (const rm of new Set(this.user.rightManagement)) {
      if (rm.key === key) {
        return rm.value === "true";
      }
    }
  }
  get isUserUpdateAivalable() {
    return store.getters.isUpdateUserAivailable;
  }
  get isMe() {
    const userConnected = store.getters.userConnected;
    if (this.user.userID === userConnected.userID) {
      return true;
    } else {
      return false;
    }
  }
  changeValueFor(key) {
    const value = this.getValueForKey(key);
    const params = new RightManagementKeyParam();
    params.user = this.user;
    params.key = key;
    params.value = !value + '';
    store.dispatch(RIGHT_MANAGMENT_CHANGE_FOR_KEY, params);
  }
  // set the good style/type for a value of a group
  getTypeFor(keyGroup) {
    const value = this.getGroupValue(keyGroup);
    if (value.total === value.active) {
      return 'is-success';
    }
    if (value.active === 0) {
      return 'is-light';
    }
    if (value.active > 0) {
      return 'is-warning';
    }
  }
  // Compute the value of a group of right management
  getGroupValue(key) {
    let numberOfKeyActive = 0;
    let totalOfKey = 0;
    for (const rm of new Set(this.user.rightManagement)) {
      // we verify that the RM is in enumerer
      if (rm.rightManagementGroup.includes(key) && Object.values(RightManagementKey).includes(RightManagementKey[rm.key])) {
        if (rm.value === "true") {
          numberOfKeyActive++;
        }
        totalOfKey++;
      }
    }
    return {
      active: numberOfKeyActive,
      total: totalOfKey
    };
  }
  get rightManagementKey() {
    return RightManagementKey;
  }
  get rightManagementGroupKey() {
    return RightManagementKeyGroup;
  }
};
__decorate([Prop()], UserRightManagement.prototype, "user", void 0);
UserRightManagement = __decorate([Options({
  components: {
    Card
  }
})], UserRightManagement);
export default UserRightManagement;