export const PLATFORM_LIST = 'PLATFORM_LIST';
export const PLATFORM_CREATE = 'PLATFORM_CREATE';
export const PLATFORM_DELETE = 'PLATFORM_DELETE';
export const PLATFORM_REFRESH = 'PLATFORM_REFRESH';
export const PLATFORM_SUMMARY = 'PLATFORM_SUMMARY';
export const ENROLL_PLATFORM = 'ENROLL_PLATFORM';
export const CLUSTER_LIST = 'CLUSTER_LIST';
export const SETTINGS_LIST = 'SETTINGS_LIST';
export const SETTING_CHANGE = 'SETTING_CHANGE';
export const SLIDEDECK_BACKGROUND = 'SLIDEDECK_BACKGROUND';
export const SET_SLIDEDECK_BACKGROUND = 'SET_SLIDEDECK_BACKGROUND';
export const DELETE_SLIDEDECK_BACKGROUND = 'DELETE_SLIDEDECK_BACKGROUND';
export const RESET_STORE_PLATFORM = 'RESET_STORE_PLATFORM';

export const SEARCH_PLATFORMS = 'SEARCH_PLATFORMS';

export const SEARCH_PLATFORMS_APPEND = 'SEARCH_PLATFORMS_APPEND';

export const SEARCH_COPY_SPEACH_SOURCE_PLATFORMS = 'COPY_SPEACH_SOURCE_PLATFORMS';

export const SEARCH_COPY_SPEACH_DEST_PLATFORMS = 'COPY_SPEACH_DEST_PLATFORMS';

export const COPY_SPEACH_SOURCE_PLATFORM_SEARCH_FILTER = 'COPY_SPEACH_SOURCE_PLATFORM_SEARCH_FILTER';

export const COPY_SPEACH_DEST_PLATFORM_SEARCH_FILTER = 'COPY_SPEACH_DEST_PLATFORM_SEARCH_FILTER';