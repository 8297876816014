// PUBLIC
export const AUTH_ITEM_PUBLIC_LIST = 'AUTH_ITEM_PUBLIC_LIST';

// PRIVATE
export const AUTH_ITEM_LIST = 'AUTH_ITEM_LIST';
export const AUTH_ITEM_ADD = 'AUTH_ITEM_ADD';
export const AUTH_ITEM_GET = 'AUTH_ITEM_GET';
export const AUTH_ITEM_DELETE = 'AUTH_ITEM_DELETE';
export const AUTH_ITEM_SAML_METADATA = 'AUTH_ITEM_SAML_METADATA';
export const AUTH_ITEM_SAML_GET_AUTH_REQUEST = 'AUTH_ITEM_SAML_GET_AUTH_REQUEST';
export const AUTH_ITEM_CHANGE_CONFIGURATION = 'AUTH_ITEM_CHANGE_CONFIGURATION';
export const AUTH_ITEM_CHANGE_ATTRIBUTE = 'AUTH_ITEM_CHANGE_ATTRIBUTE';
