import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-efa6d0b2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "color-picker"
};
const _hoisted_2 = {
  class: "color-picker__custom"
};
const _hoisted_3 = {
  class: "color-picker__predifined"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_text_input = _resolveComponent("w-text-input");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", {
    class: _normalizeClass(`picker-trigger-wrapper ${!_ctx.selectedColor ? 'no-color-picked' : ''}`),
    style: _normalizeStyle(`background-color:${_ctx.selectedColor}`)
  }, [_withDirectives(_createElementVNode("input", {
    type: "color",
    class: "picker-trigger",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.selectedColor = $event),
    onInput: _cache[1] || (_cache[1] =
    //@ts-ignore
    (...args) => _ctx.onColorInputChanges && _ctx.onColorInputChanges(...args)),
    onBlur: _cache[2] || (_cache[2] =
    //@ts-ignore
    (...args) => _ctx.onBlur && _ctx.onBlur(...args))
  }, null, 544), [[_vModelText, _ctx.selectedColor]])], 6), _createVNode(_component_w_text_input, {
    placeholder: "#000000",
    value: _ctx.selectedColor,
    width: _ctx.width,
    onInputChanged: _ctx.updateSelectedColor,
    onBlur: _ctx.onBlur
  }, null, 8, ["value", "width", "onInputChanged", "onBlur"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
    class: "colorSelectionPointer orange",
    onClick: _cache[3] || (_cache[3] = $event => _ctx.selectAColor('#F8550F'))
  }), _createElementVNode("div", {
    class: "colorSelectionPointer blue",
    onClick: _cache[4] || (_cache[4] = $event => _ctx.selectAColor('#0096ff'))
  }), _createElementVNode("div", {
    class: "colorSelectionPointer green",
    onClick: _cache[5] || (_cache[5] = $event => _ctx.selectAColor('#A2F896'))
  }), _createElementVNode("div", {
    class: "colorSelectionPointer violet",
    onClick: _cache[6] || (_cache[6] = $event => _ctx.selectAColor('#663cf2'))
  }), _createElementVNode("div", {
    class: "colorSelectionPointer blueDark",
    onClick: _cache[7] || (_cache[7] = $event => _ctx.selectAColor('#0051ff'))
  })])]);
}