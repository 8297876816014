import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5fcaddfa"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "topline"
};
const _hoisted_2 = {
  class: "cellLogo"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "cellLabel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CardInteractive = _resolveComponent("CardInteractive");
  return _openBlock(), _createBlock(_component_CardInteractive, {
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [_createElementVNode("div", {
      class: _normalizeClass(["platContainer", _ctx.selected ? 'selected' : ''])
    }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
      src: _ctx.platform.logo
    }, null, 8, _hoisted_3)])]), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.platform.name), 1)], 2)]),
    _: 1
  }, 8, ["disabled"]);
}