import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import CardInteractive from '@/components/layout/CardInteractive.vue';
let PlatformCardSelectable = class PlatformCardSelectable extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "platform", void 0);
    _defineProperty(this, "selected", void 0);
    _defineProperty(this, "disabled", void 0);
  }
};
__decorate([Prop()], PlatformCardSelectable.prototype, "platform", void 0);
__decorate([Prop()], PlatformCardSelectable.prototype, "selected", void 0);
__decorate([Prop()], PlatformCardSelectable.prototype, "disabled", void 0);
PlatformCardSelectable = __decorate([Options({
  components: {
    CardInteractive
  }
})], PlatformCardSelectable);
export default PlatformCardSelectable;