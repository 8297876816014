import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
export default class CenterCenter extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "logo", void 0);
  }
  get backgroundStyle() {
    if (this.logo !== undefined) {
      return `background-image:url(${this.logo})`;
    } else {
      return '';
    }
  }
}
__decorate([Prop()], CenterCenter.prototype, "logo", void 0);