import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d9456f88"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "account-creation-notification-dialog__url-field"
};
const _hoisted_2 = {
  class: "account-creation-notification-dialog__warning"
};
const _hoisted_3 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_i18n_t = _resolveComponent("i18n-t");
  const _component_w_text_input = _resolveComponent("w-text-input");
  const _component_w_standard_button = _resolveComponent("w-standard-button");
  const _component_w_icon = _resolveComponent("w-icon");
  const _component_standard_notification_dialog = _resolveComponent("standard-notification-dialog");
  return _openBlock(), _createBlock(_component_standard_notification_dialog, {
    class: "account-creation-notification-dialog",
    title: _ctx.$t('user_creation_notif_dialog.title', {
      firstName: _ctx.userFirstName
    }),
    "toggle-dialog": _ctx.visible,
    onDialogClose: _cache[0] || (_cache[0] = $event => _ctx.onDialogClose())
  }, {
    footer: _withCtx(() => [_createVNode(_component_w_standard_button, {
      type: "primary",
      "min-width": "70px",
      onClick: _ctx.onDialogClose
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('user_creation_notif_dialog.confirm_button').toString()), 1)]),
      _: 1
    }, 8, ["onClick"])]),
    default: _withCtx(() => [_createVNode(_component_i18n_t, {
      scope: "global",
      keypath: "user_creation_notif_dialog.content.message",
      tag: "div"
    }, {
      first_name: _withCtx(() => [_createElementVNode("b", null, _toDisplayString(_ctx.userFirstName) + ": ", 1)]),
      _: 1
    }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_w_text_input, {
      type: "url",
      size: "large",
      width: "424px",
      value: _ctx.passwordUrl,
      readonly: true,
      class: "field-input"
    }, null, 8, ["value"]), _createVNode(_component_w_standard_button, {
      type: "primary",
      "min-width": "88px",
      class: "url-field-copy-btn",
      onClick: _ctx.copyPasswordUrl
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('user_creation_notif_dialog.content.copy_button').toString()), 1)]),
      _: 1
    }, 8, ["onClick"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_w_icon, {
      icon: "alert",
      class: "warning-icon"
    }), _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('user_creation_notif_dialog.content.warning').toString()), 1)])]),
    _: 1
  }, 8, ["title", "toggle-dialog"]);
}