import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import store from '@/store';
import NumberAnimation from '@/components/numberAnimation/NumberAnimation.vue';
import Card from '@/components/layout/Card.vue';
import CardInteractive from '@/components/layout/CardInteractive.vue';
let SummaryItem = class SummaryItem extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "keyValue", void 0);
    _defineProperty(this, "platform", void 0);
    _defineProperty(this, "label", void 0);
    _defineProperty(this, "zeroClass", void 0);
    _defineProperty(this, "shortcutUrl", void 0);
  }
  get value() {
    const summary = store.getters.summary(this.platform);
    if (summary === undefined) {
      return 0;
    }
    return store.getters.summary(this.platform)[this.keyValue];
  }
  get zeroClassName() {
    if (this.value === 0) {
      return this.zeroClass;
    } else {
      return '';
    }
  }
  openSummaryDetail(url, target) {
    window.open(url, target);
  }
};
__decorate([Prop()], SummaryItem.prototype, "keyValue", void 0);
__decorate([Prop()], SummaryItem.prototype, "platform", void 0);
__decorate([Prop({
  default: ''
})], SummaryItem.prototype, "label", void 0);
__decorate([Prop({
  default: ''
})], SummaryItem.prototype, "zeroClass", void 0);
__decorate([Prop({
  default: ''
})], SummaryItem.prototype, "shortcutUrl", void 0);
SummaryItem = __decorate([Options({
  components: {
    CardInteractive,
    Card,
    NumberAnimation
  }
})], SummaryItem);
export default SummaryItem;