import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-465e1822"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "delete_confirmation_dialog__warning"
};
const _hoisted_2 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_i18n_t = _resolveComponent("i18n-t");
  const _component_w_icon = _resolveComponent("w-icon");
  const _component_w_standard_button = _resolveComponent("w-standard-button");
  const _component_standard_notification_dialog = _resolveComponent("standard-notification-dialog");
  return _openBlock(), _createBlock(_component_standard_notification_dialog, {
    class: "delete_confirmation_dialog",
    title: _ctx.$t('authentication_links_management.auth_link.auth_link_deletion_warning_dialog.title').toString(),
    "toggle-dialog": _ctx.visible,
    onDialogClose: _cache[1] || (_cache[1] = $event => _ctx.onDialogClose())
  }, {
    footer: _withCtx(() => [_createVNode(_component_w_standard_button, {
      type: "danger",
      "min-width": "70px",
      onClick: _cache[0] || (_cache[0] = $event => _ctx.onDeleteConfirm())
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('authentication_links_management.auth_link.auth_link_deletion_warning_dialog.confirm_button').toString()), 1)]),
      _: 1
    })]),
    default: _withCtx(() => [_createVNode(_component_i18n_t, {
      scope: "global",
      keypath: "authentication_links_management.auth_link.auth_link_deletion_warning_dialog.message.value",
      tag: "div"
    }, {
      delete: _withCtx(() => [_createElementVNode("b", null, _toDisplayString(_ctx.$t("authentication_links_management.auth_link.auth_link_deletion_warning_dialog.message.delete").toString()), 1)]),
      _: 1
    }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_w_icon, {
      icon: "alert"
    }), _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('authentication_links_management.auth_link.auth_link_deletion_warning_dialog.danger').toString()), 1)])]),
    _: 1
  }, 8, ["title", "toggle-dialog"]);
}