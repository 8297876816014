import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import CenterCenter from '@/components/layout/CenterCenter.vue';
import MainTitle from '@/components/title/MainTitle.vue';
import store from '@/store';
import { router } from "@/router";
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AUTH_REQUEST, AuthParams } from '@/store/actions/auth';
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
let LoginView = class LoginView extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "login", void 0);
    _defineProperty(this, "password", void 0);
    _defineProperty(this, "errorMsg", '');
    _defineProperty(this, "loading", false);
    _defineProperty(this, "isComponentMounted", false);
    _defineProperty(this, "initializationMode", void 0);
  }
  mounted() {
    this.isComponentMounted = true;
    this.initializationMode = this.$route.path.includes('update') ? 'password_update' : 'default';
  }
  autoLogin() {
    this.errorMsg = '';
    this.loading = true;
    const params = new AuthParams(this.login, this.password, true);
    store.dispatch(AUTH_REQUEST, params).then(() => {
      router.push('/');
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
};
__decorate([Prop()], LoginView.prototype, "login", void 0);
__decorate([Prop()], LoginView.prototype, "password", void 0);
LoginView = __decorate([Options({
  components: {
    MainTitle,
    CenterCenter,
    ConfirmDialog
  }
})], LoginView);
export default LoginView;