import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7072b0b8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "confirm-dialog__msg-block"
};
const _hoisted_2 = {
  class: "confirm-dialog__footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_standard_button = _resolveComponent("w-standard-button");
  const _component_w_dialog = _resolveComponent("w-dialog");
  return _openBlock(), _createBlock(_component_w_dialog, {
    visible: _ctx.isVisible,
    title: _ctx.title,
    width: _ctx.width,
    "close-on-click-modal": _ctx.closeOnOutsideClick,
    "show-close": _ctx.showClose,
    onDialogClose: _cache[0] || (_cache[0] = $event => _ctx.handleDialogClose())
  }, {
    footer: _withCtx(() => [_createElementVNode("span", _hoisted_2, [_createVNode(_component_w_standard_button, {
      onClick: _ctx.handleConfirmAction,
      type: _ctx.isDeleteAction ? 'danger' : 'primary'
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.confirmBtnTitle || _ctx.$t('generic_dialog.confirm.btn_ok').toString()), 1)]),
      _: 1
    }, 8, ["onClick", "type"])])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])]),
    _: 3
  }, 8, ["visible", "title", "width", "close-on-click-modal", "show-close"]);
}