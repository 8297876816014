import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createSlots as _createSlots, withKeys as _withKeys } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_label = _resolveComponent("w-label");
  const _component_w_icon = _resolveComponent("w-icon");
  const _component_el_input = _resolveComponent("el-input");
  return _openBlock(), _createElementBlock("div", {
    class: "input-block",
    onKeyup: _cache[1] || (_cache[1] = _withKeys($event => _ctx.onEnterPressed(), ["enter"]))
  }, [_ctx.fieldLabel ? (_openBlock(), _createBlock(_component_w_label, {
    key: 0
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.fieldLabel), 1)]),
    _: 1
  })) : _createCommentVNode("", true), _createVNode(_component_el_input, {
    class: _normalizeClass(`w-el-input ${_ctx.customClass} ${_ctx.leftPin || _ctx.rightPin ? 'w-el-input-group' : ''}
                       ${_ctx.leftPin ? 'w-el-input-group__prepend' : ''} ${_ctx.rightPin ? 'w-el-input-group__append' : ''}`),
    type: _ctx.type,
    placeholder: _ctx.placeholder,
    modelValue: _ctx.currentValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.currentValue = $event),
    size: _ctx.size,
    style: _normalizeStyle(`width:${_ctx.width};`),
    "show-password": _ctx.showPassword,
    "prefix-icon": _ctx.prefixIcon,
    "suffix-icon": _ctx.suffixIcon,
    onBlur: _ctx.onBlur,
    disabled: _ctx.disabled,
    readonly: _ctx.readonly
  }, _createSlots({
    default: _withCtx(() => [_ctx.leftPin ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_createTextVNode(_toDisplayString(_ctx.leftPin), 1)], 64)) : _createCommentVNode("", true), _ctx.rightPin ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 1
    }, [_createTextVNode(_toDisplayString(_ctx.rightPin), 1)], 64)) : _createCommentVNode("", true)]),
    _: 2
  }, [_ctx.prefixWIcon ? {
    name: "prefix",
    fn: _withCtx(() => [_createVNode(_component_w_icon, {
      icon: _ctx.prefixWIcon,
      size: "16px"
    }, null, 8, ["icon"])]),
    key: "0"
  } : undefined]), 1032, ["class", "type", "placeholder", "modelValue", "size", "style", "show-password", "prefix-icon", "suffix-icon", "onBlur", "disabled", "readonly"])], 32);
}