import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import { GET_SPEACH_FROM_SPEACH_ID, REFRESH_COUR_IN_SPEACH_TO_COPY } from '@/store/actions/speach';
import SpeachConfigureTable from '@/components/platform/copySpeach/SpeachConfigureTable.vue';
let ConfigureSpeach = class ConfigureSpeach extends Vue {
  // -- Computed variables
  get listSpeach() {
    return store.getters.listSpeachToCopy;
  }
  get destinationPlatform() {
    return store.getters.destinationPlatform;
  }
  get sourcePlatform() {
    return store.getters.sourcePlatform;
  }
  // -- CUSTOM FUNCTIONS
  async refreshCourAndAddToList(row) {
    store.dispatch(GET_SPEACH_FROM_SPEACH_ID, {
      platformID: this.sourcePlatform.platformID,
      speachID: row.speachID
    }).then(cour => {
      if (cour) {
        store.dispatch(REFRESH_COUR_IN_SPEACH_TO_COPY, cour);
      }
    });
  }
};
ConfigureSpeach = __decorate([Options({
  components: {
    SpeachConfigureTable
  }
})], ConfigureSpeach);
export default ConfigureSpeach;