import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import CardInteractive from '@/components/layout/CardInteractive.vue';
import store from '@/store';
import NumberAnimation from '@/components/numberAnimation/NumberAnimation.vue';
let PlatformPreview = class PlatformPreview extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "platform", void 0);
    _defineProperty(this, "loading", void 0);
    _defineProperty(this, "errorMsg", void 0);
  }
  get logo() {
    return this.platform.logo;
  }
  get name() {
    return this.platform.name;
  }
  get color() {
    return this.platform.color;
  }
  get nbSpeachPublish() {
    return this.getSummaryDataGivenAttribute('nbSpeachPublish');
  }
  get nbNewSpeachWeek() {
    return this.getSummaryDataGivenAttribute('nbNewSpeachWeek');
  }
  get nbNewSpeachMonth() {
    return this.getSummaryDataGivenAttribute('nbNewSpeachMonth');
  }
  get nbUser() {
    return this.getSummaryDataGivenAttribute('nbUser');
  }
  get nbSpeachMakers() {
    return this.getSummaryDataGivenAttribute('nbSpeachMaker');
  }
  get nbAdmins() {
    return this.getSummaryDataGivenAttribute('nbAdmin');
  }
  getSummaryDataGivenAttribute(attribute) {
    const summary = store.getters.summary(this.platform);
    if (summary === undefined) {
      return 0;
    }
    return summary[attribute];
  }
};
__decorate([Prop()], PlatformPreview.prototype, "platform", void 0);
__decorate([Prop({
  default: false
})], PlatformPreview.prototype, "loading", void 0);
__decorate([Prop({
  default: ''
})], PlatformPreview.prototype, "errorMsg", void 0);
PlatformPreview = __decorate([Options({
  components: {
    NumberAnimation,
    CardInteractive
  }
})], PlatformPreview);
export default PlatformPreview;