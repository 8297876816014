export enum SettingKey {
    PLATFORM_VERSION = 'PLATFORM_VERSION',
    COLOR = 'COLOR',
    LOGO = 'LOGO',
// Features
    COMMENT_ENABLED = 'COMMENT_ENABLED',
    WORKFLOW_ENABLED = 'WORKFLOW_ENABLED',
    TRANSCRIPT_ENABLED = 'TRANSCRIPT_ENABLED',
    USER_SEARCH_ENGINE = 'USER_SEARCH_ENGINE',
    INDEX_ALL_CONTENT = 'INDEX_ALL_CONTENT',
    ACTIVATE_PARTAGE = 'ACTIVATE_PARTAGE',
// DOWNLOAD
    VIDEO_DOWNLOAD_ENABLED = 'VIDEO_DOWNLOAD_ENABLED',
    PDF_DOWNLOAD_ENABLED = 'PDF_DOWNLOAD_ENABLED',

// ADMINISTRATION
    NEW_USER_IS_FORMATEUR = 'NEW_USER_IS_FORMATEUR',

// PLAYER
    AUTO_NEXT_SECTION = 'AUTO_NEXT_SECTION',
    VIDEO_DEFAULT_SD = 'VIDEO_DEFAULT_SD',
    SUPPORT_BUBBLE = 'SUPPORT_BUBBLE',

    SUBTITLE_AUTO_DISPLAY_IN_VIDEO = 'SUBTITLE_AUTO_DISPLAY_IN_VIDEO',

// PLATFORM  VISIBILTY FEATURES
    SEARCH_ENGINE_INDEXATION = 'SEARCH_ENGINE_INDEXATION',
    ALLOW_ANONYMOUS = 'ALLOW_ANONYMOUS',
    ALLOW_ACCOUNT_CREATION = 'ALLOW_ACCOUNT_CREATION',

// Publication features
    WORKFLOW_MANDATORY = 'WORKFLOW_MANDATORY',
    ALLOW_PUBLISH_PUBLICATION = 'ALLOW_PUBLISH_PUBLICATION',

// Copy speach
    COPY_SPEACH_ENABLED = 'COPY_SPEACH_ENABLED',
}
