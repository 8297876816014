import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import MainTitle from '@/components/title/MainTitle.vue';
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AuthItemType } from '@/store/enums/authItemEnum';
import store from '@/store';
import { AUTH_ITEM_ADD } from '@/store/actions/authItem';
import { router } from "@/router";
let AuthItemCreation = class AuthItemCreation extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "toggleAuthItemCreationDialog", void 0);
    _defineProperty(this, "visible", false);
    _defineProperty(this, "loading", false);
    _defineProperty(this, "errorMsg", '');
    _defineProperty(this, "authItemCreationForm", {
      name: '',
      type: AuthItemType.SAML2
    });
    _defineProperty(this, "authTypeOptions", [{
      'label': AuthItemType.SAML2,
      'value': AuthItemType.SAML2
    }]);
  }
  updateAuthItemName(value) {
    this.authItemCreationForm.name = value?.trim();
  }
  updateAuthItemType(value) {
    this.authItemCreationForm.type = value;
  }
  createAuthentication() {
    // we load the list of cluster
    this.loading = true;
    store.dispatch(AUTH_ITEM_ADD, {
      name: this.authItemCreationForm.name,
      type: this.authItemCreationForm.type
    }).then(resp => {
      const authItem = resp;
      if (authItem.type === AuthItemType.SAML2) {
        router.push({
          name: 'settingsSaml2',
          params: {
            authItemID: authItem.authItemID,
            name: authItem.name
          }
        });
      }
      this.loading = false;
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
  onDialogClose() {
    this.visible = false;
    this.$emit("dialogClose");
  }
};
__decorate([Prop({
  default: false
})], AuthItemCreation.prototype, "toggleAuthItemCreationDialog", void 0);
AuthItemCreation = __decorate([Options({
  components: {
    MainTitle
  },
  watch: {
    toggleAuthItemCreationDialog() {
      this.visible = this.toggleAuthItemCreationDialog;
    }
  }
})], AuthItemCreation);
export default AuthItemCreation;