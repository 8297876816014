import {Categorie} from "@/store/models/cour";

export class CopySpeachSearchFilters {
    title: string;
    author: string;
    category: Categorie;

    constructor(title: string, author: string, category: Categorie) {
        this.title = title;
        this.author = author;
        this.category = category;
    }
}
