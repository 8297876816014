import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-29135af0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "rightManagementContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_icon_button = _resolveComponent("w-icon-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_w_icon_button, {
    iconClass: "home-group",
    disabled: !_ctx.isUserUpdateAivalable,
    loading: this.loading,
    class: _normalizeClass(`buttonRole ${_ctx.typeForPlatform}`),
    onClick: _ctx.changeValueForPlatformGroup
  }, null, 8, ["disabled", "loading", "class", "onClick"]), _createVNode(_component_w_icon_button, {
    iconClass: "account-group-outline",
    disabled: !_ctx.isUserUpdateAivalable || _ctx.isMe,
    loading: this.loading,
    class: _normalizeClass(`buttonRole ${_ctx.typeForUser}`),
    onClick: _ctx.changeValueForUserGroup
  }, null, 8, ["disabled", "loading", "class", "onClick"]), _createVNode(_component_w_icon_button, {
    iconClass: "security",
    disabled: !_ctx.isUserUpdateAivalable,
    loading: this.loading,
    class: _normalizeClass(`buttonRole ${_ctx.typeForAuthItem}`),
    onClick: _ctx.changeValueForAuthItemGroup
  }, null, 8, ["disabled", "loading", "class", "onClick"])]);
}