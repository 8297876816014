import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import { USER_DELETE, USER_LIST, USER_UPDATE } from '@/store/actions/user';
import { generateRandomString } from '@/utils/util';
import UserItem from '@/components/users/UserItem.vue';
import UserRightManagement from '@/components/users/UserRightManagement.vue';
import PasswordNotificationDialog from "@/components/dialog/PasswordNotificationDialog.vue";
import StandardNotificationDialog from "@/components/dialog/StandardNotificationDialog.vue";
import UserAccountDeleteDialog from "@/components/dialog/UserAccountDeleteDialog.vue";
let UsersTable = class UsersTable extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "toggleUserPwdResetDialog", false);
    _defineProperty(this, "toggleAccountDeleteDialog", false);
    _defineProperty(this, "errorMsg", '');
    _defineProperty(this, "loading", false);
    _defineProperty(this, "sidePanelVisible", false);
    _defineProperty(this, "selectedUser", null);
    _defineProperty(this, "passwordRequestedFor", null);
    _defineProperty(this, "ressettedPasswordUrl", null);
    _defineProperty(this, "userToDelete", null);
  }
  get users() {
    return Object.values(store.getters.users);
  }
  mounted() {
    this.loadListUser();
  }
  get isUserDeleteAivailable() {
    return store.getters.isDeleteUserAvailable;
  }
  loadListUser() {
    this.errorMsg = '';
    this.loading = true;
    store.dispatch(USER_LIST).then(() => {
      this.loading = false;
      this.errorMsg = '';
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
  resetPassword(user) {
    this.passwordRequestedFor = user;
    this.ressettedPasswordUrl = null;
    const tmpPassword = generateRandomString(30);
    this.errorMsg = '';
    this.loading = true;
    store.dispatch(USER_UPDATE, {
      user,
      tmpPassword
    }).then(() => {
      this.informPassword(user, tmpPassword);
      this.loading = false;
      this.errorMsg = '';
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
  informPassword(user, tmpPassword) {
    this.ressettedPasswordUrl = document.location.origin + '/update/' + user.login + '/' + tmpPassword;
    this.toggleUserPwdResetDialog = true;
  }
  deleteUser(user) {
    this.userToDelete = user;
    this.toggleAccountDeleteDialog = true;
  }
  confirmAccountDelete() {
    this.toggleAccountDeleteDialog = false;
    this.errorMsg = '';
    this.loading = true;
    store.dispatch(USER_DELETE, this.userToDelete).then(() => {
      this.loading = false;
      this.errorMsg = '';
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
  selectUser(user) {
    this.selectedUser = user;
    this.sidePanelVisible = true;
  }
  closeSidePanel() {
    this.sidePanelVisible = false;
  }
};
UsersTable = __decorate([Options({
  components: {
    UserRightManagement,
    UserItem,
    StandardNotificationDialog,
    PasswordNotificationDialog,
    UserAccountDeleteDialog
  }
})], UsersTable);
export default UsersTable;