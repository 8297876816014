import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-dcd2e0ec"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_icon = _resolveComponent("w-icon");
  const _component_w_standard_button = _resolveComponent("w-standard-button");
  const _component_MainTitle = _resolveComponent("MainTitle");
  const _component_Banner = _resolveComponent("Banner");
  const _component_AuthItemsTable = _resolveComponent("AuthItemsTable");
  const _component_auth_item_creation = _resolveComponent("auth-item-creation");
  const _component_center_top = _resolveComponent("center-top");
  return _openBlock(), _createBlock(_component_center_top, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_MainTitle, null, {
      title: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('authentications_management.overview.title').toString()), 1)]),
      default: _withCtx(() => [_createVNode(_component_w_standard_button, {
        minWidth: "298px",
        disabled: !_ctx.isCreateAuthItem,
        onClick: _cache[0] || (_cache[0] = $event => _ctx.toggleAuthItemCreationDialog = true)
      }, {
        default: _withCtx(() => [_createVNode(_component_w_icon, {
          icon: "plus"
        }), _createTextVNode(" " + _toDisplayString(_ctx.$t('authentications_management.auth_item.create_btn').toString()), 1)]),
        _: 1
      }, 8, ["disabled"])]),
      _: 1
    }), !!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
      key: 0,
      type: "error"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)]),
      _: 1
    })) : _createCommentVNode("", true), _createVNode(_component_AuthItemsTable)]), _createVNode(_component_auth_item_creation, {
      "toggle-auth-item-creation-dialog": _ctx.toggleAuthItemCreationDialog,
      onDialogClose: _cache[1] || (_cache[1] = $event => _ctx.toggleAuthItemCreationDialog = false)
    }, null, 8, ["toggle-auth-item-creation-dialog"])]),
    _: 1
  });
}