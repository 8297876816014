import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
export default class WStandardButton extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "label", void 0);
    _defineProperty(this, "type", void 0);
    _defineProperty(this, "size", void 0);
    _defineProperty(this, "isLoading", void 0);
    _defineProperty(this, "minHeight", void 0);
    _defineProperty(this, "minWidth", void 0);
    _defineProperty(this, "disabled", void 0);
  }
}
__decorate([Prop({
  default: ''
})], WStandardButton.prototype, "label", void 0);
__decorate([Prop({
  default: 'primary'
})], WStandardButton.prototype, "type", void 0);
__decorate([Prop({
  default: 'default'
})], WStandardButton.prototype, "size", void 0);
__decorate([Prop({
  default: false
})], WStandardButton.prototype, "isLoading", void 0);
__decorate([Prop({
  default: '32px'
})], WStandardButton.prototype, "minHeight", void 0);
__decorate([Prop()], WStandardButton.prototype, "minWidth", void 0);
__decorate([Prop({
  default: false
})], WStandardButton.prototype, "disabled", void 0);