import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import store from '@/store';
import Card from '@/components/layout/Card.vue';
import { SlideDeckBackground } from '@/store/models/settings';
import { DELETE_SLIDEDECK_BACKGROUND, SET_SLIDEDECK_BACKGROUND } from '@/store/actions/platforms';
import ColorPickerBoard from '@/components/color-picker/ColorPickerBoard.vue';
let SlideDeckBackgroundItem = class SlideDeckBackgroundItem extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "platform", void 0);
    _defineProperty(this, "slideDeckBackgroud", void 0);
    _defineProperty(this, "color", void 0);
    _defineProperty(this, "position", void 0);
    _defineProperty(this, "txtColor", '#F0F0F0');
    _defineProperty(this, "logoDataUrl", '');
    _defineProperty(this, "loadData", false);
    _defineProperty(this, "errorMsg", '');
    _defineProperty(this, "loading", false);
    _defineProperty(this, "displaySlideckDeleteDialog", false);
  }
  mounted() {
    if (this.slideDeckBackgroud !== undefined) {
      this.txtColor = this.slideDeckBackgroud.color;
      this.logoDataUrl = this.platform.url + this.slideDeckBackgroud.url;
    }
  }
  onFileSelected(fileData) {
    const file = fileData.raw;
    this.loadData = true;
    const reader = new FileReader();
    reader.onloadend = this.loadLogo;
    reader.readAsDataURL(file);
  }
  loadLogo(e) {
    this.logoDataUrl = e.target.result;
    this.saveSlideDeckBackground(this.txtColor);
    this.loadData = false;
  }
  saveSlideDeckBackground(color) {
    this.txtColor = color;
    this.errorMsg = '';
    this.loading = true;
    const slideDeckBG = new SlideDeckBackground(this.logoDataUrl, this.txtColor, this.position);
    store.dispatch(SET_SLIDEDECK_BACKGROUND, {
      platform: this.platform,
      slideckBackground: slideDeckBG
    }).then(() => {
      this.errorMsg = '';
      this.loading = false;
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
  deleteSlideDeckBackground() {
    this.errorMsg = '';
    this.loading = true;
    this.logoDataUrl = '';
    this.txtColor = '#F0F0F0';
    store.dispatch(DELETE_SLIDEDECK_BACKGROUND, {
      platform: this.platform,
      position: this.position
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
};
__decorate([Prop()], SlideDeckBackgroundItem.prototype, "platform", void 0);
__decorate([Prop()], SlideDeckBackgroundItem.prototype, "slideDeckBackgroud", void 0);
__decorate([Prop({
  default: '#F0F0F0'
})], SlideDeckBackgroundItem.prototype, "color", void 0);
__decorate([Prop()], SlideDeckBackgroundItem.prototype, "position", void 0);
SlideDeckBackgroundItem = __decorate([Options({
  components: {
    ColorPickerBoard,
    Card
  }
})], SlideDeckBackgroundItem);
export default SlideDeckBackgroundItem;