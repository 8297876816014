import {CopySpeachStatus} from '@/store/enums/speachEnum';

export class Cour {
    constructor(speachID: string) {
        this.speachID=speachID;
    }
    public title: string;
    public user: UserSpeach;
    public category: Categorie;
    public image: string;
    public description: string;
    public visibility: string;
    public speachID: string;
}
export class CourToCopy{
    constructor(cour: Cour) {
        this.cour=cour;
    }

    public cour:Cour;
    public userCopy:UserSpeach;
    public categoryCopy:Categorie;
    public copySpeachStatus:CopySpeachStatus=CopySpeachStatus.PENDING;
}

export class UserSpeach {
    public email: string;
    public userID: string;
    public lastName: string;
    public firstName: string;
    public description: string;
    public image: string;
}

export class Categorie {
    public name: string;
    public description: string;
    public id: number;
}
