import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import WIcon from "@/components/wrapper/w-icon.vue";
let WTextInput = class WTextInput extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "type", void 0);
    _defineProperty(this, "size", void 0);
    _defineProperty(this, "width", void 0);
    _defineProperty(this, "fieldLabel", void 0);
    _defineProperty(this, "placeholder", void 0);
    _defineProperty(this, "value", void 0);
    _defineProperty(this, "disabled", void 0);
    _defineProperty(this, "leftPin", void 0);
    _defineProperty(this, "rightPin", void 0);
    _defineProperty(this, "showPassword", void 0);
    _defineProperty(this, "customClass", void 0);
    _defineProperty(this, "readonly", void 0);
    _defineProperty(this, "prefixIcon", void 0);
    _defineProperty(this, "prefixWIcon", void 0);
    _defineProperty(this, "suffixIcon", void 0);
    _defineProperty(this, "currentValue", '');
  }
  mounted() {
    this.currentValue = this.value;
  }
  onInputUpdate() {
    if (!this.disabled) {
      this.$emit('inputChanged', this.currentValue);
    }
  }
  onBlur() {
    if (!this.disabled) {
      this.$emit("blur", this.currentValue);
    }
  }
  onEnterPressed() {
    if (!this.disabled) {
      this.$emit("enterPressed", this.currentValue);
    }
  }
};
__decorate([Prop({
  default: 'text'
})], WTextInput.prototype, "type", void 0);
__decorate([Prop({
  default: 'default'
})], WTextInput.prototype, "size", void 0);
__decorate([Prop({
  default: 'auto'
})], WTextInput.prototype, "width", void 0);
__decorate([Prop()], WTextInput.prototype, "fieldLabel", void 0);
__decorate([Prop()], WTextInput.prototype, "placeholder", void 0);
__decorate([Prop()], WTextInput.prototype, "value", void 0);
__decorate([Prop({
  default: false
})], WTextInput.prototype, "disabled", void 0);
__decorate([Prop()], WTextInput.prototype, "leftPin", void 0);
__decorate([Prop()], WTextInput.prototype, "rightPin", void 0);
__decorate([Prop()], WTextInput.prototype, "showPassword", void 0);
__decorate([Prop({
  default: ''
})], WTextInput.prototype, "customClass", void 0);
__decorate([Prop({
  default: false
})], WTextInput.prototype, "readonly", void 0);
__decorate([Prop()], WTextInput.prototype, "prefixIcon", void 0);
__decorate([Prop()], WTextInput.prototype, "prefixWIcon", void 0);
__decorate([Prop()], WTextInput.prototype, "suffixIcon", void 0);
__decorate([Watch('currentValue')], WTextInput.prototype, "onInputUpdate", null);
WTextInput = __decorate([Options({
  components: {
    WIcon
  },
  props: {
    value: {
      type: String
    }
  },
  watch: {
    value() {
      this.currentValue = this.value;
    }
  }
})], WTextInput);
export default WTextInput;