export const CATEGORIES_LIST = 'CATEGORIES_LIST';
export const SET_CATEGORY = 'SET_CATEGORY';
export const AUTHORS_LIST = 'AUTHORS_LIST';
export const APPEND_AUTHORS_LIST = 'APPEND_AUTHORS_LIST';
export const SET_AUTHOR = 'SET_AUTHOR';
export const RESET_AUTHORS_LIST = 'RESET_AUTHORS_LIST';
export const SPEACH_LIST = 'SPEACH_LIST';
export const APPEND_SPEACH_LIST = 'APPEND_SPEACH_LIST';
export const EMPTY_SPEACH_LIST = 'EMPTY_SPEACH_LIST';
export const CHECK_SPEACH = 'CHECK_SPEACH';
export const UNCHECK_SPEACH = 'UNCHECK_SPEACH';
export const REFRESH_CHECKED_SPEACH_TO_SHOW = 'REFRESH_CHECKED_SPEACH_TO_SHOW';
export const RESET_STORE_COPY_SPEACH = 'RESET_STORE_COPY_SPEACH';
export const RESET_CONFIGURE_SPEACH = 'RESET_CONFIGURE_SPEACH';
export const COPY_SPEACH = 'COPY_SPEACH';
export const SOURCE_PLATFORM = 'SOURCE_PLATFORM';
export const DESTINATION_PLATFORM = 'DESTINATION_PLATFORM';
export const SUCCESS_COPY_SPEACH='SUCCESS_COPY_SPEACH';
export const ERROR_COPY_SPEACH='ERROR_COPY_SPEACH';
export const CHECK_ALL_SPEACH_NOT_CHECKED='CHECK_ALL_SPEACH_NOT_CHECKED';
export const UNCHECK_ALL_SPEACH_IN_SEARCH_LIST='UNCHECK_ALL_SPEACH_IN_SEARCH_LIST';
export const SPEACH_IDS_LIST = 'SPEACH_IDS_LIST';
export const CHECK_ALL_SPEACH_FROM_BACK = 'CHECK_ALL_SPEACH_FROM_BACK';
export const UNCHECK_ALL_SPEACH_FROM_BACK = 'UNCHECK_ALL_SPEACH_FROM_BACK';
export const GET_SPEACH_FROM_SPEACH_ID = 'GET_SPEACH_FROM_SPEACH_ID';
export const REFRESH_COUR_IN_CHECKED_LIST = 'REFRESH_COUR_IN_CHECKED_LIST';
export const REFRESH_COUR_IN_SPEACH_TO_COPY = 'REFRESH_COUR_IN_SPEACH_TO_COPY';
export const COPY_SPEACH_SEARCH_FILTERS = 'COPY_SPEACH_SEARCH_FILTERS';
