import {createI18n} from 'vue-i18n'
import {LangsEnum} from './langsEnum';

const USER_LOCALE: string = 'user-locale';

// list of supported locales
const SUPPORTED_LOCALES: string[] = [LangsEnum.EN, LangsEnum.FR, LangsEnum.DE, LangsEnum.ES];

// Default locale
export const DEFAULT_LOCALE: string = LangsEnum.EN;

// lazy loaded locales
const loadedLocales = [];

function getUserPreferredLocale() {
    const locale: string = localStorage.getItem(USER_LOCALE) || window.navigator.language.split('-')[0];
    return SUPPORTED_LOCALES.includes(locale) ? locale : DEFAULT_LOCALE;
}

function setI18nLanguage(newLocale: string) {
    i18n.global.locale = newLocale;
    localStorage.setItem(USER_LOCALE, newLocale);
    return newLocale;
}

export async function switchLanguageAsync(locale: string) {
    // If locale is not supported, we consider to default locale
    if (!SUPPORTED_LOCALES.includes(locale)) {
        locale = DEFAULT_LOCALE;
    }

    if (loadedLocales.length > 0 && i18n.global.locale === locale) {
        return Promise.resolve(locale);
    }
    // If the language was already loaded
    if (loadedLocales.includes(locale)) {
        return Promise.resolve(setI18nLanguage(locale));
    }
    // If the language hasn't been loaded yet
    return import(`@/locales/${locale}.json`).then(messages => {
        i18n.global.setLocaleMessage(locale, messages.default);
        loadedLocales.push(locale);
        return Promise.resolve(setI18nLanguage(locale));
    })
}

const preferredLocale: string = getUserPreferredLocale();

const defaultMessages = {};
defaultMessages[preferredLocale] = require(`@/locales/${preferredLocale}.json`);

const i18n = createI18n({
    locale: preferredLocale,
    fallbackLocale: DEFAULT_LOCALE,
    messages: defaultMessages,
});

export default i18n;