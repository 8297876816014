import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
export default class Card extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "icon", void 0);
    _defineProperty(this, "color", void 0);
    _defineProperty(this, "label", void 0);
  }
  get loadingStyle() {
    return `color: ${this.color};`;
  }
}
__decorate([Prop({
  default: ''
})], Card.prototype, "icon", void 0);
__decorate([Prop({
  default: '#666'
})], Card.prototype, "color", void 0);
__decorate([Prop({
  default: ''
})], Card.prototype, "label", void 0);