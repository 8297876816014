import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { __decorate } from "tslib";
import MainTitle from '@/components/title/MainTitle.vue';
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import CenterTop from '@/components/layout/CenterTop.vue';
import Footer from '@/components/layout/Footer.vue';
import CardInteractive from '@/components/layout/CardInteractive.vue';
import GridLayout from '@/components/layout/GridLayout.vue';
import GridItem from '@/components/layout/GridItem.vue';
import store from '@/store';
import { AUTH_ITEM_CHANGE_CONFIGURATION, AUTH_ITEM_GET, AUTH_ITEM_SAML_GET_AUTH_REQUEST, AUTH_ITEM_SAML_METADATA } from '@/store/actions/authItem';
import Card from '@/components/layout/Card.vue';
import { AuthItemTypeConfiguration } from '@/store/enums/authItemEnum';
import AuthItemsAttributeTable from '@/components/authItem/AuthItemsAttributeTable.vue';
import AuthItemsAttributeMappingTable from '@/components/authItem/AuthItemsAttributeMappingDialog.vue';
import { router } from "@/router";
let SettingsAuthItemSAML2 = class SettingsAuthItemSAML2 extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "breadcrumbItems", []);
    _defineProperty(this, "loading", false);
    _defineProperty(this, "errorMsg", '');
    _defineProperty(this, "isMapAttributeModalActive", false);
    _defineProperty(this, "authItemID", void 0);
    _defineProperty(this, "name", void 0);
  }
  get isIDPUpload() {
    const value = this.getConfFor(AuthItemTypeConfiguration.IDP_METADATA) !== '';
    return value;
  }
  get urlASC() {
    const value = this.getConfFor(AuthItemTypeConfiguration.SP_ASC_URL);
    return value + '/login';
  }
  get spEntityid() {
    const value = this.getConfFor(AuthItemTypeConfiguration.SP_ENTITY_ID_PROPERTYKEY);
    return value;
  }
  get isCreateAuthLink() {
    return store.getters.isAuthLinkCreateAivailable;
  }
  get isAuthenticationValid() {
    const value = this.getConfFor(AuthItemTypeConfiguration.SAML_AUTHENTICATION_VALID) === 'true';
    return value;
  }
  get authItem() {
    return store.getters.authItem(this.authItemID);
  }
  mounted() {
    if (!this.isAuthItem) {
      router.push({
        name: 'HomePage'
      });
    }
    this.buildHeaderBreadcrumb();
    this.loading = true;
    store.dispatch(AUTH_ITEM_GET, this.authItemID).then(resp => {
      this.loading = false;
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
  buildHeaderBreadcrumb() {
    this.breadcrumbItems = [{
      label: 'Manage authentication',
      isRouterLink: true,
      url: '/authItems'
    }, {
      label: `${this.name} - ${this.$t('authentications_management.auth_item.edit.saml2.breadcrumb').toString()}`
    }];
  }
  createAuthRequest() {
    this.loading = true;
    store.dispatch(AUTH_ITEM_SAML_GET_AUTH_REQUEST, this.authItemID).then(resp => {
      this.loading = false;
      document.location = resp;
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
  onFileSelected(fileData) {
    const file = fileData.raw;
    const reader = new FileReader();
    reader.onloadend = this.uploadFile;
    reader.readAsText(file);
  }
  uploadFile(e) {
    this.loading = true;
    store.dispatch(AUTH_ITEM_CHANGE_CONFIGURATION, {
      authItemID: this.authItemID,
      type: AuthItemTypeConfiguration.IDP_METADATA,
      value: e.target.result
    }).then(resp => {
      this.loading = false;
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
  downloadSPMetadata() {
    this.loading = true;
    store.dispatch(AUTH_ITEM_SAML_METADATA, this.authItemID).then(resp => {
      this.loading = false;
      const fileURL = window.URL.createObjectURL(new Blob([resp]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `sp-${this.name}-metadata.xml`);
      document.body.appendChild(fileLink);
      fileLink.click();
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
    this.loading = true;
    store.dispatch(AUTH_ITEM_CHANGE_CONFIGURATION, {
      authItemID: this.authItemID,
      type: AuthItemTypeConfiguration.SP_METADATA_DOWNLOADED,
      value: true
    }).then(() => {
      this.loading = false;
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
  downloadIDPMetadata() {
    const idpMetadata = this.getConfFor(AuthItemTypeConfiguration.IDP_METADATA);
    if (idpMetadata != null) {
      const fileURL = window.URL.createObjectURL(new Blob([idpMetadata]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `idp-${this.name}-metadata.xml`);
      document.body.appendChild(fileLink);
      fileLink.click();
    }
  }
  getConfFor(type) {
    for (const authConf of this.authItem.authItemConfigurations) {
      if (authConf.type === type) {
        return authConf.value;
      }
    }
  }
  get isAuthItem() {
    return store.getters.isAuthItemAvailable;
  }
  copyOnClipboard(value) {
    navigator.clipboard.writeText(value);
  }
  goToAuthLinkCreation() {
    router.push('/authLinks');
  }
};
__decorate([Prop()], SettingsAuthItemSAML2.prototype, "authItemID", void 0);
__decorate([Prop()], SettingsAuthItemSAML2.prototype, "name", void 0);
SettingsAuthItemSAML2 = __decorate([Options({
  components: {
    AuthItemsAttributeMappingTable,
    AuthItemsAttributeTable,
    Card,
    GridItem,
    GridLayout,
    CardInteractive,
    Footer,
    CenterTop,
    MainTitle
  }
})], SettingsAuthItemSAML2);
export default SettingsAuthItemSAML2;