import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
export default class WIconButton extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "btnClass", void 0);
    _defineProperty(this, "iconClass", void 0);
    _defineProperty(this, "icon", void 0);
    _defineProperty(this, "loading", void 0);
    _defineProperty(this, "disabled", void 0);
  }
}
__decorate([Prop({
  default: ''
})], WIconButton.prototype, "btnClass", void 0);
__decorate([Prop({
  default: ''
})], WIconButton.prototype, "iconClass", void 0);
__decorate([Prop({
  default: ''
})], WIconButton.prototype, "icon", void 0);
__decorate([Prop({
  default: false
})], WIconButton.prototype, "loading", void 0);
__decorate([Prop({
  default: false
})], WIconButton.prototype, "disabled", void 0);