import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import store from '@/store';
import GridItem from '@/components/layout/GridItem.vue';
import PlatformCardSelectable from '@/components/platform/PlatformCardSelectable.vue';
import GridSquareLayout from '@/components/layout/GridSquareLayout.vue';
import { COPY_SPEACH_DEST_PLATFORM_SEARCH_FILTER, COPY_SPEACH_SOURCE_PLATFORM_SEARCH_FILTER, SEARCH_COPY_SPEACH_DEST_PLATFORMS, SEARCH_COPY_SPEACH_SOURCE_PLATFORMS, SETTINGS_LIST } from '@/store/actions/platforms';
import WSearchBar from "@/components/wrapper/w-search-bar.vue";
import { PlatformsSortEnum } from "@/store/enums/PlatformsSortEnum";
import { SortOrderEnum } from "@/store/enums/SortOrderEnum";
import ErrorDialog from '@/components/dialog/ErrorDialog.vue';
let ChoosePlatform = class ChoosePlatform extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "selectedPlatform", void 0);
    _defineProperty(this, "excludedPlatform", void 0);
    _defineProperty(this, "isSource", void 0);
    _defineProperty(this, "copySpeachNotEligiblePlatform", null);
    _defineProperty(this, "errorMsg", '');
    _defineProperty(this, "dispatchType", void 0);
    _defineProperty(this, "searchFilterDispatchType", void 0);
  }
  mounted() {
    this.dispatchType = this.isSource ? SEARCH_COPY_SPEACH_SOURCE_PLATFORMS : SEARCH_COPY_SPEACH_DEST_PLATFORMS;
    this.searchFilterDispatchType = this.isSource ? COPY_SPEACH_SOURCE_PLATFORM_SEARCH_FILTER : COPY_SPEACH_DEST_PLATFORM_SEARCH_FILTER;
    if (!Object.values(this.platforms)?.length) {
      this.searchPlatforms();
    }
  }
  get platforms() {
    return this.isSource ? store.getters.copySpeachSourcePlatforms : store.getters.copySpeachDestPlatforms;
  }
  get platformFilter() {
    return this.isSource ? store.getters.copySpeachSourcePlatformSearchFilter : store.getters.copySpeachDestPlatformSearchFilter;
  }
  async onSearchInputUpdate(searchValue) {
    await store.dispatch(this.searchFilterDispatchType, searchValue);
    this.searchPlatforms(searchValue);
  }
  searchPlatforms(searchValue) {
    const searchCriteria = {
      sortBy: PlatformsSortEnum.NAME,
      sortOrder: SortOrderEnum.ASC
    };
    if (searchValue) {
      searchCriteria.platformName = searchValue;
    }
    this.errorMsg = '';
    store.dispatch(this.dispatchType, searchCriteria).then().catch(err => {
      this.errorMsg = err?.toString();
    });
  }
  // -- CUSTOM FUNCTIONS
  togglePlateforme(platform) {
    if (this.excludedPlatform !== platform.platformID) {
      if (this.selectedPlatform?.platformID !== platform.platformID) {
        if (this.settingsPlatform(platform.platformID)) {
          this.checkSettingCopySpeach(platform);
        } else {
          store.dispatch(SETTINGS_LIST, platform).then(() => this.checkSettingCopySpeach(platform));
        }
      } else {
        this.$emit('selected', null);
      }
    }
  }
  checkSettingCopySpeach(platform) {
    if (this.settingsPlatform(platform.platformID).COPY_SPEACH_ENABLED) {
      this.$emit('selected', platform);
    } else {
      this.copySpeachNotEligiblePlatform = platform;
    }
  }
  settingsPlatform(platformID) {
    return store.getters.settings(platformID);
  }
};
__decorate([Prop({
  default: null
})], ChoosePlatform.prototype, "selectedPlatform", void 0);
__decorate([Prop({
  default: ''
})], ChoosePlatform.prototype, "excludedPlatform", void 0);
__decorate([Prop()], ChoosePlatform.prototype, "isSource", void 0);
ChoosePlatform = __decorate([Options({
  components: {
    GridSquareLayout,
    GridItem,
    PlatformCardSelectable,
    WSearchBar,
    AlertDialog: ErrorDialog
  }
})], ChoosePlatform);
export default ChoosePlatform;