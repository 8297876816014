import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import Card from '@/components/layout/Card.vue';
import store from '@/store';
import { router } from "@/router";
class PlatformFull {
  constructor(platform, summary) {
    _defineProperty(this, "platform", void 0);
    _defineProperty(this, "summary", void 0);
    this.platform = platform;
    this.summary = summary;
  }
}
let PlatformsTable = class PlatformsTable extends Vue {
  get platformsFull() {
    // on join each summary in the platorm
    const platforms = Object.values(store.getters.platforms);
    const platformFulls = [];
    for (const i in platforms) {
      if (platforms[i] !== undefined) {
        const platform = platforms[i];
        const summary = store.getters.summary(platform);
        if (summary !== undefined) {
          platformFulls.push(new PlatformFull(platforms[i], summary));
        }
      }
    }
    return platformFulls;
  }
  goToTheLine(line) {
    router.push({
      name: 'platformDetail',
      params: {
        platformID: line.platform.platformID,
        name: line.platform.name
      }
    });
  }
  summary(platform) {
    const summary = store.getters.summary(platform);
    return store.getters.summary(platform);
  }
};
PlatformsTable = __decorate([Options({
  components: {
    Card
  }
})], PlatformsTable);
export default PlatformsTable;