
export enum RightManagementKeyGroup {
    MANAGE_USER= 'MANAGE_USER',
    MANAGE_PLATFORM= 'MANAGE_PLATFORM',
    MANAGE_ENTERPRISE_SUITE= 'MANAGE_ENTERPRISE_SUITE',
    MANAGE_AUTHITEM= 'MANAGE_AUTHITEM',
}


// key
export enum RightManagementKey {
    // USER
    CREATE_USER = 'CREATE_USER',
    MODIFY_USER = 'MODIFY_USER',
    DELETE_USER = 'DELETE_USER',

    // PLATFORM MANAGEMENT
    CONNECT_PLATFORM= 'CONNECT_PLATFORM',
    DISCONNECT_PLATFORM= 'DISCONNECT_PLATFORM',
    CREATE_PLATFORM= 'CREATE_PLATFORM',
    // DELETE_PLATFORM= 'DELETE_PLATFORM',
    SETTING_PLATFORM= 'SETTING_PLATFORM',
    COPY_SPEACH='COPY_SPEACH',

    // AUTHITEM
    CREATE_AUTHITEM = 'CREATE_AUTHITEM',
    DELETE_AUTHITEM = 'DELETE_AUTHITEM',
    UPDATE_AUTHITEM = 'UPDATE_AUTHITEM',
    CREATE_AUTHLINK = 'CREATE_AUTHLINK',
    DELETE_AUTHLINK = 'DELETE_AUTHLINK',
}


