import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue } from 'vue-class-component';
import { Prop } from "vue-property-decorator";
export default class WIcon extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "icon", void 0);
    _defineProperty(this, "size", void 0);
  }
}
__decorate([Prop({
  default: ''
})], WIcon.prototype, "icon", void 0);
__decorate([Prop({
  default: '24px'
})], WIcon.prototype, "size", void 0);