import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-66690212"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main"
};
const _hoisted_2 = {
  class: "unified-statistics-container"
};
const _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MainTitle = _resolveComponent("MainTitle");
  const _component_Banner = _resolveComponent("Banner");
  const _component_CenterTop = _resolveComponent("CenterTop");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createBlock(_component_CenterTop, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_MainTitle, null, {
      title: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('unified_statistics.title').toString()), 1)]),
      _: 1
    }), !!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
      key: 0,
      type: "error"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)]),
      _: 1
    })) : _createCommentVNode("", true), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [_ctx.metabaseDashboardEmbedUrl ? (_openBlock(), _createElementBlock("iframe", {
      key: 0,
      title: "metabase-statistics-dashboard",
      src: _ctx.metabaseDashboardEmbedUrl,
      width: "100%",
      height: "100%",
      class: "unified-statistics-container__iframe"
    }, null, 8, _hoisted_3)) : _createCommentVNode("", true)])), [[_directive_loading, !_ctx.metabaseDashboardEmbedUrl?.length]])])]),
    _: 1
  });
}