import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5756c45e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MainTitle = _resolveComponent("MainTitle");
  const _component_Banner = _resolveComponent("Banner");
  const _component_AuthItemPreview = _resolveComponent("AuthItemPreview");
  const _component_GridItem = _resolveComponent("GridItem");
  const _component_GridLayout = _resolveComponent("GridLayout");
  const _component_CenterTop = _resolveComponent("CenterTop");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createBlock(_component_CenterTop, {
    logo: require('@/assets/theme/connected.png')
  }, {
    default: _withCtx(() => [_withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_MainTitle, {
      icon: true
    }, {
      title: _withCtx(() => [_createTextVNode(" Choose your authentication Methods ")]),
      _: 1
    }), !!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
      key: 0,
      type: "error"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)]),
      _: 1
    })) : _createCommentVNode("", true), _createVNode(_component_GridLayout, null, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.authItems, authItem => {
        return _openBlock(), _createBlock(_component_GridItem, {
          key: authItem.authItemID
        }, {
          default: _withCtx(() => [_createVNode(_component_AuthItemPreview, {
            authItem: authItem
          }, null, 8, ["authItem"])]),
          _: 2
        }, 1024);
      }), 128))]),
      _: 1
    })])), [[_directive_loading, _ctx.loading]])]),
    _: 1
  }, 8, ["logo"]);
}