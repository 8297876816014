import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var _SortDropdown;
var SortDropdown_1;
import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { PlatformsSortEnum } from "@/store/enums/PlatformsSortEnum";
import { SortOrderEnum } from "@/store/enums/SortOrderEnum";
let SortDropdown = SortDropdown_1 = (_SortDropdown = class SortDropdown extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "placement", void 0);
    _defineProperty(this, "items", []);
    _defineProperty(this, "selectedItem", void 0);
    _defineProperty(this, "dropdownIsOpened", void 0);
  }
  mounted() {
    this.items = this.buildSortItems();
    this.selectedItem = this.getSelectedItem();
  }
  getSelectedItem() {
    const item = this.items?.filter(item => item.isSelected)[0];
    return item ? item : this.items[0];
  }
  onCommandHandled(key) {
    this.items.forEach(item => item.isSelected = item.key === key);
    this.selectedItem = this.getSelectedItem();
    const sortKeySplitted = this.selectedItem.key?.split(SortDropdown_1.SORT_ORDER_SEPARATOR);
    if (sortKeySplitted?.length == 2) {
      const output = {
        sortBy: sortKeySplitted[0],
        sortOrder: sortKeySplitted[1]
      };
      this.$emit('onItemSelected', output);
    }
  }
  onVisibilityChanges(isOpened) {
    this.dropdownIsOpened = isOpened;
  }
  buildSortItems() {
    return [{
      key: `${PlatformsSortEnum.NAME}${SortDropdown_1.SORT_ORDER_SEPARATOR}${SortOrderEnum.ASC}`,
      value: this.$t("sort_dropdown.a_z").toString(),
      icon: 'sort-alphabetical-ascending',
      isSelected: true
    }, {
      key: `${PlatformsSortEnum.NAME}${SortDropdown_1.SORT_ORDER_SEPARATOR}${SortOrderEnum.DESC}`,
      value: this.$t("sort_dropdown.z_a").toString(),
      icon: 'sort-alphabetical-descending'
    }, {
      key: `${PlatformsSortEnum.CREATION_DATE}${SortDropdown_1.SORT_ORDER_SEPARATOR}${SortOrderEnum.ASC}`,
      value: this.$t("sort_dropdown.newest_first").toString(),
      icon: 'sort-clock-ascending-outline',
      divided: true
    }, {
      key: `${PlatformsSortEnum.CREATION_DATE}${SortDropdown_1.SORT_ORDER_SEPARATOR}${SortOrderEnum.DESC}`,
      value: this.$t("sort_dropdown.oldest_first").toString(),
      icon: 'sort-clock-descending-outline'
    }];
  }
}, _defineProperty(_SortDropdown, "SORT_ORDER_SEPARATOR", '-'), _SortDropdown);
__decorate([Prop({
  default: 'bottom'
})], SortDropdown.prototype, "placement", void 0);
SortDropdown = SortDropdown_1 = __decorate([Options({
  data() {
    return {
      selectedItem: null,
      dropdownIsOpened: false
    };
  }
})], SortDropdown);
export default SortDropdown;