import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4747d4c3"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "check-all-message"
};
const _hoisted_2 = {
  key: 0,
  class: "check-all-message-display"
};
const _hoisted_3 = {
  key: 1,
  class: "check-all-message-display"
};
const _hoisted_4 = {
  key: 0,
  class: "div-search"
};
const _hoisted_5 = {
  key: 0,
  class: "div-search"
};
const _hoisted_6 = {
  key: 0,
  class: "div-search category-search"
};
const _hoisted_7 = {
  key: 0,
  class: "div-search"
};
const _hoisted_8 = {
  key: 0,
  class: "empty-state"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_i18n_t = _resolveComponent("i18n-t");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_header_table = _resolveComponent("header-table");
  const _component_w_text_input = _resolveComponent("w-text-input");
  const _component_title_cell = _resolveComponent("title-cell");
  const _component_user_cell = _resolveComponent("user-cell");
  const _component_select_category = _resolveComponent("select-category");
  const _component_cell = _resolveComponent("cell");
  const _component_el_table = _resolveComponent("el-table");
  const _directive_observe_visibility = _resolveDirective("observe-visibility");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock(_Fragment, null, [_ctx.checkAllMessage || _ctx.uncheckAllMessage ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.checkAllMessage ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_i18n_t, {
    scope: "global",
    keypath: "copy_speaches.search.speaches_selected"
  }, {
    numberOfSpeaches: _withCtx(() => [_createElementVNode("b", null, _toDisplayString(_ctx.numberOfSpeachesChecked) + " ", 1)]),
    _: 1
  }), _createElementVNode("a", {
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.selectAllFromBack && _ctx.selectAllFromBack(...args)),
    class: "select-all-speach-toggle-link"
  }, _toDisplayString(_ctx.$t('copy_speaches.search.select_all_speaches', {
    numberOfSpeaches: _ctx.totalOfSpeachesSearch
  })), 1)])) : _createCommentVNode("", true), _ctx.uncheckAllMessage ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_i18n_t, {
    scope: "global",
    keypath: "copy_speaches.search.all_speaches_selected"
  }, {
    numberOfSpeaches: _withCtx(() => [_createElementVNode("b", null, " " + _toDisplayString(_ctx.totalOfSpeachesSearch) + " ", 1)]),
    _: 1
  }), _createElementVNode("a", {
    onClick: _cache[1] || (_cache[1] =
    //@ts-ignore
    (...args) => _ctx.clearAllFromBack && _ctx.clearAllFromBack(...args)),
    class: "select-all-speach-toggle-link"
  }, _toDisplayString(_ctx.$t('copy_speaches.search.clear_all_speaches')), 1)])) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
    ref: _ctx.tableId,
    data: _ctx.tableData,
    stripe: "",
    size: "large",
    onSelect: _ctx.rowChecked,
    onSelectAll: _ctx.allChecked,
    "header-row-class-name": !_ctx.headerCheckable ? 'header-not-checkable' : '',
    class: _normalizeClass(`speach-table ${_ctx.checkAllMessage || _ctx.uncheckAllMessage ? 'speach-table--checks-msg-visible' : ''}`)
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      type: "selection",
      width: "55"
    }), _createVNode(_component_el_table_column, {
      field: "title",
      width: "450"
    }, {
      header: _withCtx(() => [_createVNode(_component_header_table, {
        label: _ctx.$t('copy_speaches.speaches_to_copy.table.title_col').toString()
      }, null, 8, ["label"]), _ctx.searchable ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_w_text_input, {
        value: _ctx.titleFilter,
        placeholder: _ctx.$t('copy_speaches.speaches_to_copy.table.title_search').toString(),
        width: "100%",
        class: "search-speaches-input",
        onInputChanged: _ctx.onTitleFilterUpdated
      }, null, 8, ["value", "placeholder", "onInputChanged"])])) : _createCommentVNode("", true)]),
      default: _withCtx(scope => [_withDirectives((_openBlock(), _createElementBlock("div", null, [scope.row.title ? (_openBlock(), _createBlock(_component_title_cell, {
        key: 0,
        title: scope.row.title,
        image: scope.row.image
      }, null, 8, ["title", "image"])) : _createCommentVNode("", true)])), [[_directive_observe_visibility, isVisible => _ctx.visibilityChanged(isVisible, scope.row)]])]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      field: "author",
      "min-width": "150"
    }, {
      header: _withCtx(() => [_createVNode(_component_header_table, {
        label: _ctx.$t('copy_speaches.speaches_to_copy.table.author_col').toString()
      }, null, 8, ["label"]), _ctx.searchable ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_w_text_input, {
        value: _ctx.authorFilter,
        placeholder: _ctx.$t('copy_speaches.speaches_to_copy.table.author_search').toString(),
        width: "100%",
        class: "search-speaches-input",
        onInputChanged: _ctx.onAuthorFilterUpdated
      }, null, 8, ["value", "placeholder", "onInputChanged"])])) : _createCommentVNode("", true)]),
      default: _withCtx(scope => [scope.row.user ? (_openBlock(), _createBlock(_component_user_cell, {
        key: 0,
        image: scope.row.user?.image,
        "first-name": scope.row.user?.firstName,
        "last-name": scope.row.user?.lastName,
        email: scope.row.user?.email
      }, null, 8, ["image", "first-name", "last-name", "email"])) : _createCommentVNode("", true)]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      field: "category",
      "min-width": "150"
    }, {
      header: _withCtx(() => [_createVNode(_component_header_table, {
        label: _ctx.$t('copy_speaches.speaches_to_copy.table.category_col').toString()
      }, null, 8, ["label"]), _ctx.searchable ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_select_category, {
        name: "select-category",
        "default-value": _ctx.categoryFilter,
        platform: _ctx.platformId,
        placeholder: _ctx.$t('copy_speaches.speaches_to_copy.table.category_filter').toString(),
        "add-empty-value": true,
        onInput: _ctx.onCategoryUpdated
      }, null, 8, ["default-value", "platform", "placeholder", "onInput"])])) : _createCommentVNode("", true)]),
      default: _withCtx(scope => [scope.row.category ? (_openBlock(), _createBlock(_component_cell, {
        key: 0,
        text: scope.row.category?.name
      }, null, 8, ["text"])) : _createCommentVNode("", true)]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      field: "visibility",
      width: "150"
    }, {
      header: _withCtx(() => [_createVNode(_component_header_table, {
        label: _ctx.$t('copy_speaches.speaches_to_copy.table.visibility_col').toString()
      }, null, 8, ["label"]), _ctx.searchable ? (_openBlock(), _createElementBlock("div", _hoisted_7)) : _createCommentVNode("", true)]),
      default: _withCtx(scope => [scope.row.visibility ? (_openBlock(), _createBlock(_component_cell, {
        key: 0,
        text: scope.row.visibility
      }, null, 8, ["text"])) : _createCommentVNode("", true)]),
      _: 1
    }), _ctx.searchable ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t("copy_speaches.speaches_to_copy.table.empty_state").toString()), 1)) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["data", "onSelect", "onSelectAll", "header-row-class-name", "class"])), [[_directive_loading, _ctx.loading]])], 64);
}