export const AUTH_REQUEST = 'AUTH_REQUEST';

export class AuthParams {
  public login: string;
  public password: string;
  public auto: boolean;

  constructor(login: string, password: string, auto: boolean = false) {
    this.password = password;
    this.login = login;
    this.auto = auto;
  }
}

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const RESET_AUTH_STORE = 'RESET_USER_STORE';
export const UPDATE_RIGHTMANGEMENT_USER_CONNECTE = 'UPDATE_RIGHTMANGEMENT_USER_CONNECTE';
export const UPDATE_RIGHTMANGEMENT_GROUP_USER_CONNECTE = 'UPDATE_RIGHTMANGEMENT_GROUP_USER_CONNECTE';
