import {AuthItem} from '@/store/models/authItem';
import store from '@/store';
import {AUTH_ITEM_SAML_GET_AUTH_REQUEST} from '@/store/actions/authItem';

export let generateRandomString = (length: number): string => {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXY0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export let capitalize = (word: string): string => {
    if (!word) {
        return word;
    }
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
};

export let isEmail = (search: string): boolean => {
    let serchfind: boolean;

    const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    serchfind = regexp.test(search);

    return serchfind;
};

export let getTenantID = (): string => {
    const hostname: string = document.location.hostname;
    const subdomainArray: string[] = hostname.split('.');
    if (subdomainArray.length > 1) {
        return subdomainArray[0];
    } else {
        return hostname;
    }
};

export let redirectToIDP = (authItem: AuthItem): string => {
    let errorMsg = '';
    window.history.pushState('', document.title,
        document.location.href + '&authItemID=' + authItem.authItemID);
    store.dispatch(AUTH_ITEM_SAML_GET_AUTH_REQUEST, authItem.authItemID).then((resp) => {
        document.location = resp;
    }).catch((err) => {
        errorMsg = err;
    });
    return errorMsg;
};

export const isValidPublicIPV4Address = (ipToValidate: string) => {
    const ipBaseRegex = /^(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}$/;  // Validates IP format x.x.x.x (x between 0 and 255)
    const cidrRegex = /\/(3[0-2]|[12]\d|8|9)$/;                                                         // Matches the subnet mask part
    const privateIpRange1 = /^10(\.\d{1,3}){3}$/;                                                       // Matches 10.0.0.0 - 10.255.255.255
    const privateIpRange2 = /^172\.(1[6-9]|2[0-4]|31)(\.\d{1,3}){2}$/;                                  // Matches 172.16.0.0 - 172.31.255.255
    const privateIpRange3 = /^192\.168(\.\d{1,3}){2}$/;                                                 // Matches 192.168.0.0 - 192.168.255.255
    const zeroIp = /^0\.0\.0\.0$/;                                                                      // Matches 0.0.0.0

    const parts = ipToValidate.split('/');
    const ipAddress = parts[0];
    const cidrPart = parts[1];

    // Check basic IP structure
    if (!ipBaseRegex.test(ipAddress)) {
        return false;
    }

    // Check for optional CIDR part
    if (cidrPart && !cidrRegex.test('/' + cidrPart)) {
        return false;
    }

    // Check if it's not a private or zero IP
    if (privateIpRange1.test(ipAddress) || privateIpRange2.test(ipAddress) ||
        privateIpRange3.test(ipAddress) || zeroIp.test(ipAddress)) {
        return false;
    }
    return true;
}
