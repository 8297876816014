import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Banner = _resolveComponent("Banner");
  const _component_w_text_input = _resolveComponent("w-text-input");
  const _component_w_standard_button = _resolveComponent("w-standard-button");
  const _component_w_dialog = _resolveComponent("w-dialog");
  return _openBlock(), _createBlock(_component_w_dialog, {
    visible: _ctx.visible,
    title: _ctx.$t('platforms_management.connect_platform_dialog.title'),
    onDialogClose: _cache[0] || (_cache[0] = $event => _ctx.onDialogClose())
  }, {
    footer: _withCtx(() => [_createVNode(_component_w_standard_button, {
      "is-loading": _ctx.isLoading,
      class: "connect-platform-btn",
      type: "primary",
      "min-width": "117px",
      disabled: !_ctx.url?.trim(),
      onClick: _ctx.addPlatform
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('platforms_management.connect_platform_dialog.connect_btn')), 1)]),
      _: 1
    }, 8, ["is-loading", "disabled", "onClick"])]),
    default: _withCtx(() => [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
      key: 0,
      type: "error"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)]),
      _: 1
    })) : _createCommentVNode("", true), _createVNode(_component_w_text_input, {
      type: "url",
      fieldLabel: _ctx.$t('platforms_management.connect_platform_dialog.platform_url_field_label'),
      placeholder: `https://my-company.${_ctx.CLUSTER_DOMAIN}`,
      value: _ctx.url,
      width: "512px",
      size: "large",
      onInputChanged: _ctx.updateUrl
    }, null, 8, ["fieldLabel", "placeholder", "value", "onInputChanged"])]),
    _: 1
  }, 8, ["visible", "title"]);
}