import axios from 'axios';
import {BACK_ENDPOINT} from '@/store/actions/api';
import {
    AUTH_ITEM_ADD,
    AUTH_ITEM_CHANGE_ATTRIBUTE,
    AUTH_ITEM_CHANGE_CONFIGURATION,
    AUTH_ITEM_DELETE,
    AUTH_ITEM_GET,
    AUTH_ITEM_LIST,
    AUTH_ITEM_PUBLIC_LIST,
    AUTH_ITEM_SAML_GET_AUTH_REQUEST,
    AUTH_ITEM_SAML_METADATA,
} from '@/store/actions/authItem';
import {AuthItem, AuthItemAttribute} from '@/store/models/authItem';
import {AuthItemType, AuthItemTypeConfiguration} from '@/store/enums/authItemEnum';
import {IPageAndSizeCriteria} from "@/interfaces/IPageAndSizeCriteria";


const authItemAPI = BACK_ENDPOINT + '/authItems';
const authItemPublicAPI = BACK_ENDPOINT + '/authItems/public';

function buildAuthItemsSearchUrl(criteria: IPageAndSizeCriteria): string {
    const page: number = criteria?.page || 0;
    let url: string = `${authItemAPI}?page=${page}`;
    if (criteria?.pageSize) {
        url += `&size=${criteria?.pageSize}`;
    }
    return url;
}

export default {
    state: {
        authItems: {},
    },
    mutations: {
        [AUTH_ITEM_LIST]: (state: any, authItems: AuthItem[]) => {
            let a: AuthItem;
            state.authItems = {};
            for (const i in authItems) {
                if (authItems[i] !== undefined) {

                    a = authItems[i];
                    state.authItems[a.authItemID] = a;
                }
            }
        },
        [AUTH_ITEM_ADD]: (state: any, authItem: AuthItem) => {
            state.authItems[authItem.authItemID] = authItem;
        },
        [AUTH_ITEM_DELETE]: (state: any, authItemId: string) => {
            delete state.authItems[authItemId];
        },
    },
    getters: {
        authItem: (state: any) => (authItemID: string) => {
            return state.authItems[authItemID];
        },
        authItems: (state: any) => {
            return state.authItems;
        },
    },
    actions: {
        async [AUTH_ITEM_PUBLIC_LIST]({commit, dispatch}: { commit: any, dispatch: any }, platformUrl:string) {
            const response = await axios({
                url: authItemPublicAPI,
                method: 'GET',
                params:{platformUrl}
            }).then((resp) => {
                const list = resp.data;
                commit(AUTH_ITEM_LIST, list);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        //// PRIVATE
        async [AUTH_ITEM_LIST]({commit, dispatch}: { commit: any, dispatch: any }, searchCriteria?: IPageAndSizeCriteria) {
            await axios({
                url: buildAuthItemsSearchUrl(searchCriteria),
                method: 'GET',
            }).then((resp) => {
                const list = resp.data;
                commit(AUTH_ITEM_LIST, list);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [AUTH_ITEM_ADD]({commit, dispatch}: { commit: any, dispatch: any },
                              {name, type}: {name: string, type: AuthItemType}) {
            const response = await axios({
                url: authItemAPI,
                method: 'POST',
                data: {name, type},
            }).then((resp) => {
                const authItem = resp.data;
                commit(AUTH_ITEM_ADD, authItem);
                return authItem;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });

            return response;
        },
        async [AUTH_ITEM_DELETE]({commit, dispatch}: { commit: any, dispatch: any }, authItemId: string) {
            const response = await axios({
                url: authItemAPI + '/' + authItemId,
                method: 'DELETE',
            }).then((resp) => {
                commit(AUTH_ITEM_DELETE, authItemId);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [AUTH_ITEM_GET]({commit, dispatch}: { commit: any, dispatch: any }, authItemId: string) {
            const response = await axios({
                url: authItemAPI + '/' + authItemId,
                method: 'GET',
            }).then((resp) => {
                commit(AUTH_ITEM_ADD, resp.data);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [AUTH_ITEM_SAML_METADATA]({commit, dispatch}: { commit: any, dispatch: any }, authItemId: string) {
            const response = await axios({
                url: authItemAPI + '/' + authItemId + '/metadata',
                method: 'GET',
            }).then((resp) => {
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
            return response;
        },

        async [AUTH_ITEM_CHANGE_CONFIGURATION](
            {commit, dispatch}: { commit: any, dispatch: any },
            {authItemID, type, value}: {authItemID: string, type: AuthItemTypeConfiguration, value: string}) {
            const response = await axios({
                url: authItemAPI + '/' + authItemID + '/configuration',
                method: 'PUT',
                data: {type, value},
            }).then((resp) => {
                commit(AUTH_ITEM_ADD, resp.data);
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
            return response;
        },

        async [AUTH_ITEM_SAML_GET_AUTH_REQUEST]({commit, dispatch}: { commit: any, dispatch: any },
                                                authItemID: string) {
            const response = await axios({
                url: authItemAPI + '/' + authItemID + '/authNRequest',
                method: 'GET',
            }).then((resp) => {
              //  commit(AUTH_ITEM_ADD,resp.data)
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
            return response;
        },
        async [AUTH_ITEM_CHANGE_ATTRIBUTE](
            {commit, dispatch}: { commit: any, dispatch: any },
            {authItemID, attributes}: {authItemID: string, attributes: AuthItemAttribute[]}) {
            const response = await axios({
                url: authItemAPI + '/' + authItemID + '/attributes',
                data: {attributes},
                method: 'PUT',
            }).then((resp) => {
                commit(AUTH_ITEM_ADD, resp.data);
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
            return response;
        },
    },
};
