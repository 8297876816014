import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import MainTitle from '@/components/title/MainTitle.vue';
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import { router } from "@/router";
import CenterTop from '@/components/layout/CenterTop.vue';
import AuthItemsTable from '@/components/authItem/AuthItemsTable.vue';
import GridLayout from '@/components/layout/GridLayout.vue';
import GridItem from '@/components/layout/GridItem.vue';
import Card from '@/components/layout/Card.vue';
import AuthItemPreview from '@/components/authItem/AuthItemPreview.vue';
import { AUTH_ITEM_PUBLIC_LIST } from '@/store/actions/authItem';
import { AUTH_LINK_EMPTY_LIST } from '@/store/actions/authLink';
import { redirectToIDP } from '@/utils/util';
let IdpChoose = class IdpChoose extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "loading", false);
    _defineProperty(this, "errorMsg", '');
    _defineProperty(this, "token", '');
    _defineProperty(this, "asc", '');
    _defineProperty(this, "authItemIDSelected", '');
    _defineProperty(this, "originalPlatform", void 0);
  }
  get authItems() {
    return store.getters.authItems;
  }
  beforeCreate() {
    store.commit(AUTH_LINK_EMPTY_LIST);
  }
  created() {
    this.loading = true;
    if (this.$route.query.token) {
      this.token = this.$route.query.token + '';
      this.asc = this.$route.query.asc + '';
      this.authItemIDSelected = this.$route.query.authItemID + '';
    }
    // we save the original platform if its exist, to choose automatically the team
    if (this.$route.query.platform != null) {
      this.originalPlatform = this.$route.query.platform;
    }
    // if we have a token, we have to go to the choose Team page
    if (this.token !== '') {
      router.push({
        name: 'authLinkConnect',
        params: {
          authItemID: this.authItemIDSelected,
          token: this.token,
          asc: this.asc,
          originalPlatform: this.originalPlatform
        }
      });
    } else {
      this.loadPublicAuthItem();
    }
  }
  loadPublicAuthItem() {
    // we load the list of cluster
    store.dispatch(AUTH_ITEM_PUBLIC_LIST, this.originalPlatform).then(() => {
      const listOfAuthItems = Object.keys(this.authItems);
      if (Object.keys(this.authItems).length === 1) {
        redirectToIDP(this.authItems[listOfAuthItems[0]]);
      } else {
        this.loading = false;
      }
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
};
IdpChoose = __decorate([Options({
  components: {
    AuthItemPreview,
    CenterTop,
    Card,
    GridItem,
    GridLayout,
    AuthItemsTable,
    MainTitle
  }
})], IdpChoose);
export default IdpChoose;