import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import MainTitle from '@/components/title/MainTitle.vue';
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import { router } from "@/router";
import AuthItemsTable from '@/components/authItem/AuthItemsTable.vue';
import AuthItemCreation from './AuthItemCreation.vue';
import CenterTop from "@/components/layout/CenterTop.vue";
let AuthItemList = class AuthItemList extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "errorMsg", '');
    _defineProperty(this, "toggleAuthItemCreationDialog", false);
  }
  mounted() {
    if (!this.isAuthItem) {
      router.push({
        name: 'HomePage'
      });
    }
  }
  get isCreateAuthItem() {
    return store.getters.isAuthItemCreateAivailable;
  }
  get isAuthItem() {
    return store.getters.isAuthItemAvailable;
  }
};
AuthItemList = __decorate([Options({
  components: {
    AuthItemsTable,
    CenterTop,
    MainTitle,
    AuthItemCreation
  }
})], AuthItemList);
export default AuthItemList;