import axios from 'axios';
import {BACK_ENDPOINT} from '@/store/actions/api';
import {IWhitelistedIpAddress} from "@/interfaces/IWhitelistedIpAddress";
import {
  ADD_WHITELISTED_IP, DELETE_IP_IN_STORE,
  IMPORT_WHITELISTED_IPS,
  REMOVE_ALL_WHITELISTED_IP,
  REMOVE_WHITELISTED_IP,
  RESET_WHITELISTED_IPS_SEARCH_PARAMS,
  SEARCH_WHITELISTED_IPS,
} from "@/store/actions/ipWhitelist";
import {IPaginatedSearchResult} from "@/interfaces/IPaginatedSearchResult";

const IP_WHITELIST_API: string = BACK_ENDPOINT + '/ip-whitelist';
export const SEARCH_WHITELISTED_IP_PAGE_SIZE: number = 100;

export default {
  state: {
    whitelistedIps: [],
    totalIps: 0,
  },
  mutations: {
    [SEARCH_WHITELISTED_IPS]: (state: any, searchResult: IPaginatedSearchResult) => {
      const whitelistedIps: IWhitelistedIpAddress[] = searchResult.result;
      whitelistedIps.forEach(ip => ip.addedOn = new Date(ip.addedOn).toLocaleDateString('en-US'))
      if (searchResult.page === 1) {
        state.whitelistedIps = whitelistedIps;
      } else {
        state.whitelistedIps = [...state.whitelistedIps, ...whitelistedIps];
      }
      state.totalIps = searchResult.total;
    },
    [RESET_WHITELISTED_IPS_SEARCH_PARAMS]: (state: any) => {
      state.whitelistedIps = [];
      state.totalIps = 0;
    },
    [DELETE_IP_IN_STORE]: (state: any, ipId) => {
      state.whitelistedIps = state.whitelistedIps.filter(w => w.id !== ipId);
      state.totalIps--;
    }
  },
  getters: {
    whitelistedIps: (state: any) => {
      return Object.values(state.whitelistedIps);
    },
    totalIps: (state: any) => {
      return state.totalIps;
    }
  },
  actions: {
    async [SEARCH_WHITELISTED_IPS]({commit}: { commit: any }, {platformID, searchIp, page, asc}:
        { platformID: string, searchIp: string, page: number, asc: boolean }) {
      let url: string = `${IP_WHITELIST_API}?platformID=${platformID}`;
      if (searchIp?.length) {
        url += `&searchIp=${searchIp}`;
      }
      url += `&asc=${asc}&page=${page}&objectByPage=${SEARCH_WHITELISTED_IP_PAGE_SIZE}`;
      await axios({
        url: url,
        method: 'GET'
      }).then((resp) => {
        commit(SEARCH_WHITELISTED_IPS, resp.data);
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },

    async [ADD_WHITELISTED_IP]({commit}: { commit: any }, {ipAddress, platformID}) {
      await axios({
        url: `${IP_WHITELIST_API}?platformID=${platformID}`,
        data: {ipAddress},
        method: 'POST'
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },

    async [IMPORT_WHITELISTED_IPS]({commit}: { commit: any }, {fileUrl, platformID}) {
      await axios({
        url: `${IP_WHITELIST_API}/do-batch-import?platformID=${platformID}`,
        data: {fileUrl},
        method: 'POST'
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },

    async [REMOVE_WHITELISTED_IP]({commit}: { commit: any }, {ipId, platformID}) {
      await axios({
        url: `${IP_WHITELIST_API}/${ipId}?platformID=${platformID}`,
        method: 'DELETE'
      }).then(() => {
        commit(DELETE_IP_IN_STORE, ipId);
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },

    async [REMOVE_ALL_WHITELISTED_IP]({commit}: { commit: any }, platformID) {
      await axios({
        url: `${IP_WHITELIST_API}/do-delete-all?platformID=${platformID}`,
        method: 'DELETE'
      }).then(() => {
        commit(RESET_WHITELISTED_IPS_SEARCH_PARAMS);
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },

    [RESET_WHITELISTED_IPS_SEARCH_PARAMS]({commit}: { commit: any }) {
      commit(RESET_WHITELISTED_IPS_SEARCH_PARAMS);
    },
  },

};
