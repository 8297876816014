import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-26f62e98"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "component color-picker"
};
const _hoisted_2 = ["width", "height"];
const _hoisted_3 = {
  class: "selectionColorLine"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("canvas", {
    id: "canvas",
    class: "canvaColorPicker",
    onMousemove: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.onMouseMove && _ctx.onMouseMove(...args)),
    onMouseout: _cache[1] || (_cache[1] =
    //@ts-ignore
    (...args) => _ctx.onMouseOut && _ctx.onMouseOut(...args)),
    onClick: _cache[2] || (_cache[2] =
    //@ts-ignore
    (...args) => _ctx.onClick && _ctx.onClick(...args)),
    width: _ctx.width,
    height: _ctx.height,
    style: _normalizeStyle({
      backgroundColor: _ctx.color
    })
  }, null, 44, _hoisted_2), _withDirectives(_createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
    class: "colorSelectionPointer orange",
    onClick: _cache[3] || (_cache[3] = $event => _ctx.selectAColor('#F8550F'))
  }), _createElementVNode("div", {
    class: "colorSelectionPointer blue",
    onClick: _cache[4] || (_cache[4] = $event => _ctx.selectAColor('#0096ff'))
  }), _createElementVNode("div", {
    class: "colorSelectionPointer green",
    onClick: _cache[5] || (_cache[5] = $event => _ctx.selectAColor('#A2F896'))
  }), _createElementVNode("div", {
    class: "colorSelectionPointer violet",
    onClick: _cache[6] || (_cache[6] = $event => _ctx.selectAColor('#663cf2'))
  }), _createElementVNode("div", {
    class: "colorSelectionPointer blueDark",
    onClick: _cache[7] || (_cache[7] = $event => _ctx.selectAColor('#0051ff'))
  })], 512), [[_vShow, _ctx.panelChoose]])]);
}