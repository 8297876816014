import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-83b7832c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "w-button__content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createBlock(_component_el_button, {
    class: _normalizeClass(`w-button w-button--${_ctx.type} ${_ctx.isLoading ? 'is-loading' : ''}`),
    type: _ctx.type,
    size: _ctx.size,
    loading: _ctx.isLoading,
    disabled: _ctx.disabled,
    style: _normalizeStyle(`${_ctx.minHeight ? 'min-height:' + _ctx.minHeight + ';' : ''}
                      ${_ctx.minWidth ? 'min-width:' + _ctx.minWidth + ';' : ''}`)
  }, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])]),
    _: 3
  }, 8, ["class", "type", "size", "loading", "disabled", "style"]);
}