import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Card from '@/components/layout/Card.vue';
let UserItem = class UserItem extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "user", void 0);
  }
};
__decorate([Prop()], UserItem.prototype, "user", void 0);
UserItem = __decorate([Options({
  components: {
    Card
  }
})], UserItem);
export default UserItem;