import axios from 'axios';
import {
  CLUSTER_LIST,
  COPY_SPEACH_DEST_PLATFORM_SEARCH_FILTER,
  COPY_SPEACH_SOURCE_PLATFORM_SEARCH_FILTER,
  DELETE_SLIDEDECK_BACKGROUND,
  ENROLL_PLATFORM,
  PLATFORM_CREATE,
  PLATFORM_DELETE,
  PLATFORM_LIST,
  PLATFORM_REFRESH,
  PLATFORM_SUMMARY,
  RESET_STORE_PLATFORM,
  SEARCH_COPY_SPEACH_DEST_PLATFORMS,
  SEARCH_COPY_SPEACH_SOURCE_PLATFORMS,
  SEARCH_PLATFORMS,
  SEARCH_PLATFORMS_APPEND,
  SET_SLIDEDECK_BACKGROUND,
  SETTING_CHANGE,
  SETTINGS_LIST,
  SLIDEDECK_BACKGROUND
} from '@/store/actions/platforms';
import {Platform, PlatformCreateParams} from '@/store/models/platform';
import {BACK_ENDPOINT} from '@/store/actions/api';
import {Summary} from '@/store/models/summary';

import {SummaryKey} from '@/store/enums/sumaryKeyEnum';
import {SettingKey} from '@/store/enums/settingKeyEnum';
import {SettingChangeParams, Settings, SlideDeckBackground, SlideDeckSetting} from '@/store/models/settings';
import {IPlatformsSearchCriteria} from "@/interfaces/IPlatformsSearchCriteria";
import {PaginationWrapper} from "@/store/models/paginationWrapper";

const platformAPI = BACK_ENDPOINT + '/platform';
const clusterAPI = `${BACK_ENDPOINT}/clusters`;
const enrollAPIStart = BACK_ENDPOINT + '/enroll/start';

function updatePlatformsStateAndRelatedSummaries(state, platforms: Platform[]) {
  let p: Platform;
  state.platforms = {};
  for (const i in platforms) {
    if (platforms[i] !== undefined) {

      p = platforms[i];
      state.platforms[p.platformID] = p;
    }
  }

  // now we have to clean summaries who are not present here
  for (const [key, _value] of Object.entries(state.summaries)) {
    if (state.platforms[key] === undefined) {
      delete state.summaries[key];
    }
  }
}

function buildPlatformsSearchUrl(platformAPI: string, searchCriteria: IPlatformsSearchCriteria): string {
  const page: number = searchCriteria.page ? searchCriteria.page : 0;
  let url: string = `${platformAPI}/do-search?page=${page}`;
  if (searchCriteria.pageSize) {
    url += `&size=${searchCriteria.pageSize}`
  }
  if (searchCriteria.platformName?.trim()) {
    url += `&name=${searchCriteria.platformName}`
  }
  if (searchCriteria.sortBy) {
    url += `&sortBy=${searchCriteria.sortBy}`
  }
  if (searchCriteria.sortOrder) {
    url += `&sortOrder=${searchCriteria.sortOrder}`
  }
  return url;
}

function storeCopySpeachPlatforms(state: any, stateAttribute: string, listPlatforms: Platform[]) {
  let p: Platform;
  state[stateAttribute] = {};
  for (const i in listPlatforms) {
    if (listPlatforms[i] !== undefined) {
      p = listPlatforms[i];
      state[stateAttribute][p.platformID] = p;
    }
  }
}

export default {
  state: {
    platforms: {},
    copySpeachSourcePlatforms: {},
    copySpeachDestPlatforms: {},
    copySpeachSourcePlatformSearchFilter: '',
    copySpeachDestPlatformSearchFilter: '',
    summaries: {},
    clusters: [],
    settings: {},
    slideDeckSettings: {},
    totalNumberPlatforms: 0,
    platformsCurrentPage: 0,
    totalPlatformsOnCurrentPage: 0
  },
  mutations: {
    [RESET_STORE_PLATFORM]: (state: any) => {
      state.platforms = {};
      state.summaries = {};
      state.clusters = [];
      state.settings = {};
      state.slideDeckSettings = {};
      state.copySpeachSourcePlatforms = {};
      state.copySpeachDestPlatforms = {};
      state.copySpeachSourcePlatformSearchFilter = '';
      state.copySpeachDestPlatformSearchFilter = '';
    },
    [PLATFORM_LIST]: (state: any, platforms: Platform[]) => {
      updatePlatformsStateAndRelatedSummaries(state, platforms);
    },
    [SEARCH_PLATFORMS]: (state: any, listPlatforms: PaginationWrapper<Platform>) => {
      state.totalNumberPlatforms = listPlatforms.total;
      state.platformsCurrentPage = listPlatforms.page;
      state.totalPlatformsOnCurrentPage = listPlatforms.result.length;
      updatePlatformsStateAndRelatedSummaries(state, listPlatforms.result);
    },
    [SEARCH_PLATFORMS_APPEND]: (state: any, listPlatforms: PaginationWrapper<Platform>) => {
      state.totalNumberPlatforms = listPlatforms.total;
      state.platformsCurrentPage = listPlatforms.page;
      state.totalPlatformsOnCurrentPage = listPlatforms.result.length;
      listPlatforms.result?.forEach(p => state.platforms[p.platformID] = p)
    },
    [SEARCH_COPY_SPEACH_SOURCE_PLATFORMS]: (state: any, listPlatforms: PaginationWrapper<Platform>) => {
      storeCopySpeachPlatforms(state, 'copySpeachSourcePlatforms', listPlatforms.result);
    },
    [SEARCH_COPY_SPEACH_DEST_PLATFORMS]: (state: any, listPlatforms: PaginationWrapper<Platform>) => {
      storeCopySpeachPlatforms(state, 'copySpeachDestPlatforms', listPlatforms.result);
    },
    [COPY_SPEACH_SOURCE_PLATFORM_SEARCH_FILTER]: (state: any, filter: string) => {
      state.copySpeachSourcePlatformSearchFilter = filter;
    },
    [COPY_SPEACH_DEST_PLATFORM_SEARCH_FILTER]: (state: any, filter: string) => {
      state.copySpeachDestPlatformSearchFilter = filter;
    },
    [CLUSTER_LIST]: (state: any, clusters: string[]) => {
      state.clusters = clusters;
    },
    [PLATFORM_CREATE]: (state: any, platform: Platform) => {
      state.platforms[platform.platformID] = platform;
    },
    [PLATFORM_SUMMARY]: (state: any, sum: Summary) => {
      state.summaries[sum.platformID] = sum;
    },
    [PLATFORM_DELETE]: (state: any, platform: Platform) => {
      delete state.platforms[platform.platformID];
      delete state.summaries[platform.platformID];
    },
    [SETTINGS_LIST]: (state: any, settings: Settings) => {
      delete state.settings[settings.platformID];
      state.settings[settings.platformID] = settings;
    },
    [SETTING_CHANGE]: (state: any,
                       settingChangeParams: SettingChangeParams) => {
      const settings: Settings = state.settings[settingChangeParams.platformID];
      settings[settingChangeParams.key] = settingChangeParams.value;
      state.settings[settingChangeParams.platformID] = settings;
    },
    [SLIDEDECK_BACKGROUND]: (state: any, slideDeckSettings: SlideDeckSetting) => {
      state.slideDeckSettings[slideDeckSettings.platformID] = slideDeckSettings;
    }
  },
  getters: {
    platform: (state: any) => (platformID: string) => {
      return state.platforms[platformID];
    },
    platforms: (state: any) => {
      return state.platforms;
    },
    copySpeachSourcePlatforms: (state: any) => {
      return state.copySpeachSourcePlatforms;
    },
    copySpeachDestPlatforms: (state: any) => {
      return state.copySpeachDestPlatforms;
    },
    copySpeachSourcePlatformSearchFilter: (state: any) => {
      return state.copySpeachSourcePlatformSearchFilter;
    },
    copySpeachDestPlatformSearchFilter: (state: any) => {
      return state.copySpeachDestPlatformSearchFilter;
    },
    clusters: (state: any) => {
      return state.clusters;
    },
    summary: (state: any) => (platform: Platform): Summary => {
      const summary = state.summaries[platform.platformID];
      return summary;
    },
    summaries: (state: any) => {
      return state.summaries;
    },
    settings: (state: any) => (platformID: string): Settings => {
      return state.settings[platformID];
    },
    slideDeckSetting: (state: any) => (platformID: string): SlideDeckSetting => {
      return state.slideDeckSettings[platformID];
    },
    totalNumberPlatforms: (state: any): number => {
      return state.totalNumberPlatforms;
    },
    platformsCurrentPage: (state: any): number => {
      return state.platformsCurrentPage;
    },
    totalPlatformsOnCurrentPage: (state: any): number => {
      return state.totalPlatformsOnCurrentPage;
    }
  },
  actions: {

    async [PLATFORM_LIST]({commit, dispatch}: { commit: any, dispatch: any }) {
      await axios({
        url: platformAPI,
        method: 'GET',
      }).then((resp) => {
        commit(PLATFORM_LIST, resp.data);
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [SEARCH_PLATFORMS]({
                               commit,
                               dispatch
                             }: { commit: any, dispatch: any }, searchCriteria: IPlatformsSearchCriteria) {
      await axios({
        url: buildPlatformsSearchUrl(platformAPI, searchCriteria),
        method: 'GET',
      }).then((resp) => {
        if (searchCriteria.appendResultInStore) {
          commit(SEARCH_PLATFORMS_APPEND, resp.data);
        } else {
          commit(SEARCH_PLATFORMS, resp.data);
        }
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [SEARCH_COPY_SPEACH_SOURCE_PLATFORMS]({
                               commit,
                               dispatch
                             }: { commit: any, dispatch: any }, searchCriteria: IPlatformsSearchCriteria) {
      await axios({
        url: buildPlatformsSearchUrl(platformAPI, searchCriteria),
        method: 'GET',
      }).then((resp) => {
          commit(SEARCH_COPY_SPEACH_SOURCE_PLATFORMS, resp.data);
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [SEARCH_COPY_SPEACH_DEST_PLATFORMS]({
                                                  commit,
                                                  dispatch
                                                }: { commit: any, dispatch: any }, searchCriteria: IPlatformsSearchCriteria) {
      await axios({
        url: buildPlatformsSearchUrl(platformAPI, searchCriteria),
        method: 'GET',
      }).then((resp) => {
        commit(SEARCH_COPY_SPEACH_DEST_PLATFORMS, resp.data);
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [COPY_SPEACH_SOURCE_PLATFORM_SEARCH_FILTER]({commit, dispatch}: { commit: any, dispatch: any }, filter: string) {
      commit(COPY_SPEACH_SOURCE_PLATFORM_SEARCH_FILTER, filter);
    },
    async [COPY_SPEACH_DEST_PLATFORM_SEARCH_FILTER]({commit, dispatch}: { commit: any, dispatch: any }, filter: string) {
      commit(COPY_SPEACH_DEST_PLATFORM_SEARCH_FILTER, filter);
    },
    async [CLUSTER_LIST]({commit, dispatch}: { commit: any, dispatch: any }) {
      await axios({
        url: clusterAPI,
        method: 'GET',
      }).then((resp) => {
        commit(CLUSTER_LIST, resp.data);
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [PLATFORM_SUMMARY]({commit, dispatch}: { commit: any, dispatch: any }, platform: Platform) {
      await axios({
        url: platformAPI + '/' + platform.platformID + '/stat/summary',
        method: 'GET',
      }).then((resp) => {
          const sum = new Summary(platform.platformID,
            resp.data[SummaryKey.NB_SPEACH_PUBLISH],
            resp.data[SummaryKey.NB_SPEACH_TO_PUBLISH],
            resp.data[SummaryKey.NB_SPEACH_TO_VALIDATE],
            resp.data[SummaryKey.NB_SPEACH_MAKER],
            resp.data[SummaryKey.NB_APPROVER],
            resp.data[SummaryKey.NB_USER],
            resp.data[SummaryKey.NB_ADMIN],
            resp.data[SummaryKey.NB_CONNECTED_THIS_MONTH],
            resp.data[SummaryKey.NB_NEW_SPEACH_WEEK],
            resp.data[SummaryKey.NB_NEW_SPEACH_MONTH],
            resp.data[SummaryKey.NB_NEW_SPEACH_3MONTH],
            resp.data[SummaryKey.NB_NEW_SPEACH_6MONTH],
            resp.data[SummaryKey.NB_SPEACH_UNIQUE_READ_ONE_WEEK],
            resp.data[SummaryKey.NB_SPEACH_UNIQUE_READ_ONE_MONTH],
            resp.data[SummaryKey.NB_SPEACH_UNIQUE_READ_THREE_MONTHS],
            resp.data[SummaryKey.NB_SPEACH_UNIQUE_READ_SIX_MONTHS]
          );
          commit(PLATFORM_SUMMARY, sum);
        },
      ).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [ENROLL_PLATFORM]({commit, dispatch}: { commit: any, dispatch: any }, url: string) {
      const origin = document.location.origin;
      await axios({
        url: enrollAPIStart,
        data: {url, origin},
        method: 'POST',
      }).then((resp) => {
        document.location.href = resp.data;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [PLATFORM_DELETE]({commit, dispatch}: { commit: any, dispatch: any }, platform: Platform) {
      await axios({
        url: platformAPI + '/' + platform.platformID,
        method: 'DELETE',
      }).then((resp) => {
        commit(PLATFORM_DELETE, platform);
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [PLATFORM_REFRESH]({commit, dispatch}: { commit: any, dispatch: any }, platform: Platform) {
      await axios({
        url: platformAPI + '/' + platform.platformID,
        method: 'PUT',
      }).then((resp) => {
        commit(PLATFORM_CREATE, resp.data);
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },

    async [PLATFORM_CREATE]({commit, dispatch}: { commit: any, dispatch: any },
                            platformCreateParams: PlatformCreateParams) {

      await axios({
        url: clusterAPI,
        method: 'POST',
        data: {
          url: platformCreateParams.url,
          title: platformCreateParams.title,
          color: platformCreateParams.color,
          name: platformCreateParams.name,
          logo: platformCreateParams.logo,
          serverID: platformCreateParams.serverID,
        },
      }).then((resp) => {
        commit(PLATFORM_CREATE, resp.data);
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },

    async [SETTINGS_LIST]({commit, dispatch}: { commit: any, dispatch: any }, platform: Platform) {
      await axios({
        url: platformAPI + '/' + platform.platformID + '/settings',
        method: 'GET',
      }).then((resp) => {
          const settings = new Settings();
          settings.platformID = platform.platformID;
          settings.PLATFORM_VERSION = resp.data[SettingKey.PLATFORM_VERSION];
          settings.COLOR = resp.data[SettingKey.COLOR];
          settings.LOGO = resp.data[SettingKey.LOGO];
          settings.COMMENT_ENABLED = resp.data[SettingKey.COMMENT_ENABLED]==='true';
          settings.WORKFLOW_ENABLED = resp.data[SettingKey.WORKFLOW_ENABLED]==='true';
          settings.TRANSCRIPT_ENABLED = resp.data[SettingKey.TRANSCRIPT_ENABLED]==='true';
          settings.USER_SEARCH_ENGINE = resp.data[SettingKey.USER_SEARCH_ENGINE]==='true';
          settings.VIDEO_DOWNLOAD_ENABLED = resp.data[SettingKey.VIDEO_DOWNLOAD_ENABLED]==='true';
          settings.PDF_DOWNLOAD_ENABLED = resp.data[SettingKey.PDF_DOWNLOAD_ENABLED]==='true';
          settings.NEW_USER_IS_FORMATEUR = resp.data[SettingKey.NEW_USER_IS_FORMATEUR]==='true';
          settings.AUTO_NEXT_SECTION = resp.data[SettingKey.AUTO_NEXT_SECTION]==='true';
          settings.VIDEO_DEFAULT_SD = resp.data[SettingKey.VIDEO_DEFAULT_SD]==='true';
          settings.SUPPORT_BUBBLE = resp.data[SettingKey.SUPPORT_BUBBLE]==='true';
          settings.SUBTITLE_AUTO_DISPLAY_IN_VIDEO = resp.data[SettingKey.SUBTITLE_AUTO_DISPLAY_IN_VIDEO]==='true';
          settings.SEARCH_ENGINE_INDEXATION = resp.data[SettingKey.SEARCH_ENGINE_INDEXATION]==='true';
          settings.ALLOW_ANONYMOUS = resp.data[SettingKey.ALLOW_ANONYMOUS]==='true';
          settings.ALLOW_ACCOUNT_CREATION = resp.data[SettingKey.ALLOW_ACCOUNT_CREATION]==='true';
          settings.WORKFLOW_MANDATORY = resp.data[SettingKey.WORKFLOW_MANDATORY]==='true';
          settings.INDEX_ALL_CONTENT = resp.data[SettingKey.INDEX_ALL_CONTENT]==='true';
          settings.ALLOW_PUBLISH_PUBLICATION = resp.data[SettingKey.ALLOW_PUBLISH_PUBLICATION]==='true';
          settings.COPY_SPEACH_ENABLED = resp.data[SettingKey.COPY_SPEACH_ENABLED]==='true';
        settings.ACTIVATE_PARTAGE = resp.data[SettingKey.ACTIVATE_PARTAGE]==='true';

          commit(SETTINGS_LIST, settings);
        },
      ).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },

    async [SETTING_CHANGE]({commit, dispatch}: { commit: any, dispatch: any },
                           settingChangeParam: SettingChangeParams) {
      await axios({
        url: platformAPI + '/' + settingChangeParam.platformID + '/settings',
        method: 'PUT',
        data: {
          key: settingChangeParam.key,
          value: settingChangeParam.value,
        },
      }).then((resp) => {
        commit(SETTING_CHANGE, settingChangeParam);
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },

    async [SLIDEDECK_BACKGROUND]({commit, dispatch}: { commit: any, dispatch: any }, platform: Platform) {
      await axios({
        url: platformAPI + '/' + platform.platformID + '/settings/slideDeckBackground',
        method: 'GET',
      }).then((resp) => {

          const slideConfig = new SlideDeckSetting(platform.platformID, resp.data);
          commit(SLIDEDECK_BACKGROUND, slideConfig);
        },
      ).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },

    async [SET_SLIDEDECK_BACKGROUND]({commit, dispatch}: { commit: any, dispatch: any },
                                     {platform, slideckBackground}:
                                       { platform: Platform, slideckBackground: SlideDeckBackground }) {
      await axios({
        url: platformAPI + '/' + platform.platformID + '/settings/slideDeckBackground',
        method: 'PUT',
        data: {
          color: slideckBackground.color,
          background: slideckBackground.url,
          position: slideckBackground.position,
        },
      }).then((resp) => {
          // TODO don't refresh the entire list
          dispatch(SLIDEDECK_BACKGROUND, platform);
        },
      ).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [DELETE_SLIDEDECK_BACKGROUND]({commit, dispatch}: { commit: any, dispatch: any },
                                        {platform, position}: { platform: Platform, position: number }) {
      await axios({
        url: platformAPI + '/' + platform.platformID + '/settings/slideDeckBackground',
        method: 'DELETE',
        data: {
          position,
        },
      }).then((resp) => {
          dispatch(SLIDEDECK_BACKGROUND, platform);
        },
      ).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    }
  },

};
