import {User} from '@/store/models/user';

export class RightManagement {
    public key: string;
    public value: string;
    public rightManagementGroup: string[];
}

export class RightManagementGroupParam {
    public key: string;
    public value: string;
    public user: User;
}

export class RightManagementKeyParam {
    public key: string;
    public value: string;
    public user: User;
}
