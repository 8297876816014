import axios from 'axios';
import {BACK_ENDPOINT} from '@/store/actions/api';
import {VERSION_BACK} from '@/store/actions/version';

const versionAPI = BACK_ENDPOINT + '/version';

export default {
    state: {
        versionBack:''
    },
    mutations: {
        [VERSION_BACK]: (state: any, versionBack: string) => {
            state.versionBack = versionBack;
        }
    },
    getters: {
        versionBack: (state: any): string => state.versionBack
    },
    actions: {
        async [VERSION_BACK]({commit, dispatch}: { commit: any, dispatch: any }) {
             await axios({
                url: versionAPI,
                method: 'GET',
            }).then((resp) =>
               commit(VERSION_BACK, resp.data)
                ).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        }
    }
};
