
export enum SummaryKey {
    NB_SPEACH_PUBLISH = 'nbSpeachPublish',
    NB_SPEACH_TO_PUBLISH = 'nbSpeachToPublish',
    NB_SPEACH_TO_VALIDATE = 'nbSpeachToValidate',
    NB_SPEACH_MAKER = 'nbSpeachMaker',
    NB_APPROVER = 'nbApprover',
    NB_USER = 'nbUser',

    NB_ADMIN = 'nbAdmin',
    NB_CONNECTED_THIS_MONTH = 'nbConnectedMonth',
    NB_NEW_SPEACH_WEEK = 'nbNewSpeachWeek',
    NB_NEW_SPEACH_MONTH = 'nbNewSpeachMonth',
    NB_NEW_SPEACH_3MONTH = 'nbNewSpeach3Month',
    NB_NEW_SPEACH_6MONTH = 'nbNewSpeach6Month',
    NB_SPEACH_UNIQUE_READ_ONE_WEEK = 'nbSpeachUniqueRead1Week',
    NB_SPEACH_UNIQUE_READ_ONE_MONTH = 'nbSpeachUniqueRead1Month',
    NB_SPEACH_UNIQUE_READ_THREE_MONTHS = 'nbSpeachUniqueRead3Months',
    NB_SPEACH_UNIQUE_READ_SIX_MONTHS = 'nbSpeachUniqueRead6Months'
}
