import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import UserCell from '@/components/platform/copySpeach/UserCell.vue';
import store from '@/store';
import { debounce } from 'lodash';
import { AUTHORS_LIST, RESET_AUTHORS_LIST } from '@/store/actions/speach';
import { v4 as uuidv4 } from "uuid";
import InfiniteLoading from 'vue-infinite-loading';
let SearchUser = class SearchUser extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "platform", void 0);
    _defineProperty(this, "value", void 0);
    _defineProperty(this, "placeholder", void 0);
    _defineProperty(this, "realValue", null);
    _defineProperty(this, "searchAuthorsInfiniteLoader", null);
    _defineProperty(this, "infiniteLoaderID", uuidv4());
    _defineProperty(this, "isFetching", false);
    _defineProperty(this, "textSearched", '');
    _defineProperty(this, "currentPage", 1);
  }
  mounted() {
    this.initInfiniteLoader();
    this.setTextSearchedAndRealValue();
  }
  initInfiniteLoader() {
    this.searchAuthorsInfiniteLoader = debounce(state => {
      if (this.authorsCount > this.authors.length) {
        this.infiniteSearchAuthors(++this.currentPage, true, state);
      } else {
        this.completeState(state);
      }
    }, 250);
  }
  get authors() {
    return store.getters.authors(this.platform);
  }
  get authorsCount() {
    return store.getters.totalNumberAuthors(this.platform);
  }
  select(option) {
    this.realValue = option;
    this.onChangeValue();
  }
  onChangeValue() {
    this.textSearched = this.realValue?.email;
    this.$emit('userChange', this.realValue);
    this.resetCurrentPage();
  }
  onClearSelection() {
    this.resetSearchInput();
  }
  onBlur() {
    this.textSearched = this.realValue?.email;
  }
  onChangePropValue() {
    this.setTextSearchedAndRealValue();
  }
  resetSearchInput() {
    this.realValue = null;
    this.textSearched = '';
    this.onChangeValue();
  }
  setTextSearchedAndRealValue() {
    this.realValue = this.value;
    this.textSearched = this.value?.email || '';
  }
  searchAuthors(query) {
    if (query || this.textSearched !== this.realValue?.email) {
      this.textSearched = query;
    }
    this.resetCurrentPage();
    this.resetAuthorsList();
    this.refreshInfiniteLoaderId();
    this.infiniteSearchAuthors(this.currentPage);
  }
  refreshInfiniteLoaderId() {
    this.infiniteLoaderID = uuidv4();
  }
  infiniteSearchAuthors(page, append, state) {
    this.isFetching = true;
    this.searchAuthorsImpl(page, append).then(() => {
      if (state) {
        if (this.authorsCount > this.authors.length) {
          state.loaded();
        } else {
          this.completeState(state);
        }
      }
    }).catch(() => {
      this.completeState(state);
    }).finally(() => this.isFetching = false);
  }
  completeState(state) {
    state?.complete();
    this.resetCurrentPage();
  }
  resetCurrentPage() {
    this.currentPage = 1;
  }
  searchAuthorsImpl(page = 1, append = false) {
    return store.dispatch(AUTHORS_LIST, {
      platformID: this.platform,
      author: this.textSearched,
      page,
      append
    });
  }
  resetAuthorsList() {
    store.dispatch(RESET_AUTHORS_LIST, this.platform);
  }
};
__decorate([Prop({
  default: ''
})], SearchUser.prototype, "platform", void 0);
__decorate([Prop({
  default: null
})], SearchUser.prototype, "value", void 0);
__decorate([Prop({
  default: "User email"
})], SearchUser.prototype, "placeholder", void 0);
__decorate([Watch('value')], SearchUser.prototype, "onChangePropValue", null);
__decorate([Watch('platform')], SearchUser.prototype, "resetSearchInput", null);
SearchUser = __decorate([Options({
  components: {
    UserCell,
    InfiniteLoading
  }
})], SearchUser);
export default SearchUser;