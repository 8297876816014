import {RightManagement} from '@/store/models/rightManagement';


export class User {
    public login: string;
    public contactEmail: string;
    public firstName: string;
    public lastName: string;
    public userID: string;
    public expiredPassword: string;
    public rightManagement: RightManagement[];

    constructor(login: string, firstName: string, lastName: string, contactEmail: string, userID: string) {
        this.login = login;
        this.firstName = firstName;
        this.lastName = lastName;
        this.userID = userID;
        this.contactEmail = contactEmail;
    }
}
