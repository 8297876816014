import { __decorate } from "tslib";
import { Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import store from '@/store';
import { MENU_VISIBLE } from '@/store/actions/configurations';
export default class Menu extends Vue {
  get isCopySpeachEnabled() {
    return store.getters.isCopySpeachEnabled;
  }
  get isAuthItem() {
    return store.getters.isAuthItemAvailable;
  }
  get isUnifiedStatisticsEnabled() {
    return store.getters.isUnifiedStatisticsEnabled;
  }
  mustChangeMenuVisibility(route) {
    if (window.innerWidth < 720 && store.getters.isMenuVisible) {
      store.dispatch(MENU_VISIBLE, false);
    }
  }
}
__decorate([Watch('$route', {
  immediate: false,
  deep: true
})], Menu.prototype, "mustChangeMenuVisibility", null);