import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import Card from '@/components/layout/Card.vue';
import { AUTH_LINK_DELETE, AUTH_LINK_LIST } from '@/store/actions/authLink';
import store from '@/store';
import AuthLinkForm from "@/views/authLink/AuthLinkForm.vue";
import AuthLinkDeleteDialog from "@/components/dialog/AuthLinkDeleteDialog.vue";
let AuthLinkTable = class AuthLinkTable extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "errorMsg", '');
    _defineProperty(this, "loading", false);
    _defineProperty(this, "toggleAuthLinkFormDialog", false);
    _defineProperty(this, "toggleAuthLinkDeleteDialog", false);
    _defineProperty(this, "authLinkIdToDelete", null);
    _defineProperty(this, "authLinkIdToUpdate", null);
  }
  get authLinks() {
    return Object.values(store.getters.authLinks);
  }
  get isDeleteAuthLink() {
    return store.getters.isAuthLinkDeleteAivailable;
  }
  mounted() {
    this.loadAuthLink();
  }
  /**
   * Edit the current authentication link
   */
  updateAuthLink(authLink) {
    this.authLinkIdToUpdate = authLink.authLinkID;
    // since 'authLinkIdToUpdate' and 'toggleAuthLinkFormDialog' are both inputs of the authLink edit modal,
    // we need to wait a tick.., to ensure authLinkIdToUpdate is well provided before we open the modal.
    setTimeout(() => {
      this.toggleAuthLinkFormDialog = true;
    });
  }
  loadAuthLink() {
    this.loading = true;
    store.dispatch(AUTH_LINK_LIST).then(() => {
      this.loading = false;
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
  deleteAuthLink(authLink) {
    this.authLinkIdToDelete = authLink.authLinkID;
    this.toggleAuthLinkDeleteDialog = true;
  }
  confirmAuthLinkDelete() {
    this.toggleAuthLinkDeleteDialog = false;
    this.loading = true;
    store.dispatch(AUTH_LINK_DELETE, this.authLinkIdToDelete).then(() => {
      this.loading = false;
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
};
AuthLinkTable = __decorate([Options({
  components: {
    Card,
    AuthLinkForm,
    AuthLinkDeleteDialog
  }
})], AuthLinkTable);
export default AuthLinkTable;