import axios from 'axios';
import {BACK_ENDPOINT} from '@/store/actions/api';
import {
    RESET_USER_STORE,
    USER_ADD,
    USER_CREATE_PASSWORD,
    USER_DELETE,
    USER_LIST,
    USER_UPDATE,
} from '@/store/actions/user';
import {User} from '@/store/models/user';
import {AUTH_SUCCESS} from '@/store/actions/auth';
import {IPageAndSizeCriteria} from "@/interfaces/IPageAndSizeCriteria";

const userAPI = BACK_ENDPOINT + '/users';

function buildUsersSearchUrl(criteria: IPageAndSizeCriteria): string {
    const page: number = criteria?.page || 0;
    let url: string = `${userAPI}?page=${page}`;
    if (criteria?.pageSize) {
        url += `&size=${criteria?.pageSize}`;
    }
    return url;
}

export default {
    state: {
        users: {},
    },
    mutations: {
        [USER_LIST]: (state: any, users: User[]) => {
            let u: User;
            state.users = {};
            for (const i in users) {
                if (users[i] !== undefined) {
                    u = users[i];
                    state.users[u.userID] = u;
                }
            }
        },
        [USER_ADD]: (state: any, user: User) => {
            state.users[user.userID] = user;
        },
        [USER_UPDATE]: (state: any, user: User) => {
            state.users[user.userID] = user;
        },
        [USER_DELETE]: (state: any, user: User) => {
            delete state.users[user.userID];
        },

        [RESET_USER_STORE]: (state: any) => {
            state.users = {};
        },
    },
    getters: {
        user: (state: any) => (userID: string) => {
            return state.users[userID];
        },
        users: (state: any) => {
            return state.users;
        },
    },
    actions: {

        async [USER_LIST]({
                              commit,
                              dispatch
                          }: { commit: any, dispatch: any }, searchCriteria?: IPageAndSizeCriteria) {
            await axios({
                url: buildUsersSearchUrl(searchCriteria),
                method: 'GET',
            }).then((resp) => {
                commit(USER_LIST, resp.data);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [USER_ADD]({commit, dispatch}: { commit: any, dispatch: any },
                         {user, tmpPassword}: { user: User, tmpPassword: string }) {
            const response = await axios({
                url: userAPI,
                data: {
                    login: user.login,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    contactEmail: user.contactEmail,
                    password: tmpPassword,
                },
                method: 'POST',
            }).then((resp) => {
                commit(USER_ADD, resp.data);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [USER_UPDATE]({commit, dispatch}: { commit: any, dispatch: any },
                            {user, tmpPassword}: { user: User, tmpPassword: string }) {
            await axios({
                url: `${userAPI}/${user.userID}`,
                data: {
                    login: user.login,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    contactEmail: user.contactEmail,
                    password: tmpPassword,
                },
                method: 'PUT',
            }).then((resp) => {
                commit(USER_UPDATE, resp.data);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [USER_DELETE]({commit, dispatch}: { commit: any, dispatch: any },
                            user: User) {
            return axios({
                url: `${userAPI}/${user.userID}`,
                method: 'DELETE',
            }).then((resp) => {
                commit(USER_DELETE, user);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [USER_CREATE_PASSWORD]({commit, dispatch}: { commit: any, dispatch: any },
                                     {password, repassword, user}:
                                         { password: string, repassword: string, user: User }) {
            const response = await axios({
                url: `${userAPI}/newPassword`,
                data: {password, repassword},
                method: 'POST',
            }).then((resp) => {
                const token = resp.data.token;
                // commit token
                commit(AUTH_SUCCESS, {token, user: resp.data.user});
            }).catch((err) => {

                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
    },
};
