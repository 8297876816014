import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import MainTitle from '@/components/title/MainTitle.vue';
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import CenterTop from '@/components/layout/CenterTop.vue';
import Footer from '@/components/layout/Footer.vue';
import store from '@/store';
import GridItem from '@/components/layout/GridItem.vue';
import { router } from "@/router";
import GridLayout from '@/components/layout/GridLayout.vue';
import Separator from '@/components/layout/Separator.vue';
import { SETTINGS_LIST, SLIDEDECK_BACKGROUND } from '@/store/actions/platforms';
import SettingItem from '@/components/platform/SettingItem.vue';
import ColorPickerBoard from '@/components/color-picker/ColorPickerBoard.vue';
import SlideDeckBackgroundItem from '@/components/platform/SlideDeckBackgroundItem.vue';
import { SettingKey } from '@/store/enums/settingKeyEnum';
let SettingsPlatform = class SettingsPlatform extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "platformID", void 0);
    _defineProperty(this, "loading", true);
    _defineProperty(this, "errorMsg", '');
  }
  get workflowKey() {
    return SettingKey.WORKFLOW_ENABLED;
  }
  get transcriptKey() {
    return SettingKey.TRANSCRIPT_ENABLED;
  }
  get userSearchKey() {
    return SettingKey.USER_SEARCH_ENGINE;
  }
  get subtitleAutoDisplayKey() {
    return SettingKey.SUBTITLE_AUTO_DISPLAY_IN_VIDEO;
  }
  get videoDownload() {
    return SettingKey.VIDEO_DOWNLOAD_ENABLED;
  }
  get pdfDownload() {
    return SettingKey.PDF_DOWNLOAD_ENABLED;
  }
  get autoNextKey() {
    return SettingKey.AUTO_NEXT_SECTION;
  }
  get defaultSDKey() {
    return SettingKey.VIDEO_DEFAULT_SD;
  }
  get workflowMandatoryKey() {
    return SettingKey.WORKFLOW_MANDATORY;
  }
  get publishAdminKey() {
    return SettingKey.ALLOW_PUBLISH_PUBLICATION;
  }
  get whatchAnonymousKey() {
    return SettingKey.ALLOW_ANONYMOUS;
  }
  get allowCreateAccountKey() {
    return SettingKey.ALLOW_ACCOUNT_CREATION;
  }
  get allowSearchEngineKey() {
    return SettingKey.SEARCH_ENGINE_INDEXATION;
  }
  get newUserIsFormateurKey() {
    return SettingKey.NEW_USER_IS_FORMATEUR;
  }
  get searchContentKey() {
    return SettingKey.INDEX_ALL_CONTENT;
  }
  get commentEnabledKey() {
    return SettingKey.COMMENT_ENABLED;
  }
  get supportBubbleEnabled() {
    return SettingKey.SUPPORT_BUBBLE;
  }
  get activatePartageKey() {
    return SettingKey.ACTIVATE_PARTAGE;
  }
  get slideDeckSettingReady() {
    return store.getters.settings(this.platformID) !== undefined;
  }
  get platform() {
    const platform = store.getters.platform(this.platformID);
    if (platform === undefined) {
      router.push('/');
    } else {
      return platform;
    }
  }
  get slideDeckSetting() {
    const SDsetting = store.getters.slideDeckSetting(this.platformID);
    return SDsetting;
  }
  mounted() {
    if (this.isSettingAvailable) {
      this.loadSettingForPlatform();
    }
  }
  slideDeckBackground(position) {
    const SDsetting = this.slideDeckSetting;
    if (SDsetting === undefined) {
      return undefined;
    }
    let sdBackground;
    SDsetting.slideDeckBackground.forEach(element => {
      if (position === element.position) {
        sdBackground = element;
      }
    });
    return sdBackground;
  }
  loadSettingForPlatform() {
    this.errorMsg = '';
    this.loading = true;
    store.dispatch(SETTINGS_LIST, this.platform).then(() => {
      store.dispatch(SLIDEDECK_BACKGROUND, this.platform).then(() => {
        this.loading = false;
        this.errorMsg = '';
      }).catch(err => {
        this.loading = false;
        this.errorMsg = err;
      });
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
  get isSettingAvailable() {
    return store.getters.isPlatformSettingsAvailable;
  }
};
__decorate([Prop()], SettingsPlatform.prototype, "platformID", void 0);
SettingsPlatform = __decorate([Options({
  components: {
    SlideDeckBackgroundItem,
    ColorPickerBoard,
    SettingItem,
    Separator,
    GridLayout,
    GridItem,
    Footer,
    CenterTop,
    MainTitle
  }
})], SettingsPlatform);
export default SettingsPlatform;