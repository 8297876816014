import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Banner = _resolveComponent("Banner");
  const _component_w_search_bar = _resolveComponent("w-search-bar");
  const _component_platform_card_selectable = _resolveComponent("platform-card-selectable");
  const _component_grid_item = _resolveComponent("grid-item");
  const _component_grid_square_layout = _resolveComponent("grid-square-layout");
  const _component_alert_dialog = _resolveComponent("alert-dialog");
  return _openBlock(), _createElementBlock("div", null, [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)]),
    _: 1
  })) : _createCommentVNode("", true), _createVNode(_component_w_search_bar, {
    class: "search-platforms-bar",
    placeholder: _ctx.$t('copy_speaches.search.search_platform_placeholder').toString(),
    width: "100%",
    height: "40px",
    onOnInputUpdate: _ctx.onSearchInputUpdate,
    value: _ctx.platformFilter
  }, null, 8, ["placeholder", "onOnInputUpdate", "value"]), _createVNode(_component_grid_square_layout, null, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.platforms, platform => {
      return _openBlock(), _createBlock(_component_grid_item, {
        key: platform.id
      }, {
        default: _withCtx(() => [_createVNode(_component_platform_card_selectable, {
          platform: platform,
          selected: _ctx.selectedPlatform?.platformID === platform.platformID,
          onClick: $event => _ctx.togglePlateforme(platform),
          disabled: _ctx.excludedPlatform === platform.platformID
        }, null, 8, ["platform", "selected", "onClick", "disabled"])]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  }), _ctx.copySpeachNotEligiblePlatform ? (_openBlock(), _createBlock(_component_alert_dialog, {
    key: 1,
    visible: !!_ctx.copySpeachNotEligiblePlatform,
    title: _ctx.$t('copy_speaches.copy_speaches_disabled_dialog.title').toString(),
    onDialogClose: _cache[0] || (_cache[0] = $event => _ctx.copySpeachNotEligiblePlatform = null)
  }, {
    default: _withCtx(() => [_createElementVNode("p", null, _toDisplayString(_ctx.$t('copy_speaches.copy_speaches_disabled_dialog.message', {
      platformName: _ctx.copySpeachNotEligiblePlatform.name
    }).toString()), 1)]),
    _: 1
  }, 8, ["visible", "title"])) : _createCommentVNode("", true)]);
}