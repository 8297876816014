import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_total_summaries_item = _resolveComponent("total-summaries-item");
  const _component_GridItem = _resolveComponent("GridItem");
  const _component_GridLayout = _resolveComponent("GridLayout");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_GridLayout, {
    class: "totalContainer"
  }, {
    default: _withCtx(() => [_createVNode(_component_GridItem, null, {
      default: _withCtx(() => [_createVNode(_component_total_summaries_item, {
        title: _ctx.$t('total_summaries.total_speaches'),
        total: _ctx.nbSpeachPublish
      }, null, 8, ["title", "total"])]),
      _: 1
    }), _createVNode(_component_GridItem, null, {
      default: _withCtx(() => [_createVNode(_component_total_summaries_item, {
        title: _ctx.$t('total_summaries.by_week'),
        total: _ctx.nbNewSpeachWeek
      }, null, 8, ["title", "total"])]),
      _: 1
    }), _createVNode(_component_GridItem, null, {
      default: _withCtx(() => [_createVNode(_component_total_summaries_item, {
        title: _ctx.$t('total_summaries.by_month'),
        total: _ctx.nbNewSpeachMonth
      }, null, 8, ["title", "total"])]),
      _: 1
    }), _createVNode(_component_GridItem, null, {
      default: _withCtx(() => [_createVNode(_component_total_summaries_item, {
        title: _ctx.$t('total_summaries.speaches_on_pending_validation'),
        total: _ctx.nbSpeachesToValidate
      }, null, 8, ["title", "total"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_GridLayout, {
    class: "totalContainer"
  }, {
    default: _withCtx(() => [_createVNode(_component_GridItem, null, {
      default: _withCtx(() => [_createVNode(_component_total_summaries_item, {
        title: _ctx.$t('total_summaries.users'),
        total: _ctx.nbUser
      }, null, 8, ["title", "total"])]),
      _: 1
    }), _createVNode(_component_GridItem, null, {
      default: _withCtx(() => [_createVNode(_component_total_summaries_item, {
        title: _ctx.$t('total_summaries.total_speachmakers'),
        total: _ctx.nbSpeachMaker
      }, null, 8, ["title", "total"])]),
      _: 1
    }), _createVNode(_component_GridItem, null, {
      default: _withCtx(() => [_createVNode(_component_total_summaries_item, {
        title: _ctx.$t('total_summaries.administrators'),
        total: _ctx.nbAdmins
      }, null, 8, ["title", "total"])]),
      _: 1
    }), _createVNode(_component_GridItem, null, {
      default: _withCtx(() => [_createVNode(_component_total_summaries_item, {
        title: _ctx.$t('total_summaries.total_users_connected'),
        total: _ctx.nbUserConnected
      }, null, 8, ["title", "total"])]),
      _: 1
    })]),
    _: 1
  })]);
}