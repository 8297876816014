import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import CenterTop from '@/components/layout/CenterTop.vue';
import MainTitle from '@/components/title/MainTitle.vue';
import PlatformPreview from '@/components/platform/PlatformPreview.vue';
import GridLayout from '@/components/layout/GridLayout.vue';
import GridItem from '@/components/layout/GridItem.vue';
import Card from '@/components/layout/Card.vue';
import { PLATFORM_SUMMARY, SEARCH_PLATFORMS } from '@/store/actions/platforms';
import store from '@/store';
import TotalSummaries from '@/components/platform/TotalSummaries.vue';
import PlatformsTable from '@/components/platform/PlatformsTable.vue';
import { HOME_PAGE_SIMPLE_VIEW } from '@/store/actions/configurations';
import WStandardButton from '@/components/wrapper/w-standard-button.vue';
import { PlatformsSortEnum } from "@/store/enums/PlatformsSortEnum";
import { SortOrderEnum } from "@/store/enums/SortOrderEnum";
import AddPlatform from "@/views/enroll/AddPlatform.vue";
import CreatePlatform from "@/views/platform/CreatePlatform.vue";
import debounce from "lodash/debounce";
import { v4 as uuidv4 } from "uuid";
import StandardNotificationDialog from "@/components/dialog/StandardNotificationDialog.vue";
import MessagesUtil from '@/utils/messagesUtil';
import InfiniteLoading from 'vue-infinite-loading';
let HomeView = class HomeView extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "errorMsg", '');
    _defineProperty(this, "pfLoading", {});
    _defineProperty(this, "pfErrorMsg", {});
    _defineProperty(this, "pageSize", 51);
    _defineProperty(this, "currentPlatformSearchValue", void 0);
    _defineProperty(this, "currentSortBy", PlatformsSortEnum.NAME);
    _defineProperty(this, "currentSortOrder", SortOrderEnum.ASC);
    _defineProperty(this, "toggleConnectPlatformDialog", false);
    _defineProperty(this, "togglePlatformCreationDialog", false);
    _defineProperty(this, "togglePlatformConnectionSuggestDialog", false);
    _defineProperty(this, "infiniteLoaderID", uuidv4());
    _defineProperty(this, "debounceInfinite", debounce(state => {
      this.searchPlatforms(this.platformsCurrentPage + 1, state, true);
    }, 250));
  }
  mounted() {
    this.checkNewEnrolledPlatform();
    this.listPlatforms();
  }
  get isPlatformConnect() {
    return store.getters.isPlatformConnectAvailable;
  }
  get platforms() {
    return store.getters.platforms;
  }
  get platformsCurrentPage() {
    return store.getters.platformsCurrentPage;
  }
  get totalNumberPlatforms() {
    return store.getters.totalNumberPlatforms;
  }
  get totalPlatformsOnCurrentPage() {
    return store.getters.totalPlatformsOnCurrentPage;
  }
  get summaries() {
    return store.getters.summaries;
  }
  get isPlatformCreator() {
    return store.getters.isPlatformCreatorAvailable;
  }
  checkNewEnrolledPlatform() {
    const newEnrolledPlatform = this.$route.query?.enrolledPlatform?.toString();
    if (newEnrolledPlatform) {
      MessagesUtil.showSuccessMessage(this.$t('home.platform_connected_toast.message', {
        platformUrl: newEnrolledPlatform
      }).toString(), 3000);
      window.history.replaceState(null, null, this.$route.path); // remove query args from url
    }
  }
  listPlatforms() {
    this.resetErrorMsg();
    store.dispatch(SEARCH_PLATFORMS, this.buildPlatformsSearchCriteria(0)).then(() => {
      if (this.shouldSuggestPlatformConnection()) {
        this.togglePlatformConnectionSuggestDialog = true;
      } else {
        this.listSummary();
      }
    }).catch(err => {
      this.setErrorMsg(err);
    });
  }
  shouldSuggestPlatformConnection() {
    return Object.values(this.platforms).length === 0 && this.isPlatformConnect;
  }
  setErrorMsg(errorMsg) {
    this.errorMsg = errorMsg;
  }
  resetErrorMsg() {
    this.errorMsg = '';
  }
  get isHomepageSimpleview() {
    return store.getters.isHomepageSimpleview;
  }
  toggleSimpleView() {
    store.dispatch(HOME_PAGE_SIMPLE_VIEW, !store.getters.isHomepageSimpleview);
  }
  async listSummary(append) {
    if (!append) {
      // we find summaries for all the platforms
      for (let platform of Object.values(this.platforms)) {
        await this.summary(platform);
      }
    } else {
      // we find summaries for only new added platforms
      for (let p of Object.values(this.platforms)) {
        const platform = p;
        const platformSummaryDontExist = !Object.entries(this.summaries).filter(entry => entry[0] === platform.platformID)[0];
        if (platformSummaryDontExist) {
          await this.summary(platform);
        }
      }
    }
  }
  async summary(platform) {
    this.pfErrorMsg[platform.platformID] = '';
    try {
      await store.dispatch(PLATFORM_SUMMARY, platform);
      this.pfLoading[platform.platformID] = false;
      this.pfErrorMsg[platform.platformID] = '';
    } catch (err) {
      this.pfLoading[platform.platformID] = false;
      this.pfErrorMsg[platform.platformID] = err;
    }
  }
  onSearchInputUpdate(searchValue) {
    this.resetInfiniteHandler();
    this.currentPlatformSearchValue = searchValue;
    this.searchPlatforms(0);
  }
  onSortChanges(sortData) {
    if (sortData) {
      this.currentSortBy = PlatformsSortEnum[sortData.sortBy];
      this.currentSortOrder = SortOrderEnum[sortData.sortOrder];
      if (this.currentSortBy && this.currentSortOrder) {
        this.resetInfiniteHandler();
        this.searchPlatforms(0);
      }
    }
  }
  searchPlatforms(pageNumber, state, append) {
    this.resetErrorMsg();
    store.dispatch(SEARCH_PLATFORMS, this.buildPlatformsSearchCriteria(pageNumber, append)).then(() => {
      this.listSummary(append);
      if (state) {
        //if we don't append the search, it is a new one, so we have to reset the infinite handler
        if (!append) {
          this.resetInfiniteHandler();
        }
        if (this.totalNumberPlatforms <= Object.values(this.platforms).length || !this.totalPlatformsOnCurrentPage) {
          state.complete();
        } else {
          state.loaded();
        }
      }
    }).catch(err => {
      this.setErrorMsg(err);
      state?.complete();
    });
  }
  buildPlatformsSearchCriteria(pageNumber, appendResultInStore) {
    return {
      page: pageNumber,
      pageSize: this.pageSize,
      platformName: this.currentPlatformSearchValue || '',
      sortBy: this.currentSortBy,
      sortOrder: this.currentSortOrder,
      appendResultInStore: appendResultInStore
    };
  }
  onUserAcceptToConnectPlatform() {
    this.togglePlatformConnectionSuggestDialog = false;
    this.toggleConnectPlatformDialog = true;
  }
  //We need to reset the infinite handler when we perform a new search.
  resetInfiniteHandler() {
    //The easiest way to reset the infinite handler is to change its id
    this.infiniteLoaderID = uuidv4();
  }
};
HomeView = __decorate([Options({
  components: {
    StandardNotificationDialog,
    AddPlatform,
    CreatePlatform,
    WStandardButton,
    PlatformsTable,
    TotalSummaries,
    Card,
    GridItem,
    GridLayout,
    PlatformPreview,
    MainTitle,
    CenterTop,
    InfiniteLoading
  }
})], HomeView);
export default HomeView;