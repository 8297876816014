import {
    HOME_PAGE_SIMPLE_VIEW,
    MENU_VISIBLE,
    SETTING_SPE_UPDATE,
    SETTINGS_SPE_LIST,
} from '@/store/actions/configurations';
import axios from 'axios';
import {BACK_ENDPOINT} from '@/store/actions/api';
import {SettingSPEKey} from '@/store/enums/settingSPEKey';
import {RightManagementKey} from '@/store/enums/rightManagementEnum';
import {ISPESetting} from "@/store/models/settings";

const settingsSPEAPI = BACK_ENDPOINT + '/settings';

function getSettingValueGivenKey(state, key: SettingSPEKey): boolean {
    const setting: any = Object.values(state.speSettingsList)?.find((s: ISPESetting) => s.key === key);
    return setting?.value || false;
}

export default {
    state: {
        menuVisible: false,
        homepage_simpleview: true,
        speSettingsList: {},
    },
    mutations: {
        [MENU_VISIBLE]: (state: any, active: boolean) => {
            state.menuVisible = active === true;
        },
        [HOME_PAGE_SIMPLE_VIEW]: (state: any, active: boolean) => {
            state.homepage_simpleview = active === true;
        },
        [SETTINGS_SPE_LIST]: (state: any, speSettings: any[]) => {
            state.speSettingsList = {};
            for (let s of speSettings) {
                const setting: ISPESetting = {key: s.key, value: s.value === 'true'};
                state.speSettingsList[setting.key] = setting;
            }
        },
        [SETTING_SPE_UPDATE]: (state: any, speSetting: ISPESetting) => {
            state.speSettingsList[speSetting.key] = speSetting;
        },
    },
    getters: {
        isMenuVisible: (state: any): boolean => state.menuVisible,
        isHomepageSimpleview: (state: any): boolean => {
            return state.homepage_simpleview;
        },
        isPlatformConnectAvailable: (state: any, rootGetters: any): boolean => {
            return getSettingValueGivenKey(state, SettingSPEKey.PLATFORM_CONNEXION_ENABLED)
                && rootGetters.isUserAvailableTo(RightManagementKey.CONNECT_PLATFORM);
        },
        isPlatformCreatorAvailable: (state: any, rootGetters: any, rootState: any): boolean => {
            return getSettingValueGivenKey(state, SettingSPEKey.PLATFORM_CREATION_ENABLED)
                && Object.values(rootState.platforms).length > 0
                && rootGetters.isUserAvailableTo(RightManagementKey.CREATE_PLATFORM);
        },
        isCopySpeachEnabled: (state: any, rootGetters: any, rootState: any): boolean => {
            return getSettingValueGivenKey(state, SettingSPEKey.COPY_SPEACH_ENABLED)
                && Object.values(rootState.platforms).length > 1
                && rootGetters.isUserAvailableTo(RightManagementKey.COPY_SPEACH);
        },
        isAuthItemAvailable: (state: any, rootGetters: any, rootState: any): boolean => {
            return getSettingValueGivenKey(state, SettingSPEKey.AUTH_ITEM_ENABLED)
        },
        isDebugSSOActivated: (state: any, rootGetters: any, rootState: any): boolean => {
            return getSettingValueGivenKey(state, SettingSPEKey.DEBUG_SSO_ACTIVATED)
        },
        // (state, getters, rootState, rootGetters)
        isAddUserAvailable: (state: any, rootGetters: any, rootState: any): boolean => {
            return getSettingValueGivenKey(state, SettingSPEKey.ADD_USER_ENABLED) && rootGetters.isUserAvailableTo(RightManagementKey.CREATE_USER);
        },
        isDeleteUserAvailable: (state: any, rootGetters: any, rootState: any): boolean => {
            return rootGetters.isUserAvailableTo(RightManagementKey.DELETE_USER);
        },
        isRemoveConnexionAvailable: (state: any, rootGetters: any, rootState: any): boolean => {
            return rootGetters.isUserAvailableTo(RightManagementKey.DISCONNECT_PLATFORM);
        },
        isPlatformSettingsAvailable: (state: any, rootGetters: any, rootState: any): boolean => {
            return rootGetters.isUserAvailableTo(RightManagementKey.SETTING_PLATFORM);
        },
        isUpdateUserAivailable: (state: any, rootGetters: any, rootState: any): boolean => {
            return rootGetters.isUserAvailableTo(RightManagementKey.MODIFY_USER);
        },
        isAuthItemCreateAivailable: (state: any, rootGetters: any, rootState: any): boolean => {
            return rootGetters.isUserAvailableTo(RightManagementKey.CREATE_AUTHITEM);
        },
        isAuthItemUpdateAivailable: (state: any, rootGetters: any, rootState: any): boolean => {
            return rootGetters.isUserAvailableTo(RightManagementKey.UPDATE_AUTHITEM);
        },
        isAuthItemDeleteAivailable: (state: any, rootGetters: any, rootState: any): boolean => {
            return rootGetters.isUserAvailableTo(RightManagementKey.DELETE_AUTHITEM);
        },
        isAuthLinkCreateAivailable: (state: any, rootGetters: any, rootState: any): boolean => {
            return rootGetters.isUserAvailableTo(RightManagementKey.CREATE_AUTHLINK);
        },
        isAuthLinkDeleteAivailable: (state: any, rootGetters: any, rootState: any): boolean => {
            return rootGetters.isUserAvailableTo(RightManagementKey.DELETE_AUTHLINK);
        },
        isIPWhitelistingAvailable: (state: any, rootGetters: any, rootState: any): boolean => {
            return getSettingValueGivenKey(state, SettingSPEKey.IP_WHITELISTING_ENABLED);
        },
        isUnifiedStatisticsEnabled: (state: any, rootGetters: any, rootState: any): boolean => {
            return getSettingValueGivenKey(state, SettingSPEKey.UNIFIED_STATISTICS_ENABLED);
        },
        speSettingsData: (state: any) => {
            return Object.values(state.speSettingsList);
        },

    },
    actions: {
        [MENU_VISIBLE]({commit, dispatch}: { commit: any, dispatch: any }, active: boolean) {
            commit(MENU_VISIBLE, active);
        },
        [HOME_PAGE_SIMPLE_VIEW]({commit, dispatch}: { commit: any, dispatch: any }, active: boolean) {
            commit(HOME_PAGE_SIMPLE_VIEW, active);
        },
        async [SETTINGS_SPE_LIST]({commit}: { commit: any }) {
            await axios({
                url: settingsSPEAPI,
                method: 'GET',
            }).then((resp) => {
                const list = resp.data;
                if (list?.length) {
                    commit(SETTINGS_SPE_LIST, list);
                }
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [SETTING_SPE_UPDATE]({commit}: { commit: any }, setting: ISPESetting) {
            await axios({
                url: settingsSPEAPI,
                data: {key: setting.key, value: setting.value},
                method: 'PUT',
            }).then(() => commit(SETTING_SPE_UPDATE, setting)
            ).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        }
    },
};
