import {SettingSPEKey} from "@/store/enums/settingSPEKey";

export class Settings {
    public platformID: string;
    public PLATFORM_VERSION: string;
    public COLOR: string;
    public LOGO: string;
    public COMMENT_ENABLED: boolean;
    public WORKFLOW_ENABLED: boolean;
    public TRANSCRIPT_ENABLED: boolean;
    public USER_SEARCH_ENGINE: boolean;
    public VIDEO_DOWNLOAD_ENABLED: boolean;
    public PDF_DOWNLOAD_ENABLED: boolean;
    public NEW_USER_IS_FORMATEUR: boolean;
    public AUTO_NEXT_SECTION: boolean;
    public VIDEO_DEFAULT_SD: boolean;
    public SUPPORT_BUBBLE: boolean;
    public SUBTITLE_AUTO_DISPLAY_IN_VIDEO: boolean;
    public SEARCH_ENGINE_INDEXATION: boolean;
    public COPY_SPEACH_ENABLED: boolean;
    public ALLOW_ANONYMOUS: boolean;
    public ALLOW_ACCOUNT_CREATION: boolean;
    public WORKFLOW_MANDATORY: boolean;
    public INDEX_ALL_CONTENT: boolean;
    public ALLOW_PUBLISH_PUBLICATION: boolean;
    public ACTIVATE_PARTAGE: boolean;
}



export class SettingChangeParams {
    public platformID: string;
    public key: string;
    public value: any;
    constructor(platformID: string, key: string, value: any) {
        this.platformID = platformID;
        this.key = key;
        this.value = value;
    }
}

export class SlideDeckSetting {
    public platformID: string;
    public slideDeckBackground: SlideDeckBackground[];

    constructor(platformID: string, slideDeckBackground: SlideDeckBackground[]) {
        this.platformID = platformID;
        this.slideDeckBackground = slideDeckBackground;
    }
}
export class SlideDeckBackground {
    public url: string;
    public color: string;
    public position: number;

    constructor( url: string, color: string, position: number) {
        this.url = url;
        this.color = color;
        this.position = position;
    }
}

export interface ISPESetting {
    key: SettingSPEKey;
    value: boolean;
}
