import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
export default class MainTitle extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "title", void 0);
    _defineProperty(this, "icon", void 0);
    _defineProperty(this, "breadcrumbItems", void 0);
  }
  goTo(url) {
    window.open(url);
  }
}
__decorate([Prop({
  default: 'Your title'
})], MainTitle.prototype, "title", void 0);
__decorate([Prop()], MainTitle.prototype, "icon", void 0);
__decorate([Prop()], MainTitle.prototype, "breadcrumbItems", void 0);