import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-87c5d164"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "title-cell"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "title-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
    src: _ctx.image,
    class: "title-img"
  }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1)]);
}