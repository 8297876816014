import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1793222a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tableUserContainer"
};
const _hoisted_2 = {
  class: "user-name"
};
const _hoisted_3 = {
  class: "user-email"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = ["onClick", "disabled"];
const _hoisted_6 = {
  slot: "empty"
};
const _hoisted_7 = {
  class: "no-result-block"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Banner = _resolveComponent("Banner");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_UserRightManagementSummary = _resolveComponent("UserRightManagementSummary");
  const _component_w_icon_button = _resolveComponent("w-icon-button");
  const _component_w_icon = _resolveComponent("w-icon");
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  const _component_el_dropdown = _resolveComponent("el-dropdown");
  const _component_el_table = _resolveComponent("el-table");
  const _component_UserRightManagement = _resolveComponent("UserRightManagement");
  const _component_side_panel = _resolveComponent("side-panel");
  const _component_password_notification_dialog = _resolveComponent("password-notification-dialog");
  const _component_user_account_delete_dialog = _resolveComponent("user-account-delete-dialog");
  const _directive_click_outside = _resolveDirective("click-outside");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)]),
    _: 1
  })) : _createCommentVNode("", true), _createVNode(_component_el_table, {
    ref: "usersTable",
    data: _ctx.users,
    class: "users-table"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      label: _ctx.$t('users_management.users_table.name_col')
    }, {
      default: _withCtx(scope => [_createElementVNode("div", _hoisted_2, _toDisplayString(scope.row.firstName) + " " + _toDisplayString(scope.row.lastName), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(scope.row.contactEmail), 1)]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_table_column, {
      prop: "login",
      label: _ctx.$t('users_management.users_table.login_col')
    }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
      label: _ctx.$t('users_management.users_table.roles_col')
    }, {
      default: _withCtx(scope => [_createVNode(_component_UserRightManagementSummary, {
        user: scope.row
      }, null, 8, ["user"])]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_table_column, {
      label: "",
      width: "100px"
    }, {
      default: _withCtx(scope => [_createVNode(_component_w_icon_button, {
        iconClass: "pencil-outline",
        class: "action-btn rights-management-toggler-btn",
        onClick: $event => _ctx.selectUser(scope.row)
      }, null, 8, ["onClick"]), _createVNode(_component_el_dropdown, {
        placement: "bottom-end",
        trigger: "click",
        size: "large"
      }, {
        dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, {
          class: "user-actions-dropdown"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_dropdown_item, null, {
            default: _withCtx(() => [_createElementVNode("span", {
              class: "dropdown-action-item reset-password-action",
              onClick: $event => _ctx.resetPassword(scope.row)
            }, [_createVNode(_component_w_icon, {
              icon: "lock-reset",
              size: "22px"
            }), _createTextVNode(" " + _toDisplayString(_ctx.$t('users_management.users_table.actions_btns.password_reset')), 1)], 8, _hoisted_4)]),
            _: 2
          }, 1024), _createVNode(_component_el_dropdown_item, {
            divided: ""
          }, {
            default: _withCtx(() => [_createElementVNode("span", {
              class: "dropdown-action-item delete-action",
              onClick: $event => _ctx.deleteUser(scope.row),
              disabled: !_ctx.isUserDeleteAivailable
            }, [_createVNode(_component_w_icon, {
              icon: "delete-outline",
              size: "22px"
            }), _createTextVNode(" " + _toDisplayString(_ctx.$t('users_management.users_table.actions_btns.user_delete')), 1)], 8, _hoisted_5)]),
            _: 2
          }, 1024)]),
          _: 2
        }, 1024)]),
        default: _withCtx(() => [_createVNode(_component_w_icon_button, {
          iconClass: "dots-horizontal",
          class: "action-btn user-actions-menu-btn"
        })]),
        _: 2
      }, 1024)]),
      _: 1
    }), _createElementVNode("template", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_w_icon, {
      icon: "emoticon-sad-outline"
    }), _createElementVNode("span", null, _toDisplayString(_ctx.$t('users_management.users_table.empty_result_msg')), 1)])])]),
    _: 1
  }, 8, ["data"]), this.selectedUser ? _withDirectives((_openBlock(), _createBlock(_component_side_panel, {
    key: 1,
    visible: _ctx.sidePanelVisible,
    title: `${this.selectedUser.firstName} ${this.selectedUser.lastName}`,
    class: "sidePanelContainer",
    onClose: _ctx.closeSidePanel
  }, {
    default: _withCtx(() => [_createVNode(_component_UserRightManagement, {
      user: this.selectedUser
    }, null, 8, ["user"])]),
    _: 1
  }, 8, ["visible", "title", "onClose"])), [[_directive_click_outside, _ctx.closeSidePanel]]) : _createCommentVNode("", true), _createVNode(_component_password_notification_dialog, {
    "user-first-name": _ctx.passwordRequestedFor?.firstName,
    "password-url": _ctx.ressettedPasswordUrl,
    "toggle-dialog": _ctx.toggleUserPwdResetDialog,
    onDialogClose: _cache[0] || (_cache[0] = $event => _ctx.toggleUserPwdResetDialog = false)
  }, null, 8, ["user-first-name", "password-url", "toggle-dialog"]), _createVNode(_component_user_account_delete_dialog, {
    "toggle-dialog": _ctx.toggleAccountDeleteDialog,
    onDialogClose: _cache[1] || (_cache[1] = $event => _ctx.toggleAccountDeleteDialog = false),
    onDeleteConfirm: _ctx.confirmAccountDelete
  }, null, 8, ["toggle-dialog", "onDeleteConfirm"])]);
}