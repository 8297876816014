import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-39e26d62"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["element-loading-text"];
const _hoisted_2 = ["action"];
const _hoisted_3 = {
  ref: "tokenToSend",
  type: "hidden",
  name: "token"
};
const _hoisted_4 = ["value"];
const _hoisted_5 = ["value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Banner = _resolveComponent("Banner");
  const _component_CenterTop = _resolveComponent("CenterTop");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createBlock(_component_CenterTop, {
    logo: require('@/assets/theme/connected.png')
  }, {
    default: _withCtx(() => [_withDirectives((_openBlock(), _createElementBlock("div", {
      class: "main",
      "element-loading-text": _ctx.redirectMessage
    }, [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
      key: 0,
      type: "error"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)]),
      _: 1
    })) : _createCommentVNode("", true), _ctx.authLink ? (_openBlock(), _createElementBlock("form", {
      key: 1,
      ref: "formPost",
      action: _ctx.originalPlatform + _ctx.asc,
      method: "post"
    }, [_createElementVNode("input", _hoisted_3, null, 512), _createElementVNode("input", {
      type: "hidden",
      name: "authLinkID",
      value: _ctx.authLink.authLinkID
    }, null, 8, _hoisted_4), _createElementVNode("input", {
      type: "hidden",
      name: "otherPlatformsAvailable",
      value: _ctx.otherPlatformsAvailable
    }, null, 8, _hoisted_5)], 8, _hoisted_2)) : _createCommentVNode("", true)], 8, _hoisted_1)), [[_directive_loading, _ctx.loading]])]),
    _: 1
  }, 8, ["logo"]);
}