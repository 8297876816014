import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import TotalSummariesItem from "@/components/platform/TotalSummariesItem.vue";
let TotalSummaries = class TotalSummaries extends Vue {
  get nbSpeachPublish() {
    return this.sumSummariesGivenDataAttribute('nbSpeachPublish');
  }
  get nbNewSpeachWeek() {
    return this.sumSummariesGivenDataAttribute('nbNewSpeachWeek');
  }
  get nbNewSpeachMonth() {
    return this.sumSummariesGivenDataAttribute('nbNewSpeachMonth');
  }
  get nbSpeachesToValidate() {
    return this.sumSummariesGivenDataAttribute('nbSpeachToValidate');
  }
  get nbUser() {
    return this.sumSummariesGivenDataAttribute('nbUser');
  }
  get nbSpeachMaker() {
    return this.sumSummariesGivenDataAttribute('nbSpeachMaker');
  }
  get nbAdmins() {
    return this.sumSummariesGivenDataAttribute('nbAdmin');
  }
  get nbUserConnected() {
    return this.sumSummariesGivenDataAttribute('nbConnectedMonth');
  }
  sumSummariesGivenDataAttribute(attribute) {
    const summaries = store.getters.summaries;
    let total = 0;
    for (const value in summaries) {
      if (summaries[value] !== undefined) {
        const summary = summaries[value];
        total += Number(summary[attribute]);
      }
    }
    return total;
  }
};
TotalSummaries = __decorate([Options({
  components: {
    TotalSummariesItem
  }
})], TotalSummaries);
export default TotalSummaries;