import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import store from '@/store';
import { ENROLL_PLATFORM } from '@/store/actions/platforms';
import WTextInput from "@/components/wrapper/w-text-input.vue";
import { CLUSTER_DOMAIN } from '@/store/actions/api';
let AddPlatform = class AddPlatform extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "toggleConnectPlatformDialog", void 0);
    _defineProperty(this, "url", '');
    _defineProperty(this, "errorMsg", '');
    _defineProperty(this, "isLoading", false);
    _defineProperty(this, "visible", false);
  }
  updateUrl(value) {
    this.url = value;
  }
  addPlatform() {
    this.errorMsg = '';
    this.isLoading = true;
    this.url = this.url?.trim();
    if (this.url) {
      if (!this.url.startsWith('https://') && !this.url.startsWith('http://')) {
        this.url = 'https://' + this.url;
      }
      store.dispatch(ENROLL_PLATFORM, this.url).then(() => {
        // it will be redirect if ok
      }).catch(err => {
        this.isLoading = false;
        this.errorMsg = err;
      });
    }
  }
  onDialogClose() {
    this.visible = false;
    this.errorMsg = '';
    this.$emit("dialogClose");
  }
};
__decorate([Prop({
  default: false
})], AddPlatform.prototype, "toggleConnectPlatformDialog", void 0);
AddPlatform = __decorate([Options({
  components: {
    WTextInput
  },
  data() {
    return {
      CLUSTER_DOMAIN
    };
  },
  watch: {
    toggleConnectPlatformDialog() {
      this.visible = this.toggleConnectPlatformDialog;
    }
  }
})], AddPlatform);
export default AddPlatform;