import {
    AUTH_ERROR,
    AUTH_LOGOUT,
    AUTH_REQUEST,
    AUTH_SUCCESS,
    AuthParams,
    RESET_AUTH_STORE,
    UPDATE_RIGHTMANGEMENT_GROUP_USER_CONNECTE,
    UPDATE_RIGHTMANGEMENT_USER_CONNECTE,
} from '@/store/actions/auth';
import axios from 'axios';
import {BACK_ENDPOINT} from '@/store/actions/api';
import {User} from '@/store/models/user';
import {RESET_USER_STORE} from '@/store/actions/user';
import {RESET_STORE_PLATFORM} from '@/store/actions/platforms';

const loginAPI = BACK_ENDPOINT + '/login';

export default {
    state: {
        token:  '',
        user: {},
        status: '',
    },
    mutations: {
        [AUTH_REQUEST]: (state: any) => {
            state.status = 'loading';
        },
        [AUTH_SUCCESS]: (state: any, {token, user}: {token: string, user: User}) => {
            state.status = 'success';
            state.token = token;
            state.user = user;
        },
        [AUTH_ERROR]: (state: any) => {
            state.status = 'error';
        },
        [RESET_AUTH_STORE]: (state: any) => {
            state.status = '';
            state.user = {};
            state.token = '';
        },
        [UPDATE_RIGHTMANGEMENT_USER_CONNECTE]: ( state: any, param: any ) => {
             const userConnected: User = state.user;
             const user: User = param.user;
            // // we update the user connected if need
             if (userConnected.userID === user.userID ) {
                for (const rmUser of userConnected.rightManagement) {
                    if (param.rightManagement.key === rmUser.key) {
                        rmUser.value = param.rightManagement.value;
                    }
                }
            }
        },
        [UPDATE_RIGHTMANGEMENT_GROUP_USER_CONNECTE]: (state: any, param: any) => {
            const userConnected: User = state.user;
            const user: User = param.user;

            if (userConnected.userID === user.userID ) {
                for (const rm of param.rightManagement) {
                    // we search the good key in the user
                    for (const rmUser of userConnected.rightManagement) {
                        if (rm.key === rmUser.key) {
                            rmUser.value = rm.value;
                        }
                    }
                }
            }
        }

    },
    getters: {
        token: (state: any) => state.token,
        userConnected: (state: any) => state.user,
        isAuthenticated: (state: any) => !!state.token,
        authStatus: (state: any) => state.status,
    },
    actions: {
        async [AUTH_REQUEST]({commit, dispatch}: { commit: any, dispatch: any }, authParams: AuthParams) {

            commit(AUTH_REQUEST);

            const response = await axios({
                url: loginAPI,
                data: authParams,
                method: 'POST',
            }).then((resp) => {
                    const token = resp.data.token;
                    // commit token
                    commit(AUTH_SUCCESS, {token, user: resp.data.user});
                },
            ).catch((err) => {
                commit(AUTH_ERROR);
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });

        },
        async [AUTH_LOGOUT]({commit}: { commit: any }) {
            commit(RESET_AUTH_STORE);
            commit(RESET_STORE_PLATFORM);
            commit(RESET_USER_STORE);
        },
    },
};
