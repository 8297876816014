import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5cdfd4d3"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "authItemContainer"
};
const _hoisted_2 = {
  class: "titleAuthItem"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Banner = _resolveComponent("Banner");
  const _component_w_icon = _resolveComponent("w-icon");
  const _component_CardInteractive = _resolveComponent("CardInteractive");
  return _openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.createAuthRequest && _ctx.createAuthRequest(...args))
  }, [_createVNode(_component_CardInteractive, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_Banner, {
      key: 0,
      type: "error"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)]),
      _: 1
    })) : _createCommentVNode("", true), _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.authItem.name), 1), _createElementVNode("p", null, [_createVNode(_component_w_icon, {
      icon: "account-group",
      size: "48px",
      class: "auth-item-icon"
    })])])]),
    _: 1
  })]);
}