import axios from 'axios';
import {BACK_ENDPOINT} from '@/store/actions/api';
import {
    AUTH_LINK_ADD,
    AUTH_LINK_AUTH_GENERATE,
    AUTH_LINK_DELETE,
    AUTH_LINK_EMPTY_LIST,
    AUTH_LINK_LIST,
    AUTH_LINK_UPDATE,
    AUTH_LINK_WITH_AUTHITEM,
} from '@/store/actions/authLink';
import {AuthLink} from '@/store/models/authLink';
import {IPageAndSizeCriteria} from "@/interfaces/IPageAndSizeCriteria";


const authLinkAPI = BACK_ENDPOINT + '/authLink';
const authLinkPublicAPI = authLinkAPI + '/public';
const authLinkPublicToken = authLinkPublicAPI + '/token/regenerate';

function buildAuthLinksSearchUrl(criteria: IPageAndSizeCriteria): string {
    const page: number = criteria?.page || 0;
    let url: string = `${authLinkAPI}?page=${page}`;
    if (criteria?.pageSize) {
        url += `&size=${criteria?.pageSize}`;
    }
    return url;
}

export default {
    state: {
        authLinks: {},
    },
    mutations: {
        [AUTH_LINK_LIST]: (state: any, authLink: AuthLink[]) => {
            let a: AuthLink;
            state.authLinks = {};
            for (const i in authLink) {
                if (authLink[i] !== undefined) {

                    a = authLink[i];
                    state.authLinks[a.authLinkID] = a;
                }
            }
        },
        [AUTH_LINK_ADD]: (state: any, authLink: AuthLink) => {
            state.authLinks[authLink.authLinkID] = authLink;
        },
        [AUTH_LINK_DELETE]: (state: any, authLinkId: string) => {
            delete state.authLinks[authLinkId];
        },
        [AUTH_LINK_UPDATE]: (state: any, authLink: AuthLink) => {
            state.authLinks[authLink.authLinkID] = authLink;
        },
        [AUTH_LINK_EMPTY_LIST]: (state: any) => {
            state.authLinks = {};
        },
    },
    getters: {
        authLink: (state: any) => (authLinkID: string) => {
            return state.authLinks[authLinkID];
        },
        authLinks: (state: any) => {
            return state.authLinks;
        },
    },
    actions: {
        /**
         * Get all the existing auth link
         * and update the state
         */
        async [AUTH_LINK_LIST]({commit, dispatch}: { commit: any, dispatch: any }, searchCriteria?: IPageAndSizeCriteria) {
            await axios({
                url: buildAuthLinksSearchUrl(searchCriteria),
                method: 'GET',
            }).then((resp) => {
                const list = resp.data;
                commit(AUTH_LINK_LIST, list);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        /**
         * Call the bach-end to get the list of Auth Link, link to the authItem in parameter
         * the token is used to verify the additional rules
         * and update the state
         */
        async [AUTH_LINK_WITH_AUTHITEM]({commit, dispatch}: { commit: any, dispatch: any },
                                        {authItemId, token}: {authItemId: string, token: string}) {
            return await axios({
                url: authLinkPublicAPI + '/' + authItemId,
                method: 'POST',
                data: {token},
            }).then((resp) => {
                const list = resp.data;
                commit(AUTH_LINK_LIST, list);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        /**
         * Save the auth link in parameter in the data base, and update the state
         */
        async [AUTH_LINK_ADD]({commit, dispatch}: { commit: any, dispatch: any }, postData: { platformID: string,
            authItemID: string, additionalRuleType: string, additionalRuleValue: string }) {
            return await axios({
                url: authLinkAPI, method: 'POST', data: postData,
            }).then((resp) => {
                const authLink = resp.data;
                commit(AUTH_LINK_ADD, authLink);
                return authLink;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [AUTH_LINK_DELETE]({commit, dispatch}: { commit: any, dispatch: any }, authLinkId: string) {
            const response = await axios({
                url: authLinkAPI + '/' + authLinkId,
                method: 'DELETE',
            }).then((resp) => {
                commit(AUTH_LINK_DELETE, authLinkId);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        /**
         * Save the auth link in parameter in the data base, and update the state
         */
        async [AUTH_LINK_UPDATE]({commit, dispatch}: { commit: any, dispatch: any }, postData: { platformID: string,
            authItemID: string, additionalRuleType: string, additionalRuleValue: string }) {
            return await axios({
                url: authLinkAPI, method: 'PUT', data: postData,
            }).then((resp) => {
                const authLink = resp.data;
                commit(AUTH_LINK_UPDATE, authLink);
                return authLink;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [AUTH_LINK_AUTH_GENERATE]({commit, dispatch}: { commit: any, dispatch: any },
                                        {authLinkID, token}: { authLinkID: string, token: string }) {
            const response = await axios({
                url: authLinkPublicToken,
                method: 'POST',
                data: {token, authLinkID},
            }).then((resp) => {
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
            return response;
        },
    },
};
