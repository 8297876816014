import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import NumberAnimation from '@/components/numberAnimation/NumberAnimation.vue';
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
let TotalSummariesItem = class TotalSummariesItem extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "title", void 0);
    _defineProperty(this, "total", void 0);
    _defineProperty(this, "totalPercentage", void 0);
  }
};
__decorate([Prop({
  default: ''
})], TotalSummariesItem.prototype, "title", void 0);
__decorate([Prop()], TotalSummariesItem.prototype, "total", void 0);
__decorate([Prop()], TotalSummariesItem.prototype, "totalPercentage", void 0);
TotalSummariesItem = __decorate([Options({
  components: {
    NumberAnimation
  }
})], TotalSummariesItem);
export default TotalSummariesItem;