import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import MainTitle from '@/components/title/MainTitle.vue';
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import store from '@/store';
import AuthLinksTable from '@/components/authLink/AuthLinksTable.vue';
import { AuthItemAttributeRuleMapping } from '@/store/enums/authItemEnum';
import { AUTH_LINK_ADD, AUTH_LINK_LIST, AUTH_LINK_UPDATE } from '@/store/actions/authLink';
import { PLATFORM_LIST } from '@/store/actions/platforms';
import { AUTH_ITEM_LIST } from '@/store/actions/authItem';
let AuthLinkForm = class AuthLinkForm extends Vue {
  constructor(...args) {
    super(...args);
    // -- Props
    _defineProperty(this, "linkId", void 0);
    _defineProperty(this, "toggleAuthLinkFormDialog", void 0);
    _defineProperty(this, "visible", false);
    _defineProperty(this, "additionalRuleOptions", []);
    // -- PROPERTY
    _defineProperty(this, "loading", false);
    _defineProperty(this, "errorMsg", '');
    _defineProperty(this, "authLinkForm", {
      platform: null,
      authenticationItem: null,
      authenticationAdditionalRuleType: AuthItemAttributeRuleMapping.NO_RULE,
      authenticationAdditionalRuleValue: ''
    });
  }
  setAdditionalRuleOptions() {
    this.additionalRuleOptions = [{
      'label': this.$t('authentication_links_management.auth_link.form_modal.auth_rule_type_options.no_additional_rule').toString(),
      'value': AuthItemAttributeRuleMapping.NO_RULE
    }, {
      'label': this.$t('authentication_links_management.auth_link.form_modal.auth_rule_type_options.contain_value').toString(),
      'value': AuthItemAttributeRuleMapping.CONTAIN_VALUE
    }, {
      'label': this.$t('authentication_links_management.auth_link.form_modal.auth_rule_type_options.exact_value').toString(),
      'value': AuthItemAttributeRuleMapping.EXACT_VALUE
    }, {
      'label': this.$t('authentication_links_management.auth_link.form_modal.auth_rule_type_options.not_contain').toString(),
      'value': AuthItemAttributeRuleMapping.NOT_CONTAIN
    }, {
      'label': this.$t('authentication_links_management.auth_link.form_modal.auth_rule_type_options.exist').toString(),
      'value': AuthItemAttributeRuleMapping.EXIST
    }];
  }
  get authItemAttributeRuleMapping() {
    return AuthItemAttributeRuleMapping;
  }
  get authItems() {
    return Object.values(store.getters.authItems);
  }
  get platforms() {
    return Object.values(store.getters.platforms);
  }
  // -- VUE JS LIFECYCLE --
  mounted() {
    this.setAdditionalRuleOptions();
    if (this.isUpdateContext()) {
      store.dispatch(AUTH_LINK_LIST).then(() => {
        this.loading = false;
        const authLink = store.getters.authLink(this.linkId);
        this.authLinkForm.platform = authLink.platform.platformID;
        this.authLinkForm.authenticationItem = authLink.authItem.authItemID;
        this.authLinkForm.authenticationAdditionalRuleType = authLink.ruleType;
        this.authLinkForm.authenticationAdditionalRuleValue = authLink.valueMap;
      }).catch(err => {
        this.loading = false;
        this.errorMsg = err;
      });
    }
    store.dispatch(PLATFORM_LIST).then(() => {
      this.loading = false;
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
    store.dispatch(AUTH_ITEM_LIST).then(() => {
      this.loading = false;
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
  // -- CUSTOM FUNCTIONS --
  /**
   * Create the configured link
   */
  addLink() {
    this.loading = true;
    const actionCreateOrUpdateLink = this.isUpdateContext() ? AUTH_LINK_UPDATE : AUTH_LINK_ADD;
    store.dispatch(actionCreateOrUpdateLink, {
      platformID: this.authLinkForm.platform,
      authItemID: this.authLinkForm.authenticationItem,
      additionalRuleType: this.authLinkForm.authenticationAdditionalRuleType,
      additionalRuleValue: this.authLinkForm.authenticationAdditionalRuleValue
    }).then(() => this.onDialogClose()).catch(err => this.errorMsg = err).finally(() => this.loading = false);
  }
  /**
   * Define if we are updating a link or creating one
   * @return boolean true if we are in a Update context, false otherwise
   */
  isUpdateContext() {
    return !!this.linkId;
  }
  updateSelectedAuthLinkPlatform(value) {
    this.authLinkForm.platform = value;
  }
  updateSelectedAuthItem(value) {
    this.authLinkForm.authenticationItem = value;
  }
  updateSelectedAuthAdditionalRuleType(value) {
    this.authLinkForm.authenticationAdditionalRuleType = value;
    if ([AuthItemAttributeRuleMapping.NO_RULE, AuthItemAttributeRuleMapping.EXIST].includes(this.authLinkForm.authenticationAdditionalRuleType)) {
      this.authLinkForm.authenticationAdditionalRuleValue = '';
    }
  }
  updateSelectedAuthAdditionalRuleValue(value) {
    this.authLinkForm.authenticationAdditionalRuleValue = value;
  }
  canSubmit() {
    return this.authLinkForm.platform !== null && this.authLinkForm.authenticationItem !== null && ([AuthItemAttributeRuleMapping.NO_RULE, AuthItemAttributeRuleMapping.EXIST].includes(this.authLinkForm.authenticationAdditionalRuleType) || !!this.authLinkForm.authenticationAdditionalRuleValue);
  }
  onDialogClose() {
    this.visible = false;
    this.$emit("dialogClose");
  }
};
__decorate([Prop()], AuthLinkForm.prototype, "linkId", void 0);
__decorate([Prop({
  default: false
})], AuthLinkForm.prototype, "toggleAuthLinkFormDialog", void 0);
AuthLinkForm = __decorate([Options({
  components: {
    AuthLinksTable,
    MainTitle
  },
  watch: {
    toggleAuthLinkFormDialog() {
      this.visible = this.toggleAuthLinkFormDialog;
    }
  }
})], AuthLinkForm);
export default AuthLinkForm;