import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b6fd1724"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "alert-icon"
};
const _hoisted_2 = {
  class: "banner__content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_icon = _resolveComponent("w-icon");
  return _ctx.isVisible ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(`banner banner--${_ctx.type}`),
    style: _normalizeStyle(_ctx.minHeight ? `min-height:${_ctx.minHeight};` : ``)
  }, [_createElementVNode("div", _hoisted_1, [_ctx.customIcon ? (_openBlock(), _createBlock(_component_w_icon, {
    key: 0,
    icon: _ctx.customIcon
  }, null, 8, ["icon"])) : (_openBlock(), _createBlock(_component_w_icon, {
    key: 1,
    icon: _ctx.icon
  }, null, 8, ["icon"]))]), _createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]), _ctx.closeable ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    class: "close-icon",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.isVisible = false)
  }, [_createVNode(_component_w_icon, {
    icon: "close",
    size: "16px"
  })])) : _createCommentVNode("", true)], 6)) : _createCommentVNode("", true);
}