import { __decorate } from "tslib";
import SettingItem from '@/components/platform/SettingItem.vue';
import GridLayout from '@/components/layout/GridLayout.vue';
import CenterTop from '@/components/layout/CenterTop.vue';
import MainTitle from '@/components/title/MainTitle.vue';
import store from '@/store';
import { SETTING_SPE_UPDATE } from "@/store/actions/configurations";
import { Options, Vue } from 'vue-class-component';
let UpdateSettingsSpe = class UpdateSettingsSpe extends Vue {
  get settings() {
    return store.getters.speSettingsData;
  }
  changeValueFor(setting) {
    store.dispatch(SETTING_SPE_UPDATE, {
      key: setting.key,
      value: !setting.value
    });
  }
};
UpdateSettingsSpe = __decorate([Options({
  components: {
    SettingItem,
    GridLayout,
    CenterTop,
    MainTitle
  }
})], UpdateSettingsSpe);
export default UpdateSettingsSpe;