import {createRouter, createWebHistory} from 'vue-router';
import Home from './views/Home.vue';
import Login from './views/login/Login.vue';
import store from '@/store';
import DetailPlatform from '@/views/platform/DetailPlatform.vue';
import UserSPEList from '@/views/user/UserSPEList.vue';
import {User} from '@/store/models/user';
import CreatePassword from '@/views/user/CreatePassword.vue';
import AutoLogin from '@/views/login/AutoLogin.vue';
import NotFound from '@/views/notFound/NotFound.vue';
import AuthItemList from '@/views/authItems/AuthItemList.vue';
import SettingsAuthItemSAML2 from '@/views/authItems/SettingsAuthItemSAML2.vue';
import IdpChoose from '@/views/idp/IdpChoose.vue';
import AuthLinkList from '@/views/authLink/AuthLinkList.vue';
import AuthLinkForm from '@/views/authLink/AuthLinkForm.vue';
import CopySpeach from '@/views/data/CopySpeach.vue';
import HelpVersion from '@/views/helpVersion/HelpVersion.vue';
import UpdateSettingsSpe from './views/settings/UpdateSettingsSpe.vue';
import AuthLinkConnect from "@/components/authLink/AuthLinkConnect.vue";
import UnifiedStatistics from "@/views/statistics/UnifiedStatistics.vue";

const ifNotAuthenticated = (to: any, from: any, next: any) => {
    if (!store.getters.isAuthenticated) {
        next();
        return;
    }
    next({name: 'HomePage'});
};

const ifAuthenticated = (to: any, from: any, next: any) => {
    if (store.getters.isAuthenticated) {
        const user: User = store.getters.userConnected;
        if (user.expiredPassword) {
            next({name: 'createPassword'});
            return;
        }
        next();
        return;
    }
    next({name: 'Authentication'});
};



export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'HomePage',
            component: Home,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/login',
            name: 'Authentication',
            component: Login,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/initialize/:login/:password',
            name: 'AutoLogin',
            component: AutoLogin,
            props: true,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/update/:login/:password',
            name: 'UpdatePassword',
            component: AutoLogin,
            props: true,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/create-password',
            name: 'createPassword',
            component: CreatePassword,
        },
        {
            path: '/platform/:platformID/:name',
            name: 'platformDetail',
            props: true,
            component: DetailPlatform,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/data/copySpeach',
            name: 'copySpeach',
            component: CopySpeach,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/statistics',
            name: 'statistics',
            component: UnifiedStatistics,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/user/access',
            name: 'manageUser',
            component: UserSPEList,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/authItems',
            name: 'authItemList',
            component: AuthItemList,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/authLinks',
            name: 'authLinkList',
            component: AuthLinkList,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/authLinks/init/:linkId',
            name: 'addAuthLink',
            props: true,
            component: AuthLinkForm,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/authItems/:authItemID/saml2Settings/:name',
            name: 'settingsSaml2',
            props: true,
            component: SettingsAuthItemSAML2,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/idp/choose',
            name: 'IdpChoose',
            component: IdpChoose,
        },
        {
            path: '/idp/connect/:authItemID/:token/:asc/:originalPlatform',
            props: true,
            name: 'authLinkConnect',
            component: AuthLinkConnect,
        },
        {
            path: '/helpVersion',
            name: 'HelpVersion',
            component: HelpVersion,
        },
        {
            path: '/YsOevIHiABUaejc',
            name: 'updateSettingsSPE',
            component: UpdateSettingsSpe,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'NotFound',
            component: NotFound,
        },
    ],
});

