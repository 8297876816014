import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import WIcon from "@/components/wrapper/w-icon.vue";
let Banner = class Banner extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "type", void 0);
    _defineProperty(this, "minHeight", void 0);
    _defineProperty(this, "customIcon", void 0);
    _defineProperty(this, "closeable", void 0);
    _defineProperty(this, "isVisible", true);
  }
  get icon() {
    switch (this.type) {
      case 'info':
        return 'information-outline';
      case 'success':
        return 'check-circle-outline';
      case 'error':
        return 'alert-circle-outline';
      case 'warning':
        return 'alert-outline';
      default:
        return 'information-outline';
    }
  }
};
__decorate([Prop({
  default: "info"
})], Banner.prototype, "type", void 0);
__decorate([Prop({
  default: "64px"
})], Banner.prototype, "minHeight", void 0);
__decorate([Prop()], Banner.prototype, "customIcon", void 0);
__decorate([Prop({
  default: false
})], Banner.prototype, "closeable", void 0);
Banner = __decorate([Options({
  components: {
    WIcon
  }
})], Banner);
export default Banner;