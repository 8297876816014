import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6b82ce35"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "summaryContainer"
};
const _hoisted_2 = {
  class: "item"
};
const _hoisted_3 = {
  class: "item__number-block"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_NumberAnimation = _resolveComponent("NumberAnimation");
  const _component_CardInteractive = _resolveComponent("CardInteractive");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("a", {
    onClick: _cache[0] || (_cache[0] = $event => _ctx.openSummaryDetail(_ctx.shortcutUrl, _ctx.platform.platformID))
  }, [_createVNode(_component_CardInteractive, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createTextVNode(_toDisplayString(_ctx.label) + " ", 1), _createElementVNode("div", _hoisted_3, [_createVNode(_component_NumberAnimation, {
      to: _ctx.value,
      duration: 1,
      delay: 0.25
    }, null, 8, ["to"])])])]),
    _: 1
  })])]);
}