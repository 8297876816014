import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-57d165c0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  id: "side-panel"
};
const _hoisted_2 = {
  class: "side-panel__header"
};
const _hoisted_3 = {
  class: "side-panel__header__title"
};
const _hoisted_4 = {
  class: "side-panel__body"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_icon = _resolveComponent("w-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass([{
      visible: _ctx.visible
    }, "side-panel"])
  }, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_w_icon, {
    icon: "backburger",
    class: "side-panel__header__icon",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('close'))
  }), _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1)]), _createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])], 2)]);
}