import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_header_table = _resolveComponent("header-table");
  const _component_title_cell = _resolveComponent("title-cell");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_user_cell = _resolveComponent("user-cell");
  const _component_cell = _resolveComponent("cell");
  const _component_w_icon = _resolveComponent("w-icon");
  const _component_el_table = _resolveComponent("el-table");
  return _openBlock(), _createBlock(_component_el_table, {
    data: _ctx.listSpeach,
    class: "speach-result-table",
    size: "large"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      field: "title",
      width: "450"
    }, {
      header: _withCtx(() => [_createVNode(_component_header_table, {
        label: _ctx.$t('copy_speaches.speaches_result_process.table.title_col').toString()
      }, null, 8, ["label"])]),
      default: _withCtx(scope => [_createVNode(_component_title_cell, {
        title: scope.row.cour.title,
        image: scope.row.cour.image
      }, null, 8, ["title", "image"])]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      field: "author",
      "min-width": "150"
    }, {
      header: _withCtx(() => [_createVNode(_component_header_table, {
        label: _ctx.$t('copy_speaches.speaches_result_process.table.author_col').toString()
      }, null, 8, ["label"])]),
      default: _withCtx(scope => [_createVNode(_component_user_cell, {
        image: scope.row.userCopy?.image,
        "first-name": scope.row.userCopy?.firstName,
        "last-name": scope.row.userCopy?.lastName,
        email: scope.row.userCopy?.email
      }, null, 8, ["image", "first-name", "last-name", "email"])]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      field: "category",
      "min-width": "150"
    }, {
      header: _withCtx(() => [_createVNode(_component_header_table, {
        label: _ctx.$t('copy_speaches.speaches_result_process.table.category_col').toString()
      }, null, 8, ["label"])]),
      default: _withCtx(scope => [_createVNode(_component_cell, {
        text: scope.row.categoryCopy?.name
      }, null, 8, ["text"])]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      field: "status",
      width: "150"
    }, {
      header: _withCtx(() => [_createVNode(_component_header_table, {
        label: _ctx.$t('copy_speaches.speaches_result_process.table.status_col').toString()
      }, null, 8, ["label"])]),
      default: _withCtx(scope => [_createVNode(_component_w_icon, {
        icon: _ctx.getIconFromStatus(scope.row.copySpeachStatus),
        class: _normalizeClass(`copy-speach-status-icon ${_ctx.getTypeFromStatus(scope.row.copySpeachStatus)}`)
      }, null, 8, ["icon", "class"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["data"]);
}