
export class Summary {
    public platformID: string;
    public nbSpeachPublish: number;
    public nbSpeachToPublish: number;
    public nbSpeachToValidate: number;
    public nbSpeachMaker: number;
    public nbApprover: number;
    public nbUser: number;
    public nbAdmin: number;
    public nbConnectedMonth: number;
    public nbNewSpeachWeek: number;
    public nbNewSpeachMonth: number;
    public nbNewSpeach3Month: number;
    public nbNewSpeach6Month: number;
    public nbSpeachUniqueRead1Week: number;
    public nbSpeachUniqueRead1Month: number;
    public nbSpeachUniqueRead3Months: number;
    public nbSpeachUniqueRead6Months: number;
    constructor(
        platformID: string,
        nbSpeachPublish: number,
        nbSpeachToPublish: number,
        nbSpeachToValidate: number,
        nbSpeachMaker: number,
        nbApprover: number,
        nbUser: number,
        nbAdmin: number,
        nbConnectedMonth: number,
        nbNewSpeachWeek: number,
        nbNewSpeachMonth: number,
        nbNewSpeach3Month: number,
        nbNewSpeach6Month: number,
        nbSpeachUniqueRead1Week: number,
        nbSpeachUniqueRead1Month: number,
        nbSpeachUniqueRead3Months: number,
        nbSpeachUniqueRead6Months: number,
    ) {
        this.platformID = platformID;
        this.nbSpeachPublish = nbSpeachPublish;
        this.nbSpeachToPublish = nbSpeachToPublish;
        this.nbSpeachToValidate = nbSpeachToValidate;
        this.nbSpeachMaker = nbSpeachMaker;
        this.nbApprover = nbApprover;
        this.nbUser = nbUser;
        this.nbAdmin = nbAdmin;
        this.nbConnectedMonth = nbConnectedMonth;
        this.nbNewSpeachWeek = nbNewSpeachWeek;
        this.nbNewSpeachMonth = nbNewSpeachMonth;
        this.nbNewSpeach3Month = nbNewSpeach3Month;
        this.nbNewSpeach6Month = nbNewSpeach6Month;
        this.nbSpeachUniqueRead1Week = nbSpeachUniqueRead1Week;
        this.nbSpeachUniqueRead1Month = nbSpeachUniqueRead1Month;
        this.nbSpeachUniqueRead3Months = nbSpeachUniqueRead3Months;
        this.nbSpeachUniqueRead6Months = nbSpeachUniqueRead6Months;
    }
}
