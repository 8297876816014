import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
let WDialog = class WDialog extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "title", void 0);
    _defineProperty(this, "width", void 0);
    _defineProperty(this, "customClass", void 0);
    _defineProperty(this, "center", void 0);
    _defineProperty(this, "closeOnOutsideClick", void 0);
    _defineProperty(this, "showClose", void 0);
  }
  emitCloseEvent() {
    this.$emit("dialogClose");
  }
};
__decorate([Prop({
  default: ''
})], WDialog.prototype, "title", void 0);
__decorate([Prop({
  default: '560px'
})], WDialog.prototype, "width", void 0);
__decorate([Prop({
  default: ''
})], WDialog.prototype, "customClass", void 0);
__decorate([Prop({
  default: true
})], WDialog.prototype, "center", void 0);
__decorate([Prop({
  default: false
})], WDialog.prototype, "closeOnOutsideClick", void 0);
__decorate([Prop({
  default: true
})], WDialog.prototype, "showClose", void 0);
WDialog = __decorate([Options({
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isVisible: false
    };
  },
  watch: {
    visible() {
      this.isVisible = this.visible;
    }
  }
})], WDialog);
export default WDialog;