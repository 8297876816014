import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
let Cell = class Cell extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "text", void 0);
  }
};
__decorate([Prop({
  default: ""
})], Cell.prototype, "text", void 0);
Cell = __decorate([Options({
  components: {}
})], Cell);
export default Cell;